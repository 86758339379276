import React, {useState} from "react";
import {useTheme} from "@material-ui/core/styles";
import style from "./style.module.sass";
import {Button, TextField, withStyles, createTheme, ThemeProvider, Collapse} from "@material-ui/core";
import {orange} from '@material-ui/core/colors';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useTranslation } from "react-i18next";

const ValidationTextFieldYellow = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: "#ff9800",
    },
    '& input:invalid + fieldset': {
      borderColor: "#ff9800",
    },
    '& input:valid:focus + fieldset': {
      borderColor: "#ff9800",
    },
  },
})(TextField);
const ValidationTextFieldGreen = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: "#1b5e20",
    },
    '& input:invalid + fieldset': {
      borderColor: "#1b5e20",
    },
    '& input:valid:focus + fieldset': {
      borderColor: "#1b5e20",
    },
  },
})(TextField);

const ValidationTextFieldRed = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: "#f44336",
    },
    '& input:invalid + fieldset': {
      borderColor: "#f44336",
    },
    '& input:valid:focus + fieldset': {
      borderColor: "#f44336",
    },
  },
})(TextField);

const ValidationTextFieldBlue = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: "#0d47a1",
    },
    '& input:invalid + fieldset': {
      borderColor: "#0d47a1",
    },
    '& input:valid:focus + fieldset': {
      borderColor: "#0d47a1",
    },
  },
})(TextField);

const themeYellow = createTheme({
  palette: {
    primary: orange,
  },
});

const themeGreen = createTheme({
  palette: {
    primary: {
      main: '#1b5e20',
    },
  },
});

const themeRed = createTheme({
  palette: {
    primary: {
      main: '#f44336',
    },
  },
});

const themeBlue = createTheme({
  palette: {
    primary: {
      main: '#0d47a1',
    },
  },
});

export default function ColorWords() {
  const { t } = useTranslation();
  const [bg, setBg] = useState(false)
  const {theme: {bgColors, colors}} = useTheme();
  const [collapse, setCollapse] = useState(true)
  const [yellowValue, setYellowValue] = useState("10");
  const [redValue, setRedValue] = useState("10");
  const [greenValue, setGreenValue] = useState("10");
  const [blueValue, setBlueValue] = useState("10");

  const fields = [
    {
      id: 1, label: t("innovative"), theme: themeYellow,
      field: <ValidationTextFieldYellow type="number" onInput={(e) => {
        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
      }}
                                        InputProps={{inputProps: {min: 0, max: 100}}} className={style.yellowField}
                                        label={t("innovative")}
                                        helperText={t("maximum 100")}
                                        variant="outlined" size="small"
                                        value={yellowValue} onChange={e => setYellowValue(e.target.value)}/>
    },
    {
      id: 2, label: t("emotional"), theme: themeRed,
      field: <ValidationTextFieldRed type="number" onInput={(e) => {
        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
      }}
                                     InputProps={{inputProps: {min: 0, max: 100}}} className={style.redField}
                                     label={t("emotional")} variant="outlined"
                                     helperText={t("maximum 100")}
                                     size="small"
                                     value={redValue} onChange={e => setRedValue(e.target.value)}/>
    },
    {
      id: 3, label: t("rational"), theme: themeBlue,
      field: <ValidationTextFieldBlue type="number" onInput={(e) => {
        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
      }}
                                      InputProps={{inputProps: {min: 0, max: 100}}} className={style.blueField}
                                      label={t("rational")} variant="outlined"
                                      helperText={t("maximum 100")}
                                      size="small"
                                      value={blueValue} onChange={e => setBlueValue(e.target.value)}/>
    },
    {
      id: 4, label: t("conservative"), theme: themeGreen,
      field: <ValidationTextFieldGreen type="number" onInput={(e) => {
        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
      }}
                                       InputProps={{inputProps: {min: 0, max: 100}}} className={style.greenField}
                                       label={t("conservative")}
                                       helperText={t("maximum 100")}
                                       variant="outlined" size="small"
                                       value={greenValue} onChange={e => setGreenValue(e.target.value)}/>
    },
  ]

  return (
    <div className={``} style={{backgroundColor: bgColors.bgPrimary500}}
         onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}>
      <div className={`d-flex justify-content-between align-items-center pr-4`}
           style={{color: colors.colorPrimary0, backgroundColor: bgColors.bgPrimary500}}>
        <h4 className={style.title}>{t("Color words")}</h4>
        <button className={`ml-2 p-0`} onClick={(e) => {
          e.preventDefault()
          setCollapse(!collapse)
        }}>
          {!collapse ? <AddIcon style={{fill: "white"}} fontSize="small"/> :
            <RemoveIcon style={{fill: "white"}} fontSize="small"/>}
        </button>
      </div>
      <Collapse in={collapse}>
        <div style={{backgroundColor: bgColors.bgPrimary400}}
             className={`${style.subTitle} d-flex align-items-center justify-content-between`}>
          <p style={{color: colors.colorPrimary0}} className={`m-0`}>
            {t('company-wording-define')}
          </p>
          <Button color="primary" variant="contained">
            {t('wording-evaluator')}
          </Button>
        </div>
        <div style={{backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50}}
             className={`position-relative d-flex align-items-center justify-content-center mb-5 pt-3 pb-3 pr-4 pl-4`}>
          {
            fields.map(f =>
              <ThemeProvider key={f.id} theme={f.theme}>
                {f.field}
              </ThemeProvider>
            )
          }
          <div style={{backgroundColor: bgColors.bgPrimary100}} className={`${style.circle}`}>
            <div style={{backgroundColor: bgColors.bgPrimary300}} className={`${style.yellowFr}`}>
              <div style={{width: `${yellowValue}%`, height: `${yellowValue}%`}}/>
            </div>
            <div style={{backgroundColor: bgColors.bgPrimary300}} className={`${style.redFr}`}>
              <div style={{width: `${redValue}%`, height: `${redValue}%`}}/>
            </div>
            <div style={{backgroundColor: bgColors.bgPrimary300}} className={`${style.blueFr}`}>
              <div style={{width: `${blueValue}%`, height: `${blueValue}%`}}/>
            </div>
            <div style={{backgroundColor: bgColors.bgPrimary300}} className={`${style.greenFr}`}>
              <div style={{width: `${greenValue}%`, height: `${greenValue}%`}}/>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}
