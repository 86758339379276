import { isObjectEmpty } from '../utils'

const updateAnalyseData = (state, action) => {

  const defaultState = {
    analyseProgressBar: {},
    bodyBorderShow: true,
    ltOffset: 0,
    textLanguage: '',
    autoTextLanguage: '',
    ignoreList: {},
    useBenchmark: 0,
    enabledBenchmarks: {},
    topAccordionOpen: '',
    modalAccordionOpen: '',
    allowListText: '',
    popUpData: '',
    aiMode: false,
    aiData: {},
    filter: {
      hix: false,
      clix: false,
      flesch: false,
      red: true,
      yellow: true,
      // by default green is filtered
      green: false,
      blue: true,
    },
    text: '',
    oldText: '',
    termCatTexttone: '',
    result: {
      resultData: {},
      localAnalyseResultData: {},
      aiLocalAnalyseResultData: {},
      resultTemplate: 0,
      benchmark: {},
    },
    popover: {
      nnSuggestions: {},
      popups: [],
      currentPopup: -1,
    },
    fileName: '',
    saveArchive: {
      folderId: 0,
      id: 0,
      version: '',
      tags: [],
      type: 'personal',
      name: ''
    }
  }

  if (state === undefined) {
    return defaultState
  }

  const nnSuggestions = { ...state.analyseData.popover.nnSuggestions }
  switch (action.type) {

    case 'ANALYSE_SET_SAVE_ARCHIVE':
      return {
        ...state.analyseData,
        saveArchive: action.payload,
      }

    case 'ANALYSE_CLEAR_SAVE_ARCHIVE':
      return {
        ...state.analyseData,
        saveArchive: defaultState.saveArchive,
      }

    case 'ANALYSE_UPDATE_NN_SUGGESTION':
      nnSuggestions[action.payload.mainText]['data']['sequences'][action.payload.index] = action.payload.text
      return {
        ...state.analyseData,
        popover: {
          ...state.analyseData.popover,
          nnSuggestions: nnSuggestions,
        },
      }

    case 'FETCH_NN_SUGGESTION_SUCCESS':
      // filtering
      const data = action.payload.suggestion?.data || {}
      nnSuggestions[action.payload.text]['data'] = {}
      if (!isObjectEmpty(data) && !isObjectEmpty(data['sequences'])) {
        nnSuggestions[action.payload.text]['data']['sequences'] = []
        nnSuggestions[action.payload.text]['data']['sequences_scores'] = []
        for (let i = 0; i < data['sequences'].length; ++i) {
          if (nnSuggestions[action.payload.text]['data']['sequences'].indexOf(
              data['sequences'][i]) === -1
            && action.payload.text !== data['sequences'][i]) {
            nnSuggestions[action.payload.text]['data']['sequences'].push(
              data['sequences'][i])
            nnSuggestions[action.payload.text]['data']['sequences_scores'].push(
              data['sequences_scores'][i] ? data['sequences_scores'][i] : 0)
          }
        }
      }

      //nnSuggestions[action.payload.text]['data'] = action.payload.suggestion?.data || {};
      nnSuggestions[action.payload.text]['loading'] = false
      nnSuggestions[action.payload.text]['error'] = null

      return {
        ...state.analyseData,
        popover: {
          ...state.analyseData.popover,
          nnSuggestions: nnSuggestions,
        },
      }
    case 'FETCH_NN_SUGGESTION_REQUEST':
      nnSuggestions[action.payload.text] = {}
      nnSuggestions[action.payload.text]['loading'] = true
      nnSuggestions[action.payload.text]['error'] = null
      return {
        ...state.analyseData,
        popover: {
          ...state.analyseData.popover,
          nnSuggestions: nnSuggestions,
        },
      }
    case 'FETCH_NN_SUGGESTION_FAILURE':
      nnSuggestions[action.payload.text]['loading'] = false
      nnSuggestions[action.payload.text]['error'] = action.payload.err
      return {
        ...state.analyseData,
        popover: {
          ...state.analyseData.popover,
          nnSuggestions: nnSuggestions,
        },
      }

    case 'FETCH_LANGUAGE_TOOL_SUCCESS':
      return {
        ...state.analyseData,
        result: {
          ...state.analyseData.result,
          resultData: {
            ...state.analyseData.result.resultData,
            languageTool: action.payload,
          },
        },
      }
    case 'FETCH_LANGUAGE_TOOL_FAILURE':
    case 'FETCH_LANGUAGE_TOOL_REQUEST':
      return {
        ...state.analyseData,
        result: {
          ...state.analyseData.result,
          resultData: {
            ...state.analyseData.result.resultData,
            languageTool: {},
          },
        },
      }
    case 'ANALYSE_SET_TERMCAT_TEXTTONE':
      return {
        ...state.analyseData,
        termCatTexttone: action.payload,
      }
    case 'FETCH_POPOVER_SUGGESTION_SUCCESS':
      let newPopups = [...state.analyseData.popover.popups]
      newPopups[action.payload.id].data.suggestion = action.payload.suggestion

      return {
        ...state.analyseData,
        popover: {
          ...state.analyseData.popover,
          popups: newPopups,
        },
      }
    case 'ANALYSE_SET_BODY_BORDER_SHOW':
      return {
        ...state.analyseData,
        bodyBorderShow: action.payload,
      }

    case 'ANALYSE_SET_AI_MODE':
      return {
        ...state.analyseData,
        aiMode: action.payload,
      }
    case 'ANALYSE_SET_AI_DATA':
      return {
        ...state.analyseData,
        aiData: action.payload,
      }

    case 'ANALYSE_SET_LT_OFFSET':
      return {
        ...state.analyseData,
        ltOffset: action.payload,
      }

    case 'ANALYSE_SET_AUTO_TEXT_LANGUAGE':
      return {
        ...state.analyseData,
        autoTextLanguage: action.payload,
      }

    case 'ANALYSE_SET_TEXT_LANGUAGE':
      return {
        ...state.analyseData,
        textLanguage: action.payload,
      }

    case 'ANALYSE_SET_IGNORE_LIST':
      return {
        ...state.analyseData,
        ignoreList: action.payload,
      }

    case 'ANALYSE_CLEAR_IGNORE_LIST':
      return {
        ...state.analyseData,
        ignoreList: defaultState.ignoreList,
      }

    case 'ANALYSE_SET_USE_BENCHMARK':
      return {
        ...state.analyseData,
        useBenchmark: action.payload,
      }

    case 'ANALYSE_SET_ENABLED_BENCHMARK':
      return {
        ...state.analyseData,
        enabledBenchmarks: action.payload,
      }

    case 'ANALYSE_SET_TOP_ACCORDION':
      return {
        ...state.analyseData,
        topAccordionOpen: action.payload,
      }

    case 'ANALYSE_SET_MODAL_ACCORDION':
      return {
        ...state.analyseData,
        modalAccordionOpen: action.payload,
      }

    case 'ANALYSE_SET_FILTER':
      return {
        ...state.analyseData,
        filter: action.payload,
      }

    case 'ANALYSE_CLEAR_FILTER':
      return {
        ...state.analyseData,
        filter: defaultState.filter,
      }

    case 'ANALYSE_SET_TEXT':
      return {
        ...state.analyseData,
        text: action.payload,
      }

    case 'ANALYSE_SET_OLD_TEXT':
      return {
        ...state.analyseData,
        oldText: action.payload,
      }

    case 'ANALYSE_SET_LOCAL_RESULT':
      return {
        ...state.analyseData,
        result: {
          ...state.analyseData.result,
          localAnalyseResultData: action.payload.localAnalyseResultData,
        },
      }

    case 'ANALYSE_SET_AI_LOCAL_RESULT':
      return {
        ...state.analyseData,
        result: {
          ...state.analyseData.result,
          aiLocalAnalyseResultData: action.payload.aiLocalAnalyseResultData,
        },
      }

    case 'ANALYSE_SET_RESULT':
      return {
        ...state.analyseData,
        result: {
          ...state.analyseData.result,
          resultData: action.payload.resultData,
          localAnalyseResultData: action.payload.resultData,
          resultTemplate: action.payload.resultTemplate,
          benchmark: action.payload.benchmark,
        },
      }
    case 'ANALYSE_CLEAR_RESULT':
      return {
        ...state.analyseData,
        result: defaultState.result,
      }

    case 'ANALYSE_CLEAR_ALL':
      return defaultState

    case 'ANALYSE_SET_POPUPS':
      return {
        ...state.analyseData,
        popover: {
          ...state.analyseData.popover,
          popups: action.payload,
        },
      }
    case 'ANALYSE_SET_CURRENT_POPUP':
      return {
        ...state.analyseData,
        popover: {
          ...state.analyseData.popover,
          currentPopup: action.payload,
        },
      }
    case 'ANALYSE_CLEAR_POPOVERS':
      return {
        ...state.analyseData,
        popover: defaultState.popover,
      }
    case 'ANALYSE_SET_ALLOWLISTTEXT':
      return {
        ...state.analyseData,
        allowListText: action.payload,
      }
    case 'ANALYSE_SET_POPUPDATA':
      return {
        ...state.analyseData,
        popUpData: action.payload,
      }
    case 'ANALYSE_SET_PROGRESS_BAR':
      return {
        ...state.analyseData,
        analyseProgressBar: action.payload,
      }
    case 'ANALYSE_SET_FILE_NAME':
      return {
        ...state.analyseData,
        fileName: action.payload,
      }

    default:
      return state.analyseData
  }
}

export default updateAnalyseData