import React, {useState} from "react";
import style from "../style.module.sass"
import {useTheme} from "@material-ui/core/styles";
import TypeOfField from "../../../../../../components/FormikFields/TypeOfField";
import {Button, Collapse} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { useTranslation } from "react-i18next";

  export default function Languages({formik}) {
  const { t } = useTranslation();
  const [status, setStatus] = useState("none")
  const [collapse, setCollapse] = useState(true)
  const [bg, setBg] = useState(false)
  const {theme: {bgColors, colors}} = useTheme();
  const germanLang = [{id: 1, name: "Germany"}, {id: 2, name: "Switzerland"}, {id: 3, name: "Austria"}]
  const englishLang = [{id: 1, name: "USA"}, {id: 2, name: "Great Britain"}]
  const russianLang = [{id: 1, name: "Russia"}, {id: 2, name: "Kyrgyzstan"}]
  const rows = [
    {id: 0, title: "German", options: germanLang, switchName: "germanHandler", selectName: "german"},
    {id: 1, title: "English", options: englishLang, switchName: "englishHandler", selectName: "english"},
    {id: 2, title: "Russia", options: russianLang, switchName: "russiaHandler", selectName: "russia"},
  ];

  return <>
    <div style={{backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50}}
         onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}
         className={`${style.languages}`}>
      <div style={{backgroundColor: bgColors.bgPrimary500}}
           className={`${style.header} d-flex align-items-center justify-content-between pr-4`}>
        <h4 style={{color: colors.colorPrimary0}}
            className={style.title}>{t('languages-assigned-to-group')}</h4>
        <div>
          <Button onMouseEnter={() => setStatus(status !== "group" ? "hover" : "group")}
                  onMouseLeave={() => setStatus(status !== "group" ? "none" : "group")}
                  onClick={() => setStatus("group")}
                  variant={'contained'} color={status === "hover" || status === "group" ? "primary" : "default"}
                  className={`${style.benchmarks_btn} mr-1`}>{t('Company Default')}</Button>
          <button className={`ml-2 p-0`} onClick={(e) => {
            e.preventDefault()
            setCollapse(!collapse)
          }}>
            {!collapse ? <AddIcon style={{fill: "white"}} fontSize="small"/> :
              <RemoveIcon style={{fill: "white"}} fontSize="small"/>}
          </button>
        </div>
      </div>
      <Collapse in={collapse}>
        <ul className={`mt-3 mb-4`}>
          {
            status === "group" || status === "hover" ? rows.map(
              f => (
                <li key={f.id}
                    className={`position-relative d-flex align-items-center justify-content-between pt-0 pb-1`}>
                  <TypeOfField setAutoFillState={setStatus} control="switch" formik={formik} label={""}
                               name={f.switchName} className={`m-0`}/>
                  <p style={{width: "100px"}} className={`m-0 ml-1`}>{f.title}</p>
                  <TypeOfField formik={formik} control={"select"} label={""} disabled={!formik.values[f.switchName]}
                               options={f.options} value={2}/>
                  <div style={{backgroundColor: bgColors.bgPrimary200}}
                       className={`${style.backDrop} ${formik.values[f.switchName] && "d-none"} position-absolute`}/>
                </li>
              )
            ) :
              rows.map(
                f => (
                  <li key={f.id}
                      className={`position-relative d-flex align-items-center justify-content-between pt-0 pb-1`}>
                    <TypeOfField setAutoFillState={setStatus} control="switch" formik={formik} label={""}
                                 name={f.switchName} className={`m-0`}/>
                    <p style={{width: "100px"}} className={`m-0 ml-1`}>{f.title}</p>
                    <TypeOfField formik={formik} control={"select"} label={""} disabled={!formik.values[f.switchName]}
                                 options={f.options} name={f.selectName}/>
                    <div style={{backgroundColor: bgColors.bgPrimary200}}
                         className={`${style.backDrop} ${formik.values[f.switchName] && "d-none"} position-absolute`}/>
                  </li>
                )
              )
          }
        </ul>
      </Collapse>
    </div>
  </>
}
