import React, { useState } from 'react'
import style from './style.module.sass'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import { useTheme } from '@material-ui/core/styles'
import deleteIcon from '../assets/deleteIcon.svg'
import {
    Edit,
    Check,
} from '@material-ui/icons'
import { useSelector } from 'react-redux'
import {
    Button,
    Collapse,
    Modal,
} from '@material-ui/core'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import { useTranslation } from 'react-i18next'
import { Alert } from '@material-ui/lab'
import closeIcon from '../../../../assets/close-circle.svg'
import config from '../../../../config'
import {
    convertArrayToText,
    convertJSONToObject,
    convertFromErrorObject,
    showStateAsText, getTreeTaggerResult, getTCLocaleName
} from '../../../../utils'
import { useActions } from '../../../../utils/action-helper'
import { userSaveOption, userSetOption } from '../../../../actions'

function Rows(
    {
        row,
        isItemSelected,
        labelId,
        handleClick,
        isEven,
        setDeleteRecall,
        callEndPointReCall,
        setCurrentAllowedList,
        setAddNewAllowedListModal,
        deleteAPI,
        updateAPI,
        component
    }) {
    const {
        theme: { bgColors, colors },
    } = useTheme()
    const [open, setOpen] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const [deleteMsg, setDeleteMsg] = useState()

    const {
        t,
        i18n: { language: i18nLanguage },
    } = useTranslation()
    const tlService = React.useContext(TLServiceComponent)
    const token = useSelector((state) => state.userData.token)
    const [allowedListModal, setAllowedListModal] = useState(false)
    const [approveModal, setApproveModal] = useState(false)
    const [deleteAlertMsg, setDeleteAlertMsg] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const modalSetting = convertJSONToObject(
        useSelector(state => state.userData?.user?.user_options.modal_settings))
    const [modalHide, setModalHide] = useState(false)

    /******** Redux *********/
    const userId = useSelector(state => state.userData.user.id)
    const {
        actionUserSaveOption,
        actionUserSetOption
    } = useActions({
        actionUserSetOption: userSetOption,
        actionUserSaveOption: userSaveOption(tlService)
    })
    // Parse the word and lemma and use convertArrayToText:

    let wordIs = []
    try {
        const parsedWord = (row.words)
        parsedWord?.length &&
        parsedWord.map((el) => {
            return wordIs.push(String(el))
        })
    } catch (err) {
        console.log(err)
    }

    // Delete allowed List:
    const deleteAllowedList = async id => {
        try {
            await deleteAPI(token, id)
        } catch (err) {
            setDeleteAlertMsg(convertFromErrorObject(t, err))
            setTimeout(() => {
                setDeleteAlertMsg('')
            }, config.timeOutDuration)
            return
        }
        setAllowedListModal(false)
        setDeleteRecall(id)
    }
    const update = async row => {
        try {
            await updateAPI(
                token,
                row.id,
                1
            )
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t, e))
            setErrorMsg(convertFromErrorObject(t, e))
            setTimeout(() => {
                setErrorMsg('')
            }, config.timeOutDuration)
            return false
        }
        setApproveModal(false)
        // Recall Endpoint :
        callEndPointReCall()
        return true
    }
    const actionApprove = async () => {
        const categoryLocaleName = getTCLocaleName(tlService, token, row.term_category_id)
        if (!categoryLocaleName) {
            return false
        }

        let newTermId = 0
        try {
            const res = await tlService.addTerm(
                token,
                row.term_category_id,
                (row.words),
                (row.lemma),
                (row.tag),
                row?.lemma?.includes('<unknown>') ? 1 :
                    row.check_words,
                {},
                row.description
            )
            newTermId = res?.data?.id || 0
            setApproveModal(false)
        } catch (e) {
            let error
            try {
                error = JSON.parse(e.message)
            } catch (e) {
                error = {}
            }
            if (error?.number === 409) {
                return await update(row)
            } else {
                setErrorModal(true)
                setDeleteMsg(convertFromErrorObject(t, e))
                console.log('error ' + convertFromErrorObject(t, e))
                setErrorMsg(convertFromErrorObject(t, e))
                setTimeout(() => {
                    setErrorMsg('')
                }, config.timeOutDuration)
            }
            return false
        }
        // go through replacements
        const rowReplacements = row.replacements || []
        if (rowReplacements?.length && newTermId > 0) {
            for (const element of rowReplacements) {
                const tokenizeTerm = await getTreeTaggerResult(tlService, token, categoryLocaleName, element)
                if (!tokenizeTerm?.error) {
                    try {
                        await tlService.addTermReplacement(token, newTermId, row.term_category_id,
                            tokenizeTerm.word, tokenizeTerm.lemma, tokenizeTerm.tag, row.description, 1)
                    } catch (e) {
                        console.log('error ' + convertFromErrorObject(t, e))
                        return false
                    }
                } else {
                    console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                    return false
                }
            }
        }
        try {
            await tlService.calculateCategory(token, parseInt(row.term_category_id))
            await tlService.clearAllCache(token, 1, parseInt(row.term_category_id))
        } catch (e) {
            console.log(e)
        }

        return await update(row)
    }
    const modalSettingsFunctionDelete = () => {
        setModalHide(!modalHide)
        actionUserSetOption('modal_settings', {
            ...modalSetting,
            'allowed_list_delete_term': { ...modalSetting.allowed_list_delete_term, 'not_show': !modalHide }
        })
        actionUserSaveOption(token, userId, 'modal_settings', JSON.stringify({
            ...modalSetting,
            'allowed_list_delete_term': { ...modalSetting.allowed_list_delete_term, 'not_show': !modalHide }
        }))
    }
    const modalSettingsFunctionCheck = () => {
        setModalHide(!modalHide)
        actionUserSetOption('modal_settings', {
            ...modalSetting,
            'allowed_list_checked_term': { ...modalSetting.allowed_list_checked_term, 'not_show': !modalHide }
        })
        actionUserSaveOption(token, userId, 'modal_settings', JSON.stringify({
            ...modalSetting,
            'allowed_list_checked_term': { ...modalSetting.allowed_list_checked_term, 'not_show': !modalHide }
        }))
    }
    return (
        <React.Fragment>
            <Modal open={errorModal} onClose={() => setErrorModal(false)}
                   style={{ width: '50%', margin: '0 auto', marginTop: '20%', height: '300px' }}>
                <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
                    <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
                         style={{ backgroundColor: bgColors.bgPrimary400 }}>
                        <p className={`m-0 ${style.title}`} style={{ color: 'white', fontSize: '25px' }}>
                            {t('error')}
                        </p>
                        <button className={`border-0 bg-transparent`} onClick={() => setErrorModal(false)}>
                            <img src={closeIcon} alt=""/>
                        </button>
                    </div>
                    <br/>
                    <div className={style.error}>
                        <Collapse in={!!deleteMsg}>
                            <Alert
                                className={`m-0`}
                                severity="error">
                                <h6 className={`m-0`}>
                                    {t('delete-error-msg')}
                                </h6>
                            </Alert>
                        </Collapse>
                    </div>
                </div>
            </Modal>
            <Modal
                open={approveModal}
                onClose={() => setApproveModal(false)}
                style={{
                    width: '50%',
                    margin: '0 auto',
                    marginTop: '20%',
                    height: '300px',
                }}
            >
                <div
                    className={`pb-3`}
                    style={{ backgroundColor: bgColors.bgPrimary0 }}
                >
                    <div
                        className={`d-flex justify-content-between p-1 pl-3`}
                        style={{ backgroundColor: bgColors.bgPrimary400 }}
                    >
                        <p
                            className={`m-0 ${style.title}`}
                            style={{ color: 'white', fontSize: '25px' }}
                        >
                            {component === 'textbin' ?
                                t('approve-textbin-list')
                                :
                                t('approve-allowed-list')
                            }
                        </p>
                        <button
                            className={`border-0 bg-transparent`}
                            onClick={() => setApproveModal(false)}
                        >
                            <img src={closeIcon} alt=""/>
                        </button>
                    </div>

                    <br/>
                    <div>
                        <Collapse in={!!errorMsg} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Alert className={`m-0`} severity="error">
                                <h6 className={`m-0`}>
                                    {errorMsg}
                                </h6>
                            </Alert>
                        </Collapse>
                    </div>
                    <div
                        style={{ textAlign: 'center', marginTop: '5%' }}>
                        {component === 'textbin' ?
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => actionApprove()}
                            >
                                {t('approve-textbin')}
                            </Button> :
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => actionApprove()}
                            >
                                {t('approve-allowedList')}
                            </Button>
                        }
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setApproveModal(false)
                            }}
                            style={{ marginLeft: '4%' }}
                        >
                            {t('close')}
                        </Button>
                        <br/>
                        <br/>
                        <div>
                            <Checkbox
                                onChange={(e) => modalSettingsFunctionCheck(e)}
                                checked={modalHide}
                                color="primary"
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                            {t('dont-want-see-again')}
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={allowedListModal}
                onClose={() => setAllowedListModal(false)}
                style={{
                    width: '50%',
                    margin: '0 auto',
                    marginTop: '20%',
                    height: '300px',
                }}
            >
                <div
                    className={`pb-3`}
                    style={{ backgroundColor: bgColors.bgPrimary0 }}
                >
                    <div
                        className={`d-flex justify-content-between p-1 pl-3`}
                        style={{ backgroundColor: bgColors.bgPrimary400 }}
                    >
                        <p
                            className={`m-0 ${style.title}`}
                            style={{ color: 'white', fontSize: '25px' }}
                        >
                            {t('clear-allowed-List')}
                        </p>
                        <button
                            className={`border-0 bg-transparent`}
                            onClick={() => setAllowedListModal(false)}
                        >
                            <img src={closeIcon} alt=""/>
                        </button>
                    </div>
                    <br/>
                    <div>
                        <Collapse in={!!deleteAlertMsg}
                                  style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                  }}>
                            <Alert
                                className={`m-0`}
                                severity="error"
                            >
                                <h6 className={`m-0`}>
                                    {deleteAlertMsg}
                                </h6>
                            </Alert>
                        </Collapse>
                    </div>
                    <div
                        style={{ textAlign: 'center', marginTop: '10%' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => deleteAllowedList(row.id)}
                        >
                            {t('yes')}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setAllowedListModal(false)
                            }}
                            style={{ marginLeft: '4%' }}
                        >
                            {t('no')}
                        </Button>
                        <br/>
                        <br/>
                        <Checkbox
                            onChange={e => modalSettingsFunctionDelete(e)}
                            checked={modalHide}
                            color="primary"
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                        {t('dont-want-see-again')}
                    </div>
                </div>
            </Modal>
            <TableRow
                style={{
                    backgroundColor: isItemSelected
                        ? bgColors.bgBlue300
                        : isEven
                            ? bgColors.bgPrimary0
                            : bgColors.bgBlue100,
                }}
                hover
                role="checkbox"
                className={`${style.row}`}
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
            >
                <TableCell
                    className={style.cell}
                    padding="checkbox"
                    onClick={(event) => handleClick(event, row.id)}
                >
                    <Checkbox
                        checked={isItemSelected}
                        color="primary"
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell
                    onClick={() => setOpen(!open)}
                    className={style.cell}
                    style={{
                        width: 120,
                        color: colors.colorStatusInfo,
                        cursor: 'pointer',
                    }}
                    id={labelId}
                    scope="row"
                    padding="none"
                >
                    {row.id}
                </TableCell>
                <TableCell className={style.cell} align="left">
                    {row.term_category_name[i18nLanguage]}
                </TableCell>
                <TableCell className={style.cell} align="left">
                    {row.check_words === 1 ? 'Wortform' : 'Grundform'}
                </TableCell>
                <TableCell className={style.cell} align="left">
                    {wordIs?.length && convertArrayToText(wordIs, row.locale_name)}
                </TableCell>
                {
                    component === 'textbin' &&
                    <>
                        {!!row.replacements ? <TableCell className={style.cell} align="left">
                                {row.replacements.join(' | ')}
                            </TableCell> :
                            <TableCell/>}
                        {(!!row.description && row.description !== '') ? <TableCell className={style.cell} align="left">
                                {(!!row.description && row.description !== '') ? row.description : <TableCell/>}
                            </TableCell> :
                            <TableCell/>}
                    </>
                }
                <TableCell className={style.cell} align="left">
                    {showStateAsText(row.state, t)}
                </TableCell>
                <TableCell className={`${style.cell}`} align="right">
                    <div className={`d-flex align-items-center`}>
                        <button
                            className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                            onClick={() => {
                                setAddNewAllowedListModal(true)
                                setCurrentAllowedList(row)
                            }}
                            style={{ backgroundColor: bgColors.bgBlue1000 }}
                        >
                            <Edit style={{ fill: '#fff', width: 22, height: 22 }}/>
                        </button>
                        <button
                            className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                            onClick={async () => {
                                if (!modalSetting?.allowed_list_checked_term?.not_show) {
                                    setApproveModal(true)
                                } else {
                                    await actionApprove()
                                }
                            }}
                            style={{ backgroundColor: bgColors.bgBlue1000 }}
                        >
                            <Check style={{ fill: '#fff', width: 22, height: 22 }}/>
                        </button>
                        <button
                            className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                            style={{ backgroundColor: bgColors.bgBlue1000 }}
                            onClick={async () => {
                                if (!modalSetting?.allowed_list_delete_term?.not_show) {
                                    setAllowedListModal(true)
                                } else {
                                    await deleteAllowedList(row.id)
                                }
                            }}
                        >
                            <img src={deleteIcon} alt=""/>
                        </button>
                    </div>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default Rows
