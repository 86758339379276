import React from "react";
import style from "./style.module.sass";
import MainPageWrapper from "../Main-page-wrapper";
import config from '../../config/index'
import AdministrationsCard from "./Administrations-card"
import {useSelector} from "react-redux";
import {checkUserRight} from "../../utils";
import {useTranslation} from "react-i18next";

export default function AdministrationsComponent() {
  const userType = useSelector(state => state.userData.user.user_type_id);
  const user = useSelector(state => state.userData.user);
  const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || []);
  const {t} = useTranslation();

  return (
    <MainPageWrapper>
      <div className={`${style.mainCont} `}>
        {
          config.administration.map((a, index) => {
              // check for section enable
              if (enabledSections.indexOf(a.section) === -1)
                  return null;
              // Check whether User have any right to view :
              if (a?.rights?.length && (userType === 2 && !checkUserRight(user, [101, 102, 103, 104, 105, 110, 121, 123, 131, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 221, 223, 231, 306, 307, 308, 309, 601, 602, 701, 702, 703])))
                  return (
                      index === 0 && <h1 key={index} style={{marginTop: "15vh"}}>
                          {t('no-right-to-view')}
                      </h1>
                  )
              // secondly, some right available, show blank for those cards:
              if (a?.rights?.length && (userType === 2 && !checkUserRight(user, a.rights)))
              return null;
            if(userType === 3){
              if(a.title === "Terminology menu"){
                return  (<AdministrationsCard link={a.link} key={a.id} title={a.title} logo={a.logo && a.logo}
                  content={a.content && a.content}/>)
                }
            }else{
              return  (<AdministrationsCard link={a.link} key={a.id} title={a.title} logo={a.logo && a.logo}
                content={a.content && a.content}/>)
              }
              return null;
          }
         )
        }
      </div>
    </MainPageWrapper>
  )
}

