import { useTheme } from '@material-ui/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComponentWithPop from '../../../common/ComponentWithPop'

const TextTypeCard = ({ title, img, texttype, handleCardSelection, selectedCard }) => {
    const { t } = useTranslation()
    const { theme: { bgColors, borderColors } } = useTheme()
    const [isHovered, setIsHovered] = useState(false)
    const isSelected = selectedCard === texttype
  
    const cardStyle = {
        borderColor: borderColors.borderPrimary300,
        width: '50px',
        marginRight: '8px',
        backgroundColor: isHovered ? bgColors.bgBlue300 : (isSelected ? bgColors.bgBlue1000 : bgColors.bgPrimary0),
        transition: 'background-color 0.3s ease',
    }

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    const handleClick = () => {
        handleCardSelection(texttype)
    }

    return (
        <ComponentWithPop  text={t(title)} >
        <div style={cardStyle}
        role='button'
            className={`d-flex align-items-center justify-content-center mb-2 rounded`}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            >
            <div style={{  width: '40px', height: '40px' }} className={` d-flex align-items-center justify-content-center`}>
                <img
                    src={img}
                    alt="ComLab AI Bot"
                    width="35px"
                    height="35px"
                    className='rounded-circle'
                />
            </div>
           
        </div>
        </ComponentWithPop>
    )
}

export default TextTypeCard
