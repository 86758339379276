import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import logo from './assets/group372.svg'
import text from './assets/TEXTLAB-LOGIN.svg'
import submitIcon from './assets/anmeldenIcon.svg'
import { TextField } from '@material-ui/core'
import Footer from '../Footer/Footer'
import { useTheme } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Alert } from '@material-ui/lab'
import ResetModal from './ResetModal/ResetModal'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TLServiceComponent from '../../contexts/TLServiceComponent'
import { useActions } from '../../utils/action-helper'
import { loginUser, loginUserLDAP } from '../../actions'
import { Redirect, useRouteMatch } from 'react-router-dom'
import Spinner from '../Spinner'
import { convertFromErrorObject, isObjectEmpty, parseErrorObject } from '../../utils'
import RestoreModal from './RestoreModal/RestoreModal'

export default function AuthForm () {
  const { t, i18n } = useTranslation()
  const { theme: { bgColors, borderColors, colors } } = useTheme()
  const { params: { code } } = useRouteMatch()

  // states
  const [submitted, setSubmitted] = useState(false)
  const [openAlert, setOpenAlert] = useState('')
  const [openResetPass, setOpenPass] = useState(false)
  const [openRestorePass, setOpenRestorePass] = useState(!!code)
  const [toggle, setToggle] = useState(false)
  const [LDAPvalue, setLDAPvalue] = useState()
  const [totpRedirect, setTotpRedirect] = useState('')

  // get reducers
  const errorUser = useSelector(state => state.userData.error)
  const token = useSelector(state => state.userData.token)
  const thinclient = useSelector(state => state.openData.ping?.thinclient)
  const services = useSelector(state => state.openData.ping?.services)
  const autoLogin = useSelector(state => state.openData.ping?.autoLogin || false)
  const [autoLoginIndex, setAutoLoginIndex] = useState(0)

  // bind actions
  const tlService = React.useContext(TLServiceComponent)
  const { actionLoginUser, actionLoginUserLDAP } = useActions({
    actionLoginUser: loginUser(tlService, i18n),
    actionLoginUserLDAP: loginUserLDAP(tlService, i18n)
  })

  //state validation by YUP
  const validationSchema = yup.object({
    name: yup.string()
      .required(t('Required field')),
    password: yup.string()
      .required(t('Required field')),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
      adoption: false,
      language: '',
      textSort: '',
    },
    validationSchema: validationSchema,
    onSubmit: ({ name, password }) => {
      setSubmitted(true)
      setOpenAlert('')
      actionLoginUser(name, password, true)
      // add true to remember!!
    },
  })

  // auto login
  useEffect(() => {
    if (!isObjectEmpty(services)) {
      const servicesArray = Object.entries(services)
      if (!token && autoLogin && (autoLoginIndex === 0 || !!errorUser) && autoLoginIndex < servicesArray.length) {
        // try to log in with all enabled services
        if (!submitted && autoLoginIndex < servicesArray.length) {

          // try to connect
          console.log('try', servicesArray[autoLoginIndex])
          setSubmitted(true)
          actionLoginUserLDAP(servicesArray[autoLoginIndex][1], false)
          setAutoLoginIndex(autoLoginIndex + 1)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, services, errorUser, submitted])

  // effect check error user login
  useEffect(() => {
    if (!!errorUser){
      const data = parseErrorObject(errorUser)
      if (submitted) {
        // fo GA temporary
        if (data?.type_2fa === 'ga') {
          setSubmitted(false)
          setTotpRedirect('/authorization/2fa/ga')
          return
        }
      }
      setSubmitted(false)
      setOpenAlert(convertFromErrorObject(t, errorUser))
    }
    // eslint-disable-next-line
  }, [errorUser])

  // Check for LDAP value:
  useEffect(() => {
    if (!isObjectEmpty(services)) {
      for (let el of Object.keys(services)) {
        if (el.includes('LDAP')) {
          setToggle(true)
          setLDAPvalue(services[el])
          break
        }
      }
    }
  }, [services])

  const handleDifferentLoginTypes = async (type) => {
    setSubmitted(true)
    setOpenAlert('')
    actionLoginUserLDAP(type, false)
  }

  if (totpRedirect) {
    return <Redirect to={totpRedirect}/>
  }

  if (token && !openRestorePass) {
    return <Redirect to="/"/>
  }

  return (
    <div style={{ backgroundColor: bgColors.bgPrimary100 }} className={style.mainCont}>
      {submitted && <Spinner/>}
      <ResetModal setOpen={setOpenPass} open={openResetPass}/>
      <RestoreModal setOpen={setOpenRestorePass} open={openRestorePass}/>
      <form onSubmit={formik.handleSubmit} className={style.form}>
        <div style={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.161)', backgroundColor: bgColors.bgPrimary0 }}>
          <div className={style.blueRec}>
            <img src={logo} className={style.logo} alt="logo"/>
            <img src={text} className={style.text} alt="text"/>
          </div>
          <div className={style.fields}>
            {!! openAlert && <Alert
              className={`m-0`}
              severity="error">
              <p className={`m-0`}>{openAlert}</p>
            </Alert>}
            {toggle ? <div>
                <div className={`${style.fieldsActions} mb-3`}>
                  <p style={{ color: colors.colorPrimary300, textAlign: 'center' }}>
                    {t('Login Page Bottom Text')}
                  </p>
                </div>
                <br/>
                <div className={`${style.submitCont} mt-2 mb-3`} style={{ paddingBottom: '170px' }}>
                  <button type="submit" className={style.submit} onClick={() => handleDifferentLoginTypes(LDAPvalue)}>
                    <img src={submitIcon} alt="submit svg"/>
                    {t('lets-start')}
                  </button>
                </div>
                <p style={{ color: colors.colorPrimary300, textAlign: 'center', cursor: 'pointer' }} onClick={() => {
                  setToggle(false)
                }}>
                  {t('redirect-login')}
                </p>
              </div> :
              <>
                <TextField
                  helperText={formik.touched.name && formik.errors.name}
                  error={formik.touched.name && !!formik.errors.name}
                  onChange={formik.handleChange}
                  name={'name'}
                  variant="filled"
                  label={t('Login or e-mail')}
                  style={{ marginBottom: '30px' }}
                />
                <TextField
                  type="password"
                  helperText={formik.touched.password && formik.errors.password}
                  error={formik.touched.password && !!formik.errors.password}
                  onChange={formik.handleChange}
                  name={'password'}
                  className={`mt-3`}
                  variant="filled"
                  label={t('Password')}
                  style={{ marginBottom: '20px' }}
                />
                {thinclient ? <p
                  style={{ color: colors.colorPrimary200 }}
                  className={`${style.password}`}
                  onClick={() => {
                    setOpenPass(!openResetPass)
                  }}>
                  {t('Forgot Your Password?')}
                </p> : <p className={`${style.password}`}>&nbsp;</p>}
                <div className={`${style.submitCont} mt-2 mb-3`}>
                  {!isObjectEmpty(services) && Object.entries(services).map(([key, value]) => {
                    if (key === 'Local')
                      return (<button type="submit" className={style.submit} key={key}>
                        <img style={{marginRight: '5px'}} src={submitIcon} alt="submit svg"/>
                        {t('Login')}
                      </button>)
                    return (
                      <button type="button" onClick={() => handleDifferentLoginTypes(value)} key={key}
                              className={style.submitLDAP}>
                        <img style={{marginRight: '5px'}} src={submitIcon} alt="submit svg"/>
                        {t(key)}
                      </button>
                    )
                  })}
                </div>
                <div className={`${style.fieldsActions} mb-3`}>
                  <div style={{ borderColor: borderColors.borderPrimary300 }} className={style.news}>
                    <p dangerouslySetInnerHTML={{__html: t('Login Page Bottom Text')}} style={{ color: colors.colorPrimary300, textAlign: 'center' }} />
                  </div>
                </div>
              </>
            }
          </div>
        </div>
        <div className={`pt-1`}>
          <Footer/>
        </div>
      </form>
    </div>
  )
}


