import {useTranslation} from "react-i18next";
import {useTheme} from "@material-ui/core/styles";
import AccordionItem from "../AccordionItem";
import {
    chooseColor,
    convertLanguagesToShort,
    countTermsWithPositions,
    filterByHIXCLIXFlesch, getScale, getValue,
    roundWithPrecision
} from "../../../../../utils";
import AccordionModalDefault from "../AccordionModal/AccordionModalDefault";
import React from "react";
import {useSelector} from "react-redux";
import config from "../../../../../config";

export default function CorporateLanguage({
                                        handleModalAccordionOpen,
                                        handleTopAccordionOpen,
                                        handleOnPopupClick,
                                        getNextPopup,
                                        index,
                                        filter
                                    }) {
    const {t, i18n: {language: i18nLanguage}} = useTranslation();
    const {theme: {colors}} = useTheme();
    const themeColors = {
        red: colors.colorProgress20,
        yellow: colors.colorProgress60,
        green: colors.colorProgress100,
        blue: colors.colorProgress120
    };

    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);

    // skip if HIX filter enabled
    //if (filter['hix'] )
    //    return null;

    let valResultObject = resultData['dataCorporateLanguage'];

    let topColor = 'green';
    let num = 0


    const termsSettings = currentBenchmarkSettings?.settings['listCorporateLanguageTerms']?.values['_list'] || {};

    const valResult = Object.values(valResultObject).sort((a, b) => {
        const sorta = termsSettings[a.category.id]?.sort || 0;
        const sortb = termsSettings[b.category.id]?.sort || 0;
        return sorta - sortb;
    });


    //countMarkExclamation
    const countMarkExclamation = resultData['countMarkExclamation'];

    const addValues = [];

    if (countMarkExclamation){
        const countNumber =countMarkExclamation.length;
        num += countNumber;

        const localSettings = currentBenchmarkSettings?.settings['countMarkExclamation']?.values;
        const localTarget = getValue(localSettings, "target", 50);
        const localThreshold = getValue(localSettings, "threshold", 50);
        const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50);
        const localTargetYellowRed = getValue(localSettings, "yellowRed", 50);
        const localScaleFrom = getValue(localSettings, "scaleFrom", 0);
        const localScaleTo = getValue(localSettings, "scaleTo", 100);
        const fullValueCount = config.fullCountBase['countMarkExclamation'] !== undefined ? config.fullCountBase['countMarkExclamation'] : "countWords";


        let localPercent = countNumber / (resultData[fullValueCount] / 100);
        const localAbsolute = getValue(localSettings, "absolute", false);
        if (localAbsolute){
            localPercent = countNumber;
        }
        const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);

        // convert real % into  scale size percent
        const [scalePercent, targetPercent] = getScale (localPercent, localTarget, localScaleFrom, localScaleTo);

        topColor = localColor === 'red' ? 'red' : (localColor === 'yellow' && topColor !== 'red') ? 'yellow' : topColor;

        // if filter = ignore
        if (filter[localColor] && !filterByHIXCLIXFlesch(filter, currentBenchmarkSettings?.settings['countMarkExclamation']?.values?.clix, 'countMarkExclamation')){
            addValues.push({
                absolute: localAbsolute,
                toolTip: t('CorporateLanguage_countMarkExclamation_tooltip'),
                colorBar: themeColors[localColor],
                colorTop: themeColors[localColor],
                id: 'GrammarAndStyle_countMarkExclamation',
                num: roundWithPrecision(countNumber, 0),
                target: localTarget,
                targetTriangle: targetPercent,
                actual: roundWithPrecision(localPercent, 1),
                scaleWidth: roundWithPrecision(scalePercent, 1),
                title: t('countMarkExclamation', {threshold: localThreshold})
            });
       }

    }


    let cells = valResult
        .reduce((acc, valCategory) => {
            const countNumber = countTermsWithPositions(valCategory.result);
            const keyCategory = valCategory.category.id;

            const localSettings = termsSettings[keyCategory];
            const localTarget = getValue(localSettings, "target", 50);
            const localTargetYellowGreen = getValue(localSettings, "yellowGreen", 50);
            const localTargetYellowRed = getValue(localSettings, "yellowRed", 50);
            const localScaleFrom = getValue(localSettings, "scaleFrom", 0);
            const localScaleTo = getValue(localSettings, "scaleTo", 100);


            let localPercent = countNumber / (resultData.countWords / 100);
            const localAbsolute = getValue(localSettings, "absolute", false);
            if (localAbsolute){
                localPercent = countNumber;
            }

            const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);

            // convert real % into  scale size percent
            const [scalePercent, targetPercent] = getScale (localPercent, localTarget, localScaleFrom, localScaleTo);

            // if filter = ignore
            if (!filter[localColor])
                return [...acc];

            // cilx + hix filter
            if (filterByHIXCLIXFlesch(filter, termsSettings[keyCategory]?.clix, 'terminology'))
                return [...acc];


            num += countNumber;
            topColor = localColor === 'red' ? 'red' : (localColor === 'yellow' && topColor !== 'red') ? 'yellow' : topColor;

            return [
                ...acc,
                {
                    absolute: localAbsolute,
                    toolTip: valCategory.category.description[convertLanguagesToShort(i18nLanguage)],
                    colorBar: themeColors[localColor],
                    colorTop: themeColors[localColor],
                    id: 'CorporateLanguage_' + keyCategory,
                    num: roundWithPrecision(countNumber, 0),
                    target: localTarget,
                    targetTriangle: targetPercent,
                    actual: roundWithPrecision(localPercent, 1),
                    scaleWidth: roundWithPrecision(scalePercent, 1),
                    title: valCategory?.category?.name ? valCategory.category.name[convertLanguagesToShort(i18nLanguage)] : ''
                }
            ]
        }, []);

    //const percent = num / (resultData.countWords / 100);

    cells = cells.concat(addValues);

    if (cells.length === 0)
        return null;

    return (
        <AccordionItem
            color={themeColors[topColor]}
            number={roundWithPrecision(num)}
            title={t("Corporate Language")}
            index={index}
            handleTopAccordionOpen={handleTopAccordionOpen}
            windowsKey={'CorporateLanguage'}
        >
            <AccordionModalDefault
                handleOnPopupClick={handleOnPopupClick}
                getNextPopup={getNextPopup}
                handleModalAccordionOpen={handleModalAccordionOpen}
                cells={cells}
                enabledTypes={['PositiveTerminology','Terminology','GrammarAndStyle']}
            />
        </AccordionItem>
    );
}
