import React, {useState} from 'react';
import style from "./style.module.sass";
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from "@material-ui/core/Checkbox";
import {useTheme} from '@material-ui/core/styles';
import deleteIcon from "../assets/deleteIcon.svg";
import cloneIcon from '../assets/cloneIcon.svg';
import settingIcon from "../assets/settingIcon.svg"
import {NavLink} from "react-router-dom";
import { Button, Collapse, Modal } from "@material-ui/core";
import TLServiceComponent from "../../../../../contexts/TLServiceComponent";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import closeIcon from "../../../../../assets/close-circle.svg";
import config from '../../../../../config'
import {checkUserRight, convertFromErrorObject} from '../../../../../utils'

function Rows({row, isItemSelected, labelId, handleClick, isEven, setDeleteRecall}) {
  const {theme: {bgColors, colors}} = useTheme();
  const [open, setOpen] = React.useState(false);
  const tlService = React.useContext(TLServiceComponent);
  const token = useSelector(state => state.userData.token);
  const [firmaModal, setFirmaModal] = useState(false);
  const [deleteAlertMsg, setDeleteAlertMsg] = useState();
  const { t } = useTranslation();
  const user = useSelector(state => state.userData.user);

  const deleteCompany = async (e, id) => {
    e.preventDefault();
    try {
      await tlService.deleteCompany(token, id);
      setFirmaModal(false);
      setDeleteRecall(id)
    } catch (e) {
      setDeleteAlertMsg(convertFromErrorObject(t,e));
      setTimeout(() => {
        setDeleteAlertMsg('');
      }, config.timeOutDuration)
    }
  }

  return (
    <React.Fragment>
    <Modal open={firmaModal} onClose={() => setFirmaModal(false)} style={{ width: '50%', margin: '0 auto', marginTop: '20%', height: '300px' }}>
              <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
                <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
                  style={{ backgroundColor: bgColors.bgPrimary400 }}>
                  <p className={`m-0 ${style.title}`} style={{ color: 'white', fontSize: '25px' }}>
                    {t('clear-firma')}
                  </p>
                  <button className={`border-0 bg-transparent`} onClick={() => setFirmaModal(false)}>
                    <img src={closeIcon} alt="" />
                  </button>
                </div>
                <br />
                <div className={style.error} >
                  <Collapse in={!!deleteAlertMsg} >
                    <Alert
                      className={`m-0`}
                      severity='error'>
                      <h6 className={`m-0`}>
                        {/* {t('delete-error-msg')} */}
                        {deleteAlertMsg}
                      </h6>
                    </Alert>
                  </Collapse>
                </div>

                <div>
                  <Button color='primary' variant='contained' onClick={(e) => deleteCompany(e, row.id)} style={{ marginLeft: '40%', marginTop: '4%' }}>
                    {t('yes')}
                  </Button>
                  <Button color='primary' variant='contained' onClick={() => { setFirmaModal(false); }} style={{ marginLeft: '4%', marginTop: '4%' }}>
                    {t('no')}
                  </Button>
                </div>
              </div>
      </Modal>
      <TableRow
        style={{backgroundColor: isItemSelected ? bgColors.bgBlue300 : isEven ? bgColors.bgPrimary0 : bgColors.bgBlue100}}
        hover
        role="checkbox"
        className={`${style.row}`}
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{'aria-labelledby': labelId}}
          />
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} className={style.cell}
                   style={{maxWidth: "248px", color: colors.colorStatusInfo, cursor: "pointer"}} component="th"
                   id={labelId} scope="row" padding="none">
          {row.firmaName}
        </TableCell>
        <TableCell className={style.cell} align="left">
          {row.associatedName}
        </TableCell>
        <TableCell className={style.cell} align="left">
          {row.changeDate}
        </TableCell>
        <TableCell className={style.cell} align="left">
          {row.maxLicense}
        </TableCell>
      {(checkUserRight(user, [308])) &&
        <TableCell className={`${style.cell} d-flex`} align="right">
          <NavLink to={`/administration/firma/update/${row.id}`} exact={true}>
            <button className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                    style={{backgroundColor: bgColors.bgBlue1000}}>
              <img src={settingIcon} alt=""/>
            </button>
          </NavLink>
          <button className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                  style={{backgroundColor: bgColors.bgBlue1000}}
                  onClick={() => {
                    setFirmaModal(true);}
                    }>
            <img src={deleteIcon} alt=""/>
          </button>   
          <button className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                  style={{backgroundColor: bgColors.bgBlue1000}}>
            <img src={cloneIcon} alt=""/>
          </button>
        </TableCell>
           }
      </TableRow>
      <TableRow className={`${style.accordion}`}
                style={{backgroundColor: isItemSelected ? bgColors.bgBlue300 : isEven ? bgColors.bgPrimary0 : bgColors.bgBlue100}}>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{color: colors.colorPrimary500}}
                 className={`${style.firma} pt-2`}
                 margin={1}>
              <div className={`${style.firmaData}`}>
                <ul className={`${style.firmaData_ul1}`} style={{color: colors.colorPrimary500}}>
                  <li>
                  <p className={`${style.title} mb-2 m-0`}>Id</p>
                    <p className={`m-0 ${style.text}`}>{row.primary.id}</p>
                  </li>
                  <li>
                    <p className={`${style.title} mb-2 m-0`}>{t('default-language')}</p>
                    <p className={`m-0 ${style.text}`}>{row.primary.defaultLanguage}</p>
                  </li>
                  <li>
                    <p className={`${style.title} mb-2 m-0`}>{t('default-text-type')}</p>
                    <p className={`m-0 ${style.text}`}>{row.primary.defaultTextType}</p>
                  </li>
                </ul>
              </div>
              <div className={`${style.firmaData}`}>
                <h4 className={`mb-1`}>{t('active-features')}</h4>
                <ul className={`${style.firmaData_ul2} m-0`} style={{color: colors.colorPrimary500}}>
                  <li className={`d-flex align-items-center`}>
                    <div className={`${row.featuresActive.spellCheck && style.activeCircle} ${style.circle} mr-2`}/>
                    <p className={`${style.title} m-0`}>{t('spell-check')}</p>
                  </li>
                  <li className={`d-flex align-items-center`}>
                    <div className={`${row.featuresActive.firmaArchive && style.activeCircle} ${style.circle} mr-2`}/>
                    <p className={`${style.title} m-0`}>{t('group-archive-stats')}</p>
                  </li>
                  <li className={`d-flex align-items-center`}>
                    <div
                      className={`${row.featuresActive.cleaningRules && style.activeCircle} ${style.circle} mr-2`}/>
                    <p className={`${style.title} m-0`}>{t('cleaning-rules')}</p>
                  </li>
                  <li className={`d-flex align-items-center`}>
                    <div className={`${row.featuresActive.plugin && style.activeCircle} ${style.circle} mr-2`}/>
                    <p className={`${style.title} m-0`}>{t('Plugin')}</p>
                  </li>
                  <li className={`d-flex align-items-center`}>
                    <div
                      className={`${row.featuresActive.coloredWording && style.activeCircle} ${style.circle} mr-2`}/>
                    <p className={`${style.title} m-0`}>{t('colored-wording')}</p>
                  </li>
                  <li className={`d-flex align-items-center`}>
                    <div className={`${row.featuresActive.textModule && style.activeCircle} ${style.circle} mr-2`}/>
                    <p className={`${style.title} m-0`}>{t('text-modules')}</p>
                  </li>
                </ul>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default Rows;
