import React, {useState} from "react";
import style from "../style.module.sass"
import {useTheme} from "@material-ui/core/styles";
import TypeOfField from "../../../../../../components/FormikFields/TypeOfField";
import {Button, Collapse} from "@material-ui/core";
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

import { useTranslation } from "react-i18next";

  
  function Features({formik}) {
  const { t } = useTranslation();
  const [bg, setBg] = useState(false)
  const [status, setStatus] = useState("none")
  const [collapse, setCollapse] = useState(true)
  const {theme: {bgColors, colors}} = useTheme()
  const generalFields = [
    {id: 8, control: "switch", name: "addressing", label: t("Activate addressing"), labelPlacementEnd: true},
    {id: 7, control: "switch", name: "activateCLIX", label: t("Activate CLIX"), labelPlacementEnd: true},
    {id: 11, control: "switch", name: "newsChannel", label: t("Show news channel"), labelPlacementEnd: true},
    {
      id: 5,
      control: "switch",
      name: "saveTextAfterSessionExpired",
      label: t("Save text after session expired"),
      labelPlacementEnd: true
    },
    {
      id: 2,
      control: "switch",
      name: "hyphenatedSpelling",
      label: t("Prefer hyphenated spelling in long words"),
      labelPlacementEnd: true
    },
    {id: 1, control: "switch", name: "textModules", label: "Show text modules", labelPlacementEnd: true},
    {
      id: 6,
      control: "select-with-text-field",
      name: "setSessionLength",
      withTextField: true,
      withTime: true,
      label: t("Setting the session length"),
      options: [
        {id: 1, name: "No Limit"},
      ]
    },
    {
      id: 0,
      control: "select",
      name: "activateSpellCheck",
      label: t("Activate Spell check"),
      options: [{id: 1, name: "Language Tool"}, {id: 2, name: "Hunspell"}, {id: 3, name: "No spell check"}]
    },
  ];
  const textCleaning = [
    {
      id: 1,
      control: "switch",
      name: "activateCleaningRules",
      label: t("Activate cleaning rules"),
      labelPlacementEnd: true
    },
    {
      id: 2,
      control: "switch",
      name: "usersCanUseCleaningRules",
      label: t("Enable all users to use cleaning rules"),
      labelPlacementEnd: true
    },
    {
      id: 3,
      control: "switch",
      name: "bodyCopyRecognition",
      label: t("Activate Body copy recognition"),
      labelPlacementEnd: true
    },
  ]
  const statisticFields = [
    {id: 1, control: "switch", name: "showPdf", label: t("Show PDF"), labelPlacementEnd: true},
    {
      id: 2,
      control: "switch",
      name: "timelineDefaultHIX",
      label:t("Dashboard: Timeline default = HIX"),
      labelPlacementEnd: true
    },
  ];
  const archiveFields = [
    {
      id: 3,
      control: "select",
      name: "analysesNumberPerPage",
      label: t("Number of analyses per page"),
      options: [
        {id: 10, name: "10"},
        {id: 20, name: "20"},
        {id: 30, name: "30"},
        {id: 40, name: "40"},
        {id: 50, name: "50"}
      ]
    },
    {id: 1, control: "switch", name: "showArchive", label: t("Show archive"), labelPlacementEnd: true},
    {
      id: 2,
      control: "switch",
      name: "showGroupArchive",
      label: t("Show group archive"),
      labelPlacementEnd: true
    },
  ];

  const featuresFields = [
    {id: 1, title: t("general"), fields: generalFields},
    {id: 2, title: t("Text cleaning"), fields: textCleaning},
    {id: 3, title: t("Statistics"), fields: statisticFields},
    {id: 4, title: t("Archive"), fields: archiveFields},
  ]
  return (
    <div style={{backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50}}
         onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}>
      <div className={`d-flex align-items-center justify-content-between pr-4`}
           style={{color: colors.colorPrimary0, backgroundColor: bgColors.bgPrimary500}}>
        <h4 className={style.title}>
          {t('features')}
        </h4>
        <div>
          <Button onMouseEnter={() => setStatus(status !== "group" ? "hover" : "group")}
                  onMouseLeave={() => setStatus(status !== "group" ? "none" : "group")}
                  onClick={() => setStatus("group")}
                  variant={'contained'} color={status === "hover" || status === "group" ? "primary" : "default"}
                  className={`${style.benchmarks_btn} mr-1`}>{t('Company Default')}</Button>
          <button className={`ml-2 p-0`} onClick={(e) => {
            e.preventDefault()
            setCollapse(!collapse)
          }}>
            {!collapse ? <AddIcon style={{fill: "white"}} fontSize="small"/> :
              <RemoveIcon style={{fill: "white"}} fontSize="small"/>}
          </button>
        </div>
      </div>
      <Collapse in={collapse}>
        {
          featuresFields.map(featuresF => <React.Fragment key={featuresF.id}>
            <div style={{backgroundColor: bgColors.bgPrimary400}}
                 className={`${style.subTitle} d-flex align-items-center justify-content-between`}>
              <p
                style={{color: colors.colorPrimary0}} className={`m-0`}>{featuresF.title}</p>
            </div>
            {
              status === "group" || status === "hover" ?
                <ul className={`mb-0 pl-5`}>
                  {
                    featuresF.fields.map(
                      field => (
                        <li key={field.id}>
                          <TypeOfField status={status === "group" || status === "hover" ? "group" : null}
                                       setAutoFillState={setStatus} control={field.control} formik={formik}
                                       name={field.name}
                                       label={field.label} {...field}/>
                        </li>
                      )
                    )
                  }
                </ul> :
                <ul className={`mb-0 pl-5`}>
                  {
                    featuresF.fields.map(
                      field => (
                        <li key={field.id}>
                          <TypeOfField control={field.control} formik={formik} name={field.name}
                                       label={field.label} {...field}/>
                        </li>
                      )
                    )
                  }
                </ul>
            }
          </React.Fragment>)
        }
      </Collapse>
    </div>
  )
}

export default Features;
