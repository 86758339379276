import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableCell from "@material-ui/core/TableCell";
import {Box, Button} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from "react-i18next";

export default function AddNew({setTerminologyModal, setCurrentTerm,
  mainBox, setMainBox,
  categoryType,
  setCategoryType,
  color, setColor,
  visibility, setVisibility
}) {
  const {theme: {bgColors, colors}} = useTheme();
  const {t} = useTranslation();

  return (
    <>
      <TableHead style={{backgroundColor: bgColors.bgPrimary0}} className={`${style.tableFilter}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Box style={{color: colors.colorPrimary500}}
               className={`d-flex justify-content-between align-items-end p-1 pt-2 pr-3`} margin={1}>
            <h3 className={`pl-2`}>{t('term-categories')}</h3>
            <Button className={`${style.filterBtn}`} variant="contained"
                    color="primary"
                    onClick={() => {
                      setMainBox([])
                      setTerminologyModal(s => !s);
                      setCurrentTerm();
                      setCategoryType('');
                      setColor('');
                      setVisibility(false)
                    }}
                    startIcon={<AddIcon/>}>
              {t('add-new')}
            </Button>
          </Box>
        </TableCell>
        </TableRow>
      </TableHead>
    </>
  )
}
