import React, { useEffect, useRef, useState } from 'react'
import style from '../Universal/style.module.sass'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Rows from '../Universal/Rows'
import { useTheme } from '@material-ui/core/styles'
import EnhancedTableHead from './TableHead'
import TableFooter from '../Universal/TableFooter'
import UserProfileWrapper from '../../UserProfileWrapper/UserProfileWrapper'
import AddNew from './AddNew'
import { checkUserRight, convertFromErrorObject, convertLanguagesToShort, isObjectEmpty } from '../../../../utils'
import { useSelector } from 'react-redux'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import { useTranslation } from 'react-i18next'
import Spinner from '../../../Spinner'
import { TableCell, TableRow } from '@material-ui/core'
import AddNewTermModal from '../../../ModalWindows/TermModal/TermModal.jsx'
import { Redirect } from 'react-router-dom'
import { userGetTextTone } from '../../../../actions'
import { useActions } from '../../../../utils/action-helper'

function createData (
  id,
  locale_name,
  term_category_name,
  term_category_id,
  check_words,
  words,
  lemma,
  state,
  tag,
  description,
  replacements
) {
  return {
    id,
    locale_name,
    term_category_name,
    term_category_id,
    check_words,
    words,
    lemma,
    state,
    tag,
    description,
    replacements
  }
}

export default function TerminologyMainTable () {
  const [addNewAllowedListModal, setAddNewAllowedListModal] = useState(false)
  const [noData, setNoData] = useState(false)
  const {
    theme: { bgColors },
  } = useTheme()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [selected, setSelected] = useState([])
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddNewFields] = useState(false)
  const user = useSelector((state) => state.userData.user)
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector((state) => state.userData.token)
  const [letterRows, setLetterRows] = useState([])
  const forward = useRef(0)
  const [allDataCount, setAllDataCount] = useState()
  const [limitIs, setLimitIs] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [spinner, setSpinner] = useState(false)
  const [pageData, setPageData] = useState()
  const [deleteRecall, setDeleteRecall] = useState()
  // const [textValue, setTextValue] = useState("");
  const [deleteCheckedTermModal, setDeleteCheckedTermModal] = useState(false)
  const [deleteAlertMsg, setDeleteAlertMsg] = useState(false)
  const {
    t,
    i18n: { language: i18nLanguage },
  } = useTranslation()
  const languageIs = useRef()
  const [owners, setOwners] = useState()
  const [orderByValue, setOrderByValue] = useState('id')
  const [orderASCDESC, setOrderASCDESC] = useState(0)
  const [filter, setFilter] = useState(1)
  const handleFilter = (e) => setFilter(e.target.value)
  const [currentAllowedList, setCurrentAllowedList] = useState()
  // const [editModal, setEditModal] = useState(false);
  // const [termReplacementEdit, setTermReplacementEdit] = useState();
  // const [currentTerm, setCurrentTerm] = useState();
  // const dispatch = useDispatch();
  const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])
  const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})
  const textTone = useSelector(state => state.userData.textTone || [])

  // Set Language accordingly when user lands on archive page:
  useEffect(() => {
    languageIs.current = Object.keys(backendLanguages).filter(
      (lang) => backendLanguages[lang]['shortName'] === i18nLanguage
    )
  }, [i18nLanguage])

  // Set page to 1 & offset to 0 and checked archive to unchecked when limit is changed or language is changed or archive is changed :
  useEffect(() => {
    setCurrentPage(1)
    forward.current = 0
    setSelected([])
  }, [limitIs])

  const pageSetter = async (val) => {
    setCurrentPage(val)
    forward.current = val * limitIs - 10
    await callEndPoint()
  }

  const {
    actionUserGetTextTone
  } = useActions({
    actionUserGetTextTone: userGetTextTone(tlService)
  })

  useEffect(() => {
    /********* To Fetch the list of texttone list ************/
    actionUserGetTextTone(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const callEndPoint = async (val) => {

    if (isObjectEmpty(textTone)) {
      return false
    }

    setSpinner(true)
    switch (val) {
      case 0:
        forward.current = forward.current - limitIs
        if (selected.length !== 0) {
          setSelected([])
        }
        break
      case 1:
        forward.current = forward.current + limitIs
        if (selected.length !== 0) {
          setSelected([])
        }
        break
      default:
        break
    }
    let terms
    let filterIs
    if (val === 'delete') {
      forward.current = 0
      filterIs = {
        offset: forward.current,
        order_by: orderByValue,
        order: orderASCDESC,
        state: 0
      }
    } else {
      filterIs = {
        offset: forward.current,
        order_by: orderByValue,
        order: orderASCDESC,
        state: 0

      }
    }
    try {
      terms = await tlService.getFilteredTextbin(
        token,
        { limit: limitIs },
        filterIs
      )
      setSpinner(false)
      setNoData(false)
    } catch (e) {
      console.log('error', e)
      setSpinner(false)
      setPageData('')
      setNoData(true)
      switch (val) {
        case 0:
          forward.current = forward.current + limitIs
          break
        case 1:
          forward.current = forward.current - limitIs
          break
        default:
          break
      }
      setLetterRows('')
      // Setting Total page to 0 if no data found:
      setAllDataCount(0)
      // setting current input page no. to 1 and all data to empty as it will be 1 as well
      if (val !== 0 && val !== 1 && !!val) {
        setCurrentPage(1)
        // setAllData('');
      }
    }
    if (terms?.fullcount) {
      setAllDataCount(terms.fullcount)
    }
    if (terms?.data) {
      //  setUserIds
      let aryOfOwner = []
      // set of user id to check for user right:
      terms.data.map((el) => {
        aryOfOwner.push(el.owner)
      })
      setOwners(aryOfOwner)
      setPageData(terms?.data)
    }
    if (terms?.data) {
      let arry = []
      terms?.data.map((el) => {
        if (el !== undefined && el?.id) {
          const list = textTone.find(v => v.id = el.term_category_id)
          const localeName = list ? convertLanguagesToShort(list.locale_name) : convertLanguagesToShort(i18nLanguage)
          arry.push(
            createData(
              el.id,
              localeName,
              el.term_category_name,
              el.term_category_id,
              el.check_words,
              el.words,
              el.lemma,
              el.state,
              el.tag,
              el.description,
              el.replacements
            )
          )
        }
      })
      setLetterRows([...arry])
      let pageNumber = Math.ceil(allDataCount / limitIs)
      // When user delete, it should go to first page:
      if (val === 'delete') {
        setCurrentPage(1)
      }
      if (forward.current >= 0) {
        let currentPageIs
        if (currentPage < pageNumber) {
          if (val === 0) {
            currentPageIs = Math.floor(forward.current / limitIs - 1)
            if (currentPageIs <= 0) {
              setCurrentPage(1)
            } else {
              if (currentPageIs > 0) {
                setCurrentPage(currentPageIs)
              }
            }
          } else if (val === 1) {
            currentPageIs = Math.floor(forward.current / limitIs + 1)
            if (currentPageIs > 0) {
              setCurrentPage(currentPageIs)
            }
          }
        }
        // here currently when user page no. input and total page is name, this func run to decrease the page no. :
        if (val === 0) {
          // currentPageIs = Math.floor(forward.current / limitIs);
          currentPageIs = currentPage - 1
          if (currentPageIs > 0) {
            setCurrentPage(currentPageIs)
          }
        }
      }
    }
  }

  // Consecutive call!
  useEffect(() => {
    callEndPoint()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textTone, limitIs, deleteRecall, orderBy, orderASCDESC])

  const handleRequestSort = (event, property, orderBy_value) => {
    // const isAsc = orderBy === property && order === 'asc';
    // setOrder(isAsc ? 'desc' : 'asc');
    // setPage(0);
    // setRowsPerPage(10);
    setOrderBy(property)
    setOrderByValue(orderBy_value)
    if (orderASCDESC === 1) {
      setOrder('asc')
      setOrderASCDESC(0)
    } else {
      setOrder('desc')
      setOrderASCDESC(1)
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = letterRows?.length && letterRows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const deleteChecked = async () => {
    const elementsId = letterRows?.length
      ? letterRows.map((element) =>
        selected.includes(element.id) ? element.id : null
      )
      : []
    setSpinner(true)
    if (filter === 1) {
      for (let element of elementsId) {
        try {
          if (!!element) {
            await tlService.deleteTextbin(token, element)
            setDeleteCheckedTermModal(false)
          }
        } catch (e) {
          setDeleteAlertMsg(convertFromErrorObject(t, e))
          console.log('error', convertFromErrorObject(t, e))
          break
        }
      }
    } else if (filter === 2) {
      for (let element of letterRows) {
        try {
          if (!!element) {
            await tlService.deleteTextbin(token, element.id)
            setDeleteCheckedTermModal(false)
          }
        } catch (e) {
          setDeleteAlertMsg(convertFromErrorObject(t, e))
          console.log('error', convertFromErrorObject(t, e))
          break
        }
      }
    }
    setSelected([])
    // try to reduce offset by limit amount if delete whole data on one page:
    if (selected?.length === letterRows?.length || filter === 2) {
      forward.current = forward.current - limitIs
      setCurrentPage(currentPage - 1)
      // if the offset is negative than make it 0:
      if (Math.sign(forward.current) === -1) {
        forward.current = 0
      }
    }
    callEndPoint()
  }
  //check for enabled section
  if (enabledSections.indexOf('allowedlist') === -1)
    return <Redirect to={'/'}/>

  // check for  user right
  if (!checkUserRight(user, [701, 702, 703])) return null

  return (
    <UserProfileWrapper>
      <div className={`${style.mainCont}`}>
        {spinner && <Spinner/>}
        <Paper className={``}>
          <AddNewTermModal
            component="AdminTextbin"
            currentTerm={currentAllowedList}
            addAPI={tlService.addTextTone}
            editAPI={tlService.editTextbin}
            setDeleteRecall={setDeleteRecall}
            currentAllowedList={currentAllowedList}
            open={addNewAllowedListModal}
            setOpen={setAddNewAllowedListModal}
            callEndPoint={callEndPoint}
          />
          <TableContainer>
            <Table
              style={{
                backgroundColor: bgColors.bgPrimary100,
                borderColor: 'transparent',
              }}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              {checkUserRight(user, [701, 702, 703]) && (
                <AddNew
                  setAddNewAllowedListModal={setAddNewAllowedListModal}
                  setCurrentAllowedList={setCurrentAllowedList}
                />
              )}
              {/* <TableFilter/> */}
              <EnhancedTableHead
                deleteAlertMsg={deleteAlertMsg}
                deleteCheckedTermModal={deleteCheckedTermModal}
                setDeleteCheckedTermModal={setDeleteCheckedTermModal}
                deleteChecked={deleteChecked}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                openAddNewFields={openAddNewFields}
                rowCount={letterRows.length}
              />
              <TableBody>
                {letterRows?.length
                  ? letterRows.map((row, index) => {
                    /* {!!stableSort(letterRows, getComparator(order, orderBy)) &&
            stableSort(letterRows, getComparator(order, orderBy))
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => { */
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <Rows
                        component="textbin"
                        deleteAPI={tlService.deleteTextbin}
                        updateAPI={tlService.updateTextbin}
                        key={index}
                        handleClick={handleClick}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        row={row}
                        isEven={index % 2 === 0}
                        setDeleteRecall={setDeleteRecall}
                        owners={owners}
                        setSelected={setSelected}
                        setAddNewAllowedListModal={setAddNewAllowedListModal}
                        currentAllowedList={currentAllowedList}
                        setCurrentAllowedList={setCurrentAllowedList}
                        callEndPointReCall={callEndPoint}
                      />
                    )
                  })
                  : noData && (
                  <TableRow>
                    <TableCell
                      colSpan="10"
                      style={{
                        height: '400px',
                        textAlign: 'center',
                        marginTop: '140px',
                      }}
                    >
                      <h2> {t('no-data')} </h2>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter
                offsetIs={forward.current}
                handleFilter={handleFilter}
                allDataCount={allDataCount}
                pageSetter={pageSetter}
                currentPage={currentPage}
                pageData={pageData}
                callEndPointForward={callEndPoint}
                callEndPointBackward={callEndPoint}
                setLimitIs={setLimitIs}
                filter={filter}
                selected={selected}
              />
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </UserProfileWrapper>
  )
}
