import 'date-fns'
import React, { useState, useEffect } from 'react'
import { useTheme } from "@material-ui/core/styles"
import TableHead from "@material-ui/core/TableHead"
import style from "./style.module.sass"
import TableCell from "@material-ui/core/TableCell"
import { Box, Button, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import TableRow from '@material-ui/core/TableRow'
import { useTranslation } from "react-i18next"

export default function TableFilter({ groups, setGroupIs, companyIs, setCompanyIs, company, callEndPoint, companyId, groupId, setCompanyId, setGroupId }) {
  const { theme: { bgColors, colors } } = useTheme()
  const [filter, setFilter] = useState('')
  const { t } = useTranslation()
  const [currentGroups, setCurrentGroups] = useState()

  const onGroupSelect = (event, value, reason) => {
    if (reason === "clear") {
      callEndPoint("", "", companyId)
      setGroupId("")
    }
    setGroupIs(event.target.outerText)
  }

  const onCompanySelect = (event, value, reason) => {
    if (reason === "clear") {
      callEndPoint("", groupId)
      setCompanyId("")
    }
    setCompanyIs(event.target.outerText)
  }

  // Set current groups according to the company selected:
  useEffect(() => {
    let currentCompany = company?.length && company.filter(el => el.name === companyIs)
    if (currentCompany?.length) {
      let currentGroups = groups?.length && groups.filter(el => el.company_id === currentCompany[0].id)
      setCurrentGroups(currentGroups)
    }
  }, [companyIs])

  return (
    <TableHead style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.tableFilter}`}>
      <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Box style={{ color: colors.colorPrimary500 }}
            className={`d-flex justify-content-between align-items-end flex-column-reverse p-1 pl-3`} margin={1}>
            <div className={`d-flex align-items-center pb-2`} style={{ marginRight: "auto" }}>
              <Autocomplete
                // onChange={(event, value) => !!value?.name ? setGroupIs(value.name) : setGroupIs('')}
                onChange={onCompanySelect}
                // getOptionSelected={(option, value) => option.name === value.name}
                id="combo-box"
                options={!!company ? company : []}
                getOptionLabel={(option) => option.name}
                className={`d-flex align-items-center mr-3`}
                style={{ width: 300, height: "52px" }}
                renderInput={(params) => <TextField {...params} style={{ height: "52px" }} label={t("company")}
                  onChange={(e) => setCompanyIs(e.target.value)}
                  variant="filled" />}
              />
              {
                companyIs ?
                  <Autocomplete
                    // onChange={(event, value) => !!value?.name ? setGroupIs(value.name) : setGroupIs('')}
                    onChange={onGroupSelect}
                    // getOptionSelected={(option, value) => option.name === value.name}
                    id="combo-box"
                    options={!!currentGroups ? currentGroups : []}
                    getOptionLabel={(option) => option.name}
                    className={`d-flex align-items-center mr-3`}
                    style={{ width: 300, height: "52px" }}
                    renderInput={(params) => <TextField {...params} style={{ height: "52px" }} label={t("group")}
                      onChange={(e) => setGroupIs(e.target.value)}
                      variant="filled" />}
                  />
                  :
                  <Autocomplete
                    disabled
                    // onChange={(event, value) => !!value?.name ? setGroupIs(value.name) : setGroupIs('')}
                    onChange={onGroupSelect}
                    // getOptionSelected={(option, value) => option.name === value.name}
                    id="combo-box"
                    options={!!currentGroups ? currentGroups : []}
                    getOptionLabel={(option) => option.name}
                    className={`d-flex align-items-center mr-3`}
                    style={{ width: 300, height: "52px" }}
                    renderInput={(params) => <TextField {...params} style={{ height: "52px" }} label={t("group")}
                      onChange={(e) => setGroupIs(e.target.value)}
                      variant="filled" />}
                  />}
              {
                filter ?
                  <Button onClick={() => setFilter('')} size='small' color="secondary" fullWidth variant='contained'>
                    {t('clear-filter')}
                  </Button> : ""
              }
            </div>
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

// const selectVariants = [
//   { title: 'filter' },
//   { title: 'filter 2' },
//   { title: 'filter 3' },
//   { title: 'filter 4' },
//   { title: 'filter 5' },
//   { title: "filter 6" },
//   { title: 'filter 7' },
// ];
