import homeIcon from '../components/Main-page-wrapper/Dashboard/assets/homeIcon.svg'
//import dashboardIcon from "../components/Main-page-wrapper/Dashboard/assets/dashboardIcon.svg";
import statistikIcon from '../components/Main-page-wrapper/Dashboard/assets/statistikIcon.svg'
import archivIcon from '../components/Main-page-wrapper/Dashboard/assets/archivIcon.svg'
import settingsIcon from '../components/Main-page-wrapper/Dashboard/assets/settingsIcon.svg'
import combotIcon from '../components/Main-page-wrapper/Dashboard/assets/combotIcon.svg'
import lightBulbIcon from '../components/Main-page-wrapper/Dashboard/assets/light-bulb-idea.svg'
//import wordMicrosoftIcon from "../components/Main-page-wrapper/Dashboard/assets/wordMicrosoftIcon.svg";
//import supportIcon from "../components/Main-page-wrapper/Dashboard/assets/supportIcon.svg";
//import personIcon from "../components/Main-page-wrapper/Header/assets/Icon-material-person.svg";
import Spell from '../components/Analysis/Tabs/Accordion/Blocks/Spell'
import LinguisticClimate from '../components/Analysis/Tabs/Accordion/Blocks/LinguisticClimate'
import Readability from '../components/Analysis/Tabs/Accordion/Blocks/Readability'
import Terminology from '../components/Analysis/Tabs/Accordion/Blocks/Terminology'
import CorporateLanguage from '../components/Analysis/Tabs/Accordion/Blocks/CorporateLanguage'
import Tonality from '../components/Analysis/Tabs/Accordion/Blocks/Tonality'
import GrammarAndStyle from '../components/Analysis/Tabs/Accordion/Blocks/GrammarAndStyle'
import TerminologyModal from '../components/Analysis/ModalWindows/TerminologyModal'
import SpellModal from '../components/Analysis/ModalWindows/SpellModal'
import LinguisticClimateModal from '../components/Analysis/ModalWindows/LinguisticClimateModal'
import InfoModal from '../components/Analysis/ModalWindows/InfoModal'
import ReadabilityModal from '../components/Analysis/ModalWindows/ReadabilityModal'
import LanguageToolModal from '../components/Analysis/ModalWindows/LanguageToolModal'
import benutzerIcon from '../components/Administration/assets/benutzerIcon.svg'
//import cleanUpRulesIcon from "../components/Administration/assets/cleanUpRulesIcon.svg";
import groupIcon from '../components/Administration/assets/groupIcon.svg'
//import infoIcon from "../components/Administration/assets/InfoIcon.svg";
import terminologiesIcon from '../components/Administration/assets/terminoliesIcon.svg'
import textSortIcon from '../components/Administration/assets/textSortIcon.svg'
import React from 'react'
import Experimental from '../components/Analysis/Tabs/Accordion/Blocks/Experimental'
import SuggestionModal from '../components/Analysis/ModalWindows/SuggestionModal'
import { HelpOutline } from '@material-ui/icons'
//import ApartmentIcon from "@material-ui/icons/Apartment";

const languageLevelToString = [
  { level: 'A1', threshold: 94, color: '#327D15' },
  { level: 'A2', threshold: 84, color: '#327D15'  },
  { level: 'B1', threshold: 74, color: '#4BBD1F'  },
  { level: 'B2', threshold: 70, color: '#BD911F'  },
  { level: 'C1', threshold: -100, color: '#BD421F'  },
  { level: 'C2', threshold: -1000, color: '#ff0707'  },
]
// Category Type List:
const categoryTypeList = [
  { id: 'positive', name: 'cattype_positive' },
  { id: 'negative', name: 'cattype_negative' },
  { id: 'allowedlist', name: 'cattype_allowedList' },
  // { id: 'tonality', name: "cattype_tonality" },
  // { id: 'color', name: "cattype_color" },
  // { id: 'nominal', name: "cattype_nominal" },
  // { id: 'personal/neutral', name: "cattype_personal/neutral" }
]

const clixCategories = [
  'colorBlue',
  'colorGreen',
  'colorRed',
  'colorYellow'
]

const clixDigits = [
  'formulaHix',
  'formulaPersonalNeutralScaleValue',
  'formulaTonalityScaleValue',
  'countScoreNeutral',
  'countScorePersonal',
  'countSttr',
]

const clixArrays = [
  'countFutureTenseInSentence',
  'countInfinitiveConstructions',
  'countNominalStyle',
  'countPassiveVoiceInSentence',
  'moreClausesInWords',
  'moreParagraphsInWords',
  'moreSentencesInBrackets',
  'moreSentencesInClauses',
  'moreSentencesInPrepositions',
  'moreSentencesInWords',
  'moreWordsInLetters',
]

const compareShowValues = [
  {
    name: 'moreWordsInLetters',
    prefix: 'arraymax',
    type: 'arraysize',
    title: 'LONG WORDS',
    subTitle: 'Longest words',
    lText: '',
    lTitle: 'Words are too long'
  },
  {
    name: 'moreSentencesInClauses',
    prefix: 'arraymax',
    type: 'arraysize',
    title: 'COMPLEX SENTENCES',
    subTitle: 'Complicated sentence',
    lText: '',
    lTitle: 'Sentences are too complicated'
  },
  {
    name: 'moreSentencesInWords',
    prefix: 'arraymax',
    type: 'arraysize',
    title: 'LONG SENTENCES',
    subTitle: 'Longest sentence',
    lText: '',
    lTitle: 'Sentences are too long'
  }
]

const compareShowValuesCLIX = []

const dataHixValues = [
  'dataHix.Amstad',
  'dataHix.Lix',
  'dataHix.GSMOG',
  'dataHix.WSTF'
]

const compareThemeColors = {
  red: '#b00014',
  yellow: '#dd540a',
  green: '#9db10d',
  blue: '#415C98'
}

const userSettingColor = [
  { name: 'colorBlue', color: '#0643cc' },
  { name: 'colorRed', color: '#DD0D08' },
  { name: 'colorYellow', color: '#ffa603' },
  { name: 'colorGreen', color: '#43AB1B' }
]

const archiveTypes = ['user', 'user_group', 'company']

const defaultArchiveColumns = ['archive_name', 'owner', 'benchmark_id', 'create_time', 'formulaSPIX', 'formulaHix', 'formulaCLIX', 'update_time']

const defaultUsersColumns = ['user_name', 'active', 'last_name', 'user_group_name', 'company_name', 'create_time']

const singleGroupLevel = [
  {
    id: 101,
    control: 'switch', labelPlacementEnd: true,
  },
  {
    id: 102,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 103,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 104,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 105,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 106,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 109,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 110,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 121,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 123,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 131,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 141,
    control: 'switch',
    labelPlacementEnd: true,
  },
]

const companyLevel = [
  {
    id: 201,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 202,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 203,
    control: 'switch', labelPlacementEnd: true,
  },
  {
    id: 204,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 205,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 206,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 207,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 208,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 209,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 210,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 221,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 223,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 231,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 241,
    control: 'switch',
    labelPlacementEnd: true,
  }
]

const overCompanyLevel = [
  {
    id: 306,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 307,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 308,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 309,
    control: 'switch',
    labelPlacementEnd: true,
  }
]

const specialRights = [
  {
    id: 401,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 402,
    control: 'switch',
    labelPlacementEnd: true,
  },
  {
    id: 403,
    control: 'switch',
    labelPlacementEnd: true,
  }
]

const termHeaders =
  {
    'words': { 'de_DE': 'Begriff', 'en_GB': 'words' },
    'description': { 'de_DE': 'Erklärung', 'en_GB': 'description' },
    'replacements': { 'de_DE': 'Ersetzung', 'en_GB': 'replacement_words' }
  }

const chartMax = {
  hix: 20,
  clix: 100,
  flesch: 100
}

const userRights = {
  'rights_label_101': 101,
  'rights_label_102': 102,
  'rights_label_103': 103,
  'rights_label_104': 104,
  'rights_label_105': 105,
  'rights_label_106': 106,
  'rights_label_109': 109,
  'rights_label_110': 110,
  'rights_label_121': 121,
  'rights_label_123': 123,
  'rights_label_131': 131,
  'rights_label_141': 141,

  'rights_label_201': 201,
  'rights_label_202': 202,
  'rights_label_203': 203,
  'rights_label_204': 204,
  'rights_label_205': 205,
  'rights_label_206': 206,
  'rights_label_207': 207,
  'rights_label_208': 208,
  'rights_label_209': 209,
  'rights_label_210': 210,
  'rights_label_221': 221,
  'rights_label_223': 223,
  'rights_label_231': 231,
  'rights_label_241': 241,

  'rights_label_306': 306,
  'rights_label_307': 307,
  'rights_label_308': 308,
  'rights_label_309': 309,

  'rights_label_401': 401,
  'rights_label_402': 402,
  'rights_label_403': 403,
}

const userRightsGroupLevel = [
  101,
  102,
  103,
  104,
  105,
  106,
  109,
  110,
  121,
  123,
  131,
  141
]

const autoAnalyseLanguage = {
  auto: { nativeName: 'Auto' }
}

const neededValues = [
  'formulaHix',
  'formulaFlesch',
  'formulaCLIX'
]

const options = {
  'maximum_users': '',
  'enabled_benchmark_templates': '',
  'edit_archives': '',
  'default_language': '',
  'analyse_language_texttype': '',
  'enabled_frontend_sections': '',
  'enabled_export_template': '',
  'export_template_word': '',
  'enable_textbin': false,
  'default_company_settings': '',
  'enabled_texttypes': '',
  'view_archives': '',
  'color_schema': '',
  'analyse_tonality': '',
  'tonality_visibility': false,
  'export_template_single': '',
  'export_template_excel': '',
  'modal_settings': '',
  'expired_time': '',

}

const enabledUserOptions = [
  'expired_time'
]

// administration array, backend emulating
const administration = [
  {
    section: 'administration',
    rights: [101, 201],
    id: 0, title: 'Users',
    logo: <img className={`mr-4`} src={benutzerIcon} alt=""/>,
    link: '/administration/user',
    content: [
      { text: 'User Profiles', link: '/administration/user', rights: [101, 201], section: 'administration' },
    ]
  },
  {
    section: 'administration',
    rights: [206],
    id: 1, title: 'Groups',
    logo: <img className={`mr-4`} src={groupIcon} alt=""/>,
    link: '/administration/groups',
    content: [
      { text: 'Groups', link: '/administration/groups', rights: [206], section: 'administration' },
      // { text: "Group options", link: "/administration/einstellung", rights: [209], section: "administration" }
    ]
  },
  {
    section: 'administration',
    rights: [601, 602],
    id: 3, title: 'Text types',
    logo: <img className={`mr-4`} src={textSortIcon} alt=""/>,
    link: '/administration/text-types',
    content: [
      { text: 'Text types', link: '/administration/text-types', rights: [601, 602], section: 'administration' }
    ]
  },
  {
    section: 'administration',
    rights: [701, 702, 703],
    id: 2, title: 'Terminology menu',
    logo: <img className={`mr-4`} src={terminologiesIcon} alt=""/>,
    link: '/administration/terminology',
    content: [
      { text: 'Word lists', link: '/administration/terminology', rights: [701, 702, 703], section: 'administration' },
      {
        text: 'Allowed terms',
        link: '/administration/terminology/allowedList',
        rights: [701, 702, 703],
        section: 'allowedlist'
      },
      { text: 'textbins', link: '/administration/terminology/textbin', rights: [701, 702, 703], section: 'textbin' }

      //  { text: "Replacements", link: "/administration/terminology", rights: [701, 702, 703] }
    ]
  },
  // {
  //     rights: [601,602],
  //     id: 4, title: "Cleanup rules",
  //     logo: <img className={`mr-4`} src={cleanUpRulesIcon} alt=""/>,
  //     link: "/administration/cleaning-rules",
  //     content: [
  //         {text: "Cleanup rules", link: "/administration/cleaning-rules", rights: [601,602]},
  //         {text: "Placeholder", link: "/administration/platzhalter", rights: [601,602]}
  //     ]
  // },
  // {  rights: [],
  //     id: 5, title: "Info",
  //     logo: <img className={`mr-4`} src={infoIcon} alt=""/>,
  //     link: "/administration/info/best-practice",
  //     content: [
  //         {text: "Best Practice", link: "/administration/info/best-practice", rights: []},
  //         {text: "Info Messages", link: "/administration/info/info-messages", rights: []},
  //         {text: "Logs", link: "/administration/info/logs", rights: [403]}
  //     ]
  // },
  // {
  //     rights: [306],
  //     id: 6, title: "Companies",
  //     logo: <div className={`mr-2 d-flex align-items-center justify-content-center`}
  //                          style={{borderRadius: "50%", width: "75px", height: "75px"}}>
  //         <ApartmentIcon style={{fill: "white", height: "45px", width: "45px"}}/>
  //     </div>,
  //     link: "/administration/company",
  //     content: [
  //         {text: "Companies", link: "/administration/company",rights: [306]},
  //         {text: "Company options", link: "/administration/company",rights: [309]},
  //     ]
  // },
]

// set links by config
let linksArr = [
  { to: '/', text: 'Start / Text editing', img: homeIcon, name: 'home' },
  { to: '/', text: 'ai_mode', img: combotIcon, name: 'ai-mode', section: 'double-interface' },
  { to: '/text-generation', text: 'navbar_text-generator', img: lightBulbIcon, section: 'double-interface' },
  { to: '/administration', text: 'Administration', img: settingsIcon, section: 'administration' },
  { to: '/archive', text: 'Archive', img: archivIcon, section: 'archive' },
  { to: '/dashboard/compare', text: 'Dashboard', img: statistikIcon, section: 'dashboard-compare' },
  { to: '/static/docs/TL8-Benutzer-Handbuch.pdf', text: 'TL8 Handbook', icon: HelpOutline, name: 'view-pdf' }
];


const dashboardParameters = {
  HIX: [
    'ColumnCharts'

  ]
}

const tonalityParameters = {
  formulaTonalityScaleValue: {
    appearFirst: 'Appear negative',
    appearSecond: 'Appear positive',
    explanation: 'Explanation positive-negative',
    firstPartChart: [
      { value: 'dataTonalityNegative', name: 'nameTonalityNegative', countBase: 'countWords', type: 'terms' },
      {
        value: 'countTonalityClauseNegative',
        name: 'nameTonalityClauseNegative',
        countBase: 'countClauses',
        type: 'array'
      }
    ],
    secondPartChart: [
      { value: 'dataTonalityPositive', name: 'nameTonalityPositive', countBase: 'countWords', type: 'terms' },
      {
        value: 'countTonalityClausePositive',
        name: 'nameTonalityClausePositive',
        countBase: 'countClauses',
        type: 'array'
      }
    ],
    nameOnTop: 'positive',
    scaleFrom: [-10, 'negative'],
    scaleTo: [10, 'positive'],
    defaultPosition: 0,
    scaleStep: 2
  },
  formulaPersonalNeutralScaleValue: {
    appearFirst: 'Appear neutral',
    appearSecond: 'Appear personal',
    explanation: 'Explanation personal-neutral',
    firstPartChart: [
      { value: 'dataTonalityNeutral', name: 'nameTonalityNeutral', countBase: 'countWords', type: 'terms' }
    ],
    secondPartChart: [
      { value: 'dataTonalityPersonal', name: 'nameTonalityPersonal', countBase: 'countWords', type: 'terms' }
    ],
    nameOnTop: 'personal',
    scaleFrom: [-10, 'neutral'],
    scaleTo: [10, 'personal'],
    defaultPosition: 0,
    scaleStep: 2
  }
}

// LExcel export list

const excelExportList = [
  {
    name: 'PartsOfSpeech',
    translation: 'PartsOfSpeech',
    children: [
      {
        name: 'countNouns',
        request: 'countNouns',
        translation: 'countNouns1',
        children: [],
        language: ['en_GB', 'de_DE']

      },
      {
        name: 'countAdjectives',
        request: 'countAdjectives',
        translation: 'countAdjectives1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countNumbers',
        request: 'countNumbers',
        translation: 'countNumbers',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countVerbs',
        request: 'countVerbs',
        translation: 'countVerbs1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countArticles',
        request: 'countArticles',
        translation: 'countArticles1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countParticles',
        request: 'countParticles',
        translation: 'countParticles1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countPronouns',
        request: 'countPronouns',
        translation: 'countPronouns1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countConjunctions',
        request: 'countConjunctions',
        translation: 'countConjunctions1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countPrepositions',
        request: 'countPrepositions',
        translation: 'countPrepositions1',
        children: [],
        language: ['en_GB', 'de_DE']
      }, {
        name: 'countMisc',
        request: 'countMisc',
        translation: 'countMisc1',
        children: [],
        language: ['en_GB', 'de_DE']

      }
    ]
  },
  {
    name: 'Counts',
    translation: 'Counts',
    children: [
      {
        name: 'countHeading',
        request: 'countHeading',
        translation: 'countHeading1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countParagraphs',
        request: 'countParagraphs',
        translation: 'countParagraphs1',
        children: [],
        language: ['en_GB', 'de_DE']

      },
      {
        name: 'countSubjunctiveClauses',
        request: 'countParcountSubjunctiveClausesagraphs',
        translation: 'countSubjunctiveClauses1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countSubjunctiveVerbs',
        request: 'countSubjunctiveVerbs',
        translation: 'countSubjunctiveVerbs1',
        children: [],
        language: ['en_GB', 'de_DE']

      },
      {
        name: 'countSentences',
        request: 'countSentences',
        translation: 'countSentences1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countClauses',
        request: 'countClauses',
        translation: 'countClauses1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countSyllables',
        request: 'countSyllables',
        translation: 'countSyllables1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countCharactersNoSpaces',
        request: 'countCharactersNoSpaces',
        translation: 'countCharactersNoSpaces1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countCharacters',
        request: 'countCharacters',
        translation: 'countCharacters1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countWords',
        request: 'countWords',
        translation: 'countWords1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countLongestWordInCharacters',
        request: 'countLongestWordInCharacters',
        translation: 'countLongestWordInCharacters1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countLongestSentenceInWords',
        request: 'countLongestSentenceInWords',
        translation: 'countLongestSentenceInWords1',
        children: [],
        language: ['en_GB', 'de_DE']

      },
    ]
  },
  {
    name: 'GrammarAndStyle',
    translation: 'GrammarAndStyle',
    children: [
      {
        name: 'countPassiveVoiceInSentence',
        request: 'countPassiveVoiceInSentence',
        translation: 'countPassiveVoiceInSentence1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countFutureTenseInSentence',
        request: 'countFutureTenseInSentence',
        translation: 'countFutureTenseInSentence1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countInfinitiveConstructions',
        request: 'countInfinitiveConstructions',
        translation: 'countInfinitiveConstructions1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countNominalStyle',
        request: 'countNominalStyle',
        translation: 'countNominalStyle1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'neutralInfinitive',
        request: 'neutralInfinitive',
        translation: 'neutralInfinitive1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'neutralPassive',
        request: 'neutralPassive',
        translation: 'neutralPassive1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'countSttr',
        request: 'countSttr',
        translation: 'countSttr1',
        children: [],
        language: ['en_GB', 'de_DE']

      },
    ]
  },
  {
    name: 'Readability',
    translation: 'Readability',
    children: [
      {
        name: 'meanSentenceLengthInLetters',
        request: 'meanSentenceLengthInLetters',
        translation: 'meanSentenceLengthInLetters1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'meanSentenceLengthInSyllables',
        request: 'meanSentenceLengthInSyllables',
        translation: 'meanSentenceLengthInSyllables1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'meanSentenceLengthInWords',
        request: 'meanSentenceLengthInWords',
        translation: 'meanSentenceLengthInWords1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'meanWordLengthInLetters',
        request: 'meanWordLengthInLetters',
        translation: 'meanWordLengthInLetters1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'meanWordLengthInSyllables',
        request: 'meanWordLengthInSyllables',
        translation: 'meanWordLengthInSyllables1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'meanClauseLengthInLetters',
        request: 'meanClauseLengthInLetters',
        translation: 'meanClauseLengthInLetters1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'meanClauseLengthInSyllables',
        request: 'meanClauseLengthInSyllables',
        translation: 'meanClauseLengthInSyllables1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'meanClauseLengthInWords',
        request: 'meanClauseLengthInWords',
        translation: 'meanClauseLengthInWords1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'moreParagraphsInWords',
        request: 'moreParagraphsInWords',
        translation: 'moreParagraphsInWords',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'moreClausesInWords',
        request: 'moreClausesInWords',
        translation: 'moreClausesInWords',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'moreSentencesInClauses',
        request: 'moreSentencesInClauses',
        translation: 'moreSentencesInClauses',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'moreSentencesInWords',
        request: 'moreSentencesInWords',
        translation: 'moreSentencesInWords',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'moreWordsInLetters',
        request: 'moreWordsInLetters',
        translation: 'moreWordsInLetters',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'moreSentencesInBrackets',
        request: 'moreSentencesInBrackets',
        translation: 'moreSentencesInBrackets',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'moreSentencesInPrepositions',
        request: 'moreSentencesInPrepositions',
        translation: 'moreSentencesInPrepositions',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'moreSentencesInVerbalBlocks',
        request: 'moreSentencesInVerbalBlocks',
        translation: 'moreSentencesInVerbalBlocks',
        children: [],
        language: ['en_GB', 'de_DE']

      }
    ]
  },
  {
    name: 'Tonality',
    translation: 'Tonality',
    children: [
      {
        name: 'formulaTonalityScaleValue',
        request: 'formulaTonalityScaleValue',
        translation: 'formulaTonalityScaleValue1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'formulaPersonalNeutralScaleValue',
        request: 'formulaPersonalNeutralScaleValue',
        translation: 'formulaPersonalNeutralScaleValue1',
        children: [],
        language: ['en_GB', 'de_DE']

      },
    ]
  },
  {
    name: 'Formulas',
    translation: 'Formulas',
    children: [
      {
        name: 'formulaHix',
        request: 'formulaHix',
        translation: 'formulaHix1',
        children: [],
        language: ['de_DE']

      }, {
        name: 'formulaCLIX',
        request: 'formulaCLIX',
        translation: 'formulaCLIX1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'formulaSPIX',
        request: 'formulaSPIX',
        translation: 'formulaSPIX1',
        children: [],
        language: ['en_GB']

      },
    ]
  },
  {
    name: 'formulaHix',
    translation: 'dataHix',
    baseOn: 'formulaHix',
    children: [
      {
        name: 'dataHix.Amstad',
        request: 'dataHix.Amstad',
        translation: 'dataHix.Amstad',
        children: [],
        language: ['de_DE']
      }, {
        name: 'dataHix.Lix',
        request: 'dataHix.Lix',
        translation: 'dataHix.Lix',
        children: [],
        language: ['de_DE']
      }, {
        name: 'dataHix.GSMOG',
        request: 'dataHix.GSMOG',
        translation: 'dataHix.GSMOG',
        children: [],
        language: ['de_DE']
      }, {
        name: 'dataHix.WSTF',
        request: 'dataHix.WSTF',
        translation: 'dataHix.WSTF',
        children: [],
        language: ['de_DE']

      },
    ]
  },
  {
    name: 'Spelling',
    translation: 'Spelling',
    children: [
      {
        name: 'languageTool',
        request: 'languageTool',
        translation: 'languageTool1',
        children: [],
        language: ['en_GB', 'de_DE']

      }, {
        name: 'dataHunSpell',
        request: 'dataHunSpell',
        translation: 'dataHunSpell1',
        children: [],
        language: ['en_GB', 'de_DE']

      }
    ]
  },
  {
    name: 'Terminology',
    translation: 'Terminology',
    startWith: 'dataTerms.',
    children: []
  },
  {
    name: 'CorporateLanguage',
    translation: 'CorporateLanguage',
    startWith: 'dataCorporateLanguage.',
    children: [
      {
        ignoreStart: true,
        name: 'countMarkExclamation',
        request: 'countMarkExclamation',
        translation: 'countMarkExclamation',
        children: [],
        language: ['en_GB', 'de_DE']

      }
    ]
  },
  {
    name: 'Colorwords',
    translation: 'Colorwords',
    children: [
      {
        name: 'colorBlue',
        request: 'colorBlue',
        translation: 'colorBlue',
        children: [],
        language: ['de_DE']

      }, {
        name: 'colorRed',
        request: 'colorRed',
        translation: 'colorRed',
        children: [],
        language: ['de_DE']

      }, {
        name: 'colorGreen',
        request: 'colorGreen',
        translation: 'colorGreen',
        children: [],
        language: ['de_DE']

      }, {
        name: 'colorYellow',
        request: 'colorYellow',
        translation: 'colorYellow',
        children: [],
        language: ['de_DE']

      },
    ]
  },
]

const config = {
  aiDefaultTextSize: 4000,
  clixDisplay: [
    {
      title: 'Spelling',
      subCat: [
        'languageTool',
        'dataHunSpell'
      ]
    },
    {
      title: 'Counts',
      subCat: [
        'countLongestSentenceInWords',
        'countNumbers',
        'countSentences',
        'countClauses',
        'countSyllables',
        'countCharactersNoSpaces',
        'countCharacters',
        'countWords',
        'countLongestWordInCharacters',
        'countHeading',
        'countParagraphs'
      ]
    },
    {
      title: 'Readability',
      subCat: [
        'moreClausesInWords',
        'moreSentencesInClauses',
        'moreSentencesInWords',
        'moreWordsInLetters',
        'meanSentenceLengthInLetters',
        'meanSentenceLengthInSyllables',
        'meanSentenceLengthInWords',
        'meanWordLengthInLetters',
        'meanWordLengthInSyllables',
        'meanClauseLengthInLetters',
        'meanClauseLengthInSyllables',
        'meanClauseLengthInWords',
        'moreParagraphsInWords',
        'moreSentencesInBrackets',
        'moreSentencesInPrepositions',
        'moreSentencesInVerbalBlocks'
      ]
    },
    {
      title: 'Linguistic climate',
      subCat: [
        'colorBlue',
        'colorRed',
        'colorGreen',
        'colorYellow'
      ]
    },
    {
      title: 'Terminology',
      subCat: [
        // 'Nominalisierungen',
        // 'Finanzen und Versicherungen',
        // 'Doppelt gemoppelt',
        // 'Gender',
        // 'Komposita',
        // 'Juristendeutsch',
        // 'Negative Formulierungen',
        // 'Konjunktiv und vage',
        // 'Floskeln und Verstaubtes',
        // 'Attribute',
        // 'Verneinung',
        // 'Abkürzungen',
        // 'Füllwörter'
      ]
    },
    {
      title: 'CorporateLanguage',
      subCat: [
        // 'dataCorporateLanguage'
        // "Stopp!",
        // "Spelling rules",
        // "Corporate Wording",
        // "Call to action",
        // "Anglizisms",
        // "Bank detail",
        // "Date",
        // "Phone numbers",
        // "Time",
        // "Currency",
        // "Exclamation Marks",
        // "Filler words",
      ]
    },
    {
      title: 'Tonality',
      subCat: [
        'formulaTonalityScaleValue',
        'formulaPersonalNeutralScaleValue',
        'countTonalityAllPositive',
        'countTonalityAllNegative',
        'countTonalityClausePositive',
        'countTonalityClauseNegative',
        'countTonalityParagraphPositive',
        'countTonalityParagraphNegative',
        'dataTonalityPositive',
        'dataTonalityNegative',
        'countTonalityAllPersonal',
        'countTonalityAllNeutral',
        'countTonalityClausePersonal',
        'countTonalityClauseNeutral',
        'countTonalityParagraphPersonal',
        'countTonalityParagraphNeutral',
        'dataTonalityPersonal',
        'dataTonalityNeutral',
        'countScorePersonal',
        'countScoreNeutral'
      ]
    },
    {
      title: 'GrammarAndStyle',
      subCat: [
        'countPassiveVoiceInSentence', 'countInfinitiveConstructions',
        'countFutureTenseInSentence', 'countNominalStyle',
        'countSttr'
      ]
    },
    {
      title: 'PartsOfSpeech',
      subCat: [
        'countNouns',
        'countAdverbs',
        'countAdjectives',
        'countVerbs',
        'countArticles',
        'countParticles',
        'countPronouns',
        'countConjunctions',
        'countPrepositions',
        'countMisc',
      ]
    }
  ],

  multiWords: [
    'moreParagraphsInWords',
    'moreClausesInWords',
    'moreSentencesInBrackets',
    'moreSentencesInClauses',
    'moreSentencesInPrepositions',
    'moreSentencesInWords',
    'moreSentencesInVerbalBlocks'],

  minPasswordLength: 5,
  minRestorePasswordCodeLength: 5,
  dashboardParameters: dashboardParameters,
  hixFleschFilter: [
    'moreParagraphsInWords',
    'moreClausesInWords',
    'moreSentencesInClauses',
    'moreSentencesInWords',
    'moreSentencesInBrackets',
    'moreSentencesInPrepositions',
    'moreWordsInLetters',
    'moreSentencesInVerbalBlocks'
  ],
  dangerousTags: [
    'pre',
    'div',
    'comment',
    'embed',
    'link',
    'listing',
    'meta',
    'noscript',
    'object',
    'plaintext',
    'script',
    'xmp'
  ],
  modalAccordionComponents: {
    Spell: Spell,
    LinguisticClimate: LinguisticClimate,
    Readability: Readability,
    CorporateLanguage: CorporateLanguage,
    Terminology: Terminology,
    Tonality: Tonality,
    GrammarAndStyle: GrammarAndStyle,
    Experimental: Experimental
  },
  modalComponents: {
    TextClassification: InfoModal,
    LanguageTool: LanguageToolModal,
    Spell: SpellModal,
    LinguisticClimate: LinguisticClimateModal,
    Readability: ReadabilityModal,
    ReadabilitySuggestion: SuggestionModal,
    GrammarAndStyle: SuggestionModal,
    PositiveTerminology: InfoModal,
    AllowList: InfoModal,
    CorporateLanguage: TerminologyModal,
    Terminology: TerminologyModal,
    Tonality: InfoModal
  },

  accordions: {
    Spell: [
      'languageTool',
      'dataHunSpell'
    ],
    LinguisticClimate: [
      'dataColorWords'
    ],
    Experimental: [
      'dataTextClassification'
    ],
    Readability: [
      'moreClausesInWords', 'moreSentencesInClauses',
      'moreSentencesInWords', 'moreWordsInLetters',
      'moreSentencesInVerbalBlocks'
    ],
    Terminology: [
      'dataTerms'
    ],
    CorporateLanguage: [
      'dataCorporateLanguage', 'countMarkExclamation'
    ],
    Tonality: [
      'formulaTonalityScaleValue', 'formulaPersonalNeutralScaleValue'
    ],
    GrammarAndStyle: [
      'countPassiveVoiceInSentence', 'countInfinitiveConstructions',
      'countFutureTenseInSentence', 'countNominalStyle'
    ]
  },
  fullCountBase: {
    countMarkExclamation: 'countWords',
    countPassiveVoiceInSentence: 'countClauses',
    countInfinitiveConstructions: 'countClauses',
    countFutureTenseInSentence: 'countClauses',
    countNominalStyle: 'countClauses',
    countLongestSentenceInWords: 'countSentences',
    countLongestWordInCharacters: 'countWords',
    countTonalityAllPositive: 'countSentences',
    countTonalityAllNegative: 'countSentences',
    countTonalityClausePositive: 'countClauses',
    countTonalityClauseNegative: 'countClauses',
    countTonalityAllPersonal: 'countSentences',
    countTonalityAllNeutral: 'countSentences',
    countTonalityClausePersonal: 'countClauses',
    countTonalityClauseNeutral: 'countClauses',
    countScorePersonal: '',
    countScoreNeutral: '',
    formulaTonalityScaleValue: '',
    formulaPersonalNeutralScaleValue: '',
    formulaHix: '',
    formulaCLIX: '',
    dataTonalityPositive: 'countWords',
    dataTonalityNegative: 'countWords',
    dataTonalityPersonal: 'countWords',
    dataTonalityNeutral: 'countWords',
    moreSentencesInClauses: 'countSentences',
    moreClausesInWords: 'countClauses',
    moreSentencesInPhrases: 'countSentences',
    moreSentencesInWords: 'countSentences',
    moreWordsInLetters: 'countWords',
    moreSentencesInBrackets: 'countSentences',
    moreSentencesInPrepositions: 'countSentences'
  },
  tonalityParameters: tonalityParameters,
  minSize: 10,
  defaultAnalyseLanguageTextType: {
    language: 'auto',
    textType: {},
  },
  defaultAnalyseTonality: {
    remember: false,
    ...Object.entries(tonalityParameters).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value.defaultPosition }
    }, {})
  },
  defaultUserRedirects: {
    'redirect/Assistant': false,
    'redirect/Summ': false
  },
  themeSwitchIsAlwaysDisabled: true,
  messagesInterval: 60000,
  tokenUpdateInterval: 5000,
  linksArr,
  administration,
  timeOutDuration: 7000,
  localStorageTokenName: 'token',
  defaultTextType: 'letter',
  enabledThemes: ['light', 'dark'],
  termCategoryOptionSet: ['color'],
  meaningTypesList: {
    v: 'vovel',
    o: 'object',
    n: 'nonun'
  },
  messagesLevels: ['alert', 'warning', 'info'],
  messagesTemplates: ['text_message', 'text_message_no_answer', 'must_change_password', 'terms_and_conditions'],
  termSettings: ['color'],
  excelExportList,
  categoryTypeList,
  userSettingColor,
  archiveTypes,
  defaultArchiveColumns,
  defaultUsersColumns,
  userRights,
  options,
  enabledUserOptions,
  userRightsGroupLevel,
  singleGroupLevel,
  companyLevel,
  overCompanyLevel,
  specialRights,
  clixCategories,
  clixDigits,
  clixArrays,
  compareShowValues,
  compareThemeColors,
  dataHixValues,
  compareShowValuesCLIX,
  termHeaders,
  chartMax,
  autoAnalyseLanguage,
  neededValues,
  languageLevelToString
}

export default config