import React from "react";
import style from "./style.module.sass";
import triangle from "./assets/triangle.svg"
import infoIcon from "../../../../../assets/information-circle.svg"
import { useTheme } from '@material-ui/core/styles';
import disabledArrowLeft from "../../../../../assets/disabledArrowLeft.svg";
import disabledArrowRight from "../../../../../assets/disabledArrowRight.svg";
import activeArrowLeft from "../../../../../assets/activeArrowLeft.svg";
import activeArrowRight from "../../../../../assets/activeArrowRight.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ComponentWithPop from "../../../../common/ComponentWithPop";
import { convertNumberToStringLocally } from "../../../../../utils";
import { analyseSetTermCatTextTone } from '../../../../../actions'

export default function AccordionModalDefault({
    handleOnPopupClick,
    getNextPopup,
    handleModalAccordionOpen,
    cells,
    title,
    enabledTypes
}) {
    const { theme: { bgColors, colors } } = useTheme();
    //const [openInfo, setOpenInfo] = useState(false);
    const {
        t,
        i18n: { language: i18nLanguage },
    } = useTranslation();
    const dispatch = useDispatch();
    const modalAccordionOpen = useSelector(state => state.analyseData.modalAccordionOpen);
    const currentPopup = useSelector(state => state.analyseData.popover.currentPopup);

    const prevPopUp = getNextPopup(-1, currentPopup, enabledTypes);
    const nextPopUp = getNextPopup(1, currentPopup, enabledTypes);

    const accordionClick = (c) => {
        dispatch(analyseSetTermCatTextTone(c.title))
        handleModalAccordionOpen(modalAccordionOpen === c.id ? "" : c.id)
    }
    return (
        <ul className={`${style.mainCont}`}>
            {cells.map(c => (
                <li key={c.id} style={{ backgroundColor: modalAccordionOpen === c.id ? bgColors.bgPrimary0 : "" }}
                    className={`${style.row} ${modalAccordionOpen === c.id && style.open} p-2 mt-1`}>
                    <div className={`d-flex align-items-start pb-1 ${style.head}`}>
                        <p className={`mr-2 mb-0 font-weight-bold`}
                            style={{ color: c.colorTop }}>{c.num}{title === t('Linguistic climate') && "%"} </p>
                        <p onClick={() => accordionClick(c)}
                            style={{
                                color: modalAccordionOpen !== c.id ? colors.colorPrimary300 : colors.colorPrimary1000,
                                width: "200px"
                            }}
                            className={`${style.title} m-0`}>
                            {c.title}
                        </p>
                        <div style={{ margin: "0 0 0 auto" }}
                            className={`d-flex justify-content-end align-items-center`}>
                            <button
                                onClick={() => {
                                    if (modalAccordionOpen === c.id && prevPopUp > -1) {
                                        handleOnPopupClick(prevPopUp, c.title);
                                    }
                                }}
                                style={{ backgroundColor: "none", border: "none" }} className={`p-0 m-0`}><img
                                    className={`mr-1`}
                                    src={(modalAccordionOpen === c.id && prevPopUp > -1) ? activeArrowLeft : disabledArrowLeft}
                                    alt="" /></button>
                            <button
                                onClick={() => {
                                    if (modalAccordionOpen === c.id && nextPopUp > -1) {
                                        handleOnPopupClick(nextPopUp, c.title);
                                    }
                                }}
                                style={{ backgroundColor: "none", border: "none" }} className={`p-0 m-0`}><img
                                    src={(modalAccordionOpen === c.id && nextPopUp > -1) ? activeArrowRight : disabledArrowRight}
                                    alt="" /></button>
                        </div>
                    </div>
                    <div className={`${style.modalWin} mt-1`}>
                        <div className={`${style.ranger} d-flex`}>
                            <div style={{
                                width: `${c.scaleWidth === 0 ? 1 : c.scaleWidth}%`,
                                backgroundColor: c.colorBar
                            }} />
                            <img className={style.triangle} style={{ left: `calc(${c.targetTriangle}% - 12px)` }} src={triangle}
                                alt="" />
                        </div>
                        <div className={`d-flex align-items-center justify-content-between mt-2`}>
                            <p style={{ color: colors.colorPrimary500 }} className={`${style.desc} m-0`}>
                                {t("Proportion in text")}:  {t("Current")}
                                &nbsp;
                                {
                                    convertNumberToStringLocally(c.actual, i18nLanguage)
                                }
                                {c.absolute ? '' : '%'} | {t("Target")}
                                &nbsp;
                                {c.target}
                                {c.absolute ? '' : '%'}
                            </p>
                            <ComponentWithPop html={true} text={!!c.toolTip ? c.toolTip : ""}>
                                <img style={{ cursor: "pointer" }} src={infoIcon} alt={!!c.toolTip ? c.toolTip : ""} />
                            </ComponentWithPop>
                        </div>
                    </div>
                </li>
            )
            )}
            {
                // <ModalWin open={openInfo} setOpen={setOpenInfo}/>
            }

        </ul>
    )
}
