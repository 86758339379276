import { useTheme } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableCell from "@material-ui/core/TableCell";
import React, { useState, useEffect } from "react";
import { FormControl, IconButton, MenuItem, Select, TextField } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";

function TableFooter({
  allDataCount,
  pageSetter,
  currentPage,
  pageData,
  callEndPointForward,
  callEndPointBackward,
  setLimitIs
}) {
  const { theme: { bgColors, colors } } = useTheme();
  const [rows, setRows] = useState(10)
  const [totalPage, setTotalPage] = useState("");
  const [currentPageIs, setCurrentPageIs] = useState(currentPage)
  const { t } = useTranslation();

  useEffect(() => {
    let pageNumber = Math.ceil(allDataCount / rows);
    if (pageNumber === 0) {
      setTotalPage(1)
    } else {
      setTotalPage(pageNumber)
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData, allDataCount])

  useEffect(() => {
    if (totalPage <= currentPage) {
      setCurrentPageIs(totalPage);
    }
    else {
      setCurrentPageIs(currentPage);
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageData, allDataCount])

  useEffect(() => {
    setLimitIs(rows)
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  const onKeySubmit = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (e.target.value < 1) {
        pageSetter(1);
        setCurrentPageIs(1);
      } else {
        pageSetter(e.target.value);
      }
    }
  }

  const textInputfunc = (e) => {
    e.preventDefault();
    if (e.target.value <= totalPage && e.target.value >= 0) {
      setCurrentPageIs(e.target.value);
    } else {
      setCurrentPageIs(currentPage);
    }
  }

  return (
    <>
      <TableHead style={{ backgroundColor: bgColors.bgPrimary500 }} className={`${style.tableFilter}`}>
        <TableRow>
          <TableCell colSpan={12} padding="checkbox">
            <div className={style.pagination}>
              <p className={`mr-2 m-0`} style={{ color: colors.colorPrimary0 }}>{t('entries-per-page')}</p>
              <div style={{ backgroundColor: bgColors.bgBlue100 }}
                className={`pl-2 mr-2 ${style.perPage} d-flex align-items-center`}>
                <FormControl className={`ml-1`}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rows}
                    onChange={e => setRows(e.target.value)}
                    disableUnderline
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <TextField inputProps={{ maxLength: 3 }} value={currentPageIs} onChange={(e) => textInputfunc(e)}
                onKeyDown={(e) => {
                  onKeySubmit(e)
                }} style={{ backgroundColor: bgColors.bgBlue100, borderRadius: "4px" }}
                InputProps={{ disableUnderline: true }}
              />
              <span style={{ color: colors.colorPrimary0 }} className={`mr-2 ml-2`}>{t("of")}</span>
              <p style={{ color: colors.colorPrimary0 }} className={`mr-2 m-0`}>{!!totalPage && totalPage}</p>
              <IconButton style={{ color: colors.colorPrimary0 }} onClick={() => callEndPointBackward(0)} disabled={currentPageIs === 1}>
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton style={{ color: colors.colorPrimary0 }} onClick={() => callEndPointForward(1)} disabled={currentPageIs === totalPage}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

export default TableFooter
