import React, { useEffect, useState } from 'react'
import { TableRow, TableHead, TableCell, Box, Select, TextField, IconButton, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/styles'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useActions } from '../../../utils/action-helper'
import {
tableSetPageAndLimit
} from '../../../actions'

export default function TableFooter ({
  style,
  enableBottomPagination = true,
  handleNextPage,
  handleChangePage,
  type
}) {

  const { theme: { bgColors, colors } } = useTheme()
  const { t } = useTranslation()

  const {
    aTableSetPageAndLimit,
  } = useActions({
    aTableSetPageAndLimit: tableSetPageAndLimit
  })

  const currentPage = useSelector(state => state.tableData.currentPage)
  const pages = useSelector(state => state.tableData.pages)
  const limit = useSelector(state => state.tableData.limit)
  const otherData = useSelector(state => state.tableData.otherData)
  const pagination = useSelector(state => state.tableData.pagination) || []

  const [editCurrentPage, setEditCurrentPage] = useState(1)

  useEffect(()=>setEditCurrentPage(currentPage),[currentPage])
  const handleBlurPageEditor = (e)=>{
    handleChangePage(parseInt(e.target.value))
  }
  const handleChangePageEditor = (e)=>{
    setEditCurrentPage(e.target.value)
  }
  const handleOnPageEditorEnter = (event) => {
    if (event.keyCode === 13) {
      event.target.blur()
    }
  }

  const handleChangeLimit = (e)=>{
    aTableSetPageAndLimit(1, e.target.value)
  }

  return <TableHead style={{ backgroundColor: bgColors.bgPrimary500 }} className={`${style.tableFilter}`}>
    <TableRow>
      <TableCell colSpan={12} padding="checkbox">
        <Box style={{ color: colors.colorPrimary500 }}
             className={`d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2`} margin={1}>
          <p style={{ color: colors.colorPrimary0 }}
             className={`m-0`}>
            {type === 'archive' && (
              <>
                {otherData?.archives_fullcount ? otherData?.archives_fullcount : 0} {t('Analyzes')} &nbsp; | &nbsp;&nbsp;
                {otherData?.versions_fullcount ? otherData?.versions_fullcount : 0}  {t('Versions')}
              </>
            )}
            {type === 'benchmarks' && (
              <>
                {otherData?.benchmarks_fullcount ? otherData?.benchmarks_fullcount : 0} {t('admin_texttypes_footer-text')}
              </>
            )}
            {type === 'users' && (
              <>
                {otherData?.users_fullcount ? otherData?.users_fullcount : 0} {t('admin_user_footer-text')}
              </>
            )}
          </p>
          <div className={style.pagination}>
            <p className={`mr-2 m-0`} style={{ color: colors.colorPrimary0 }}>{t('entries-per-page')}</p>
            <div style={{ backgroundColor: bgColors.bgBlue100 }}
                 className={`pl-2 mr-2 ${style.perPage} d-flex align-items-center`}>
              <FormControl className={`ml-1`}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={limit}
                  onChange={handleChangeLimit}
                >
                  {pagination.map(v=><MenuItem value={v} key={v}>{v}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
            {enableBottomPagination ? <div className={style.pagination}>
              <TextField
                inputProps={{ maxLength: 3 }}
                type="number"
                value={editCurrentPage}
                onBlur={handleBlurPageEditor}
                onChange={handleChangePageEditor}
                onKeyUp={handleOnPageEditorEnter}
                style={{ backgroundColor: bgColors.bgBlue100, borderRadius: '4px' }}/>
              <span className={`mr-2 ml-2`}>{t('of')}</span>
              <p className={`mr-2 m-0`}>{pages}</p>
              <IconButton  style={{ color: colors.colorPrimary0 }} onClick={() => {handleNextPage(-1)}} disabled={currentPage === 1}>
                <KeyboardArrowLeft/>
              </IconButton>
              <IconButton  style={{ color: colors.colorPrimary0 }} onClick={() => {handleNextPage(1)}}
                          disabled={currentPage === pages}>
                <KeyboardArrowRight/>
              </IconButton>
            </div> : <div className={style.pagination}/>}
          </div>
        </Box>
      </TableCell>
    </TableRow>
  </TableHead>
}