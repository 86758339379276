import React, { useState } from "react";
import style from "./style.module.sass";
import { useTheme } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import arrow from "../../../Profile/ProfileWrapper/assets/arrow.svg";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import config from "../../../../config";
import { checkUserRight } from "../../../../utils";
import { useSelector } from "react-redux";
import ErrorPopup from "../../../Analysis/ModalWindows/ErrorModal";

function Dashboard() {
  const { t } = useTranslation();
  const user = useSelector(state => state.userData.user);
  const [error, setError] = useState(null);
  const { theme: { borderColors, colors } } = useTheme();

  // const settingPopUp = () => {
  //   setError({ text: t("be-patient"), header: t("be-patient") });
  // }

  return (
    <div className={style.mainCont}>
      <NavLink to="/administration" exact={true}>
        <ErrorPopup error={error} setError={setError} />
        <Button color="primary" variant="contained"
          className={`d-flex align-items-center ${style.breadcrumb}`}>
          <img src={arrow} className={`mr-1`} alt="" />
          {t("Administration")}
        </Button>
      </NavLink>
      <ul style={{ borderColor: borderColors.borderPrimary400 }}>
        {
          config.administration.map(l => {
            if (l?.rights?.length && !checkUserRight(user, l.rights))
              return null;
            return (
              <li key={l.id} className={``} style={{ borderColor: borderColors.borderPrimary400 }}>
                {
                  // l.title === "Terminology menu" ? 
                  l.content.map((el, i) => {
                    return (
                      <div key={i}>
                        <NavLink
                          className={style.link}
                          // activeClassName={style.activeLink}
                          style={{ color: colors.colorPrimary400 }}
                          // activeStyle={{color: colors.colorStatusInfo}}
                          to={el.link}
                        >
                          <p className={`m-0`}>
                            {/* {t(l.title)} */}
                            {t(el.text)}
                          </p>
                        </NavLink>
                      </div>)
                  })
                  // :
                  //   <a  
                  //   className={style.link}
                  //   // activeClassName={style.activeLink}
                  //   style={{color: colors.colorPrimary400}}
                  //   // activeStyle={{color: colors.colorStatusInfo}}
                  //   // onClick={settingPopUp} 
                  //   >
                  //      <p className={`m-0`}>{t(l.title)}</p>
                  //     </a>
                }
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default Dashboard;
