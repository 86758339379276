import React, { useState } from "react";
import style from "../style.module.sass"
import { useTheme } from "@material-ui/core/styles";
import TypeOfField from "../../../../../components/FormikFields/TypeOfField";
import { useTranslation } from "react-i18next";

function TextLabStatus({ formik, companyList, groupList }) {
  const { t } = useTranslation();
  const [bg, setBg] = useState(false)
  const { theme: { bgColors, colors } } = useTheme();
  const companyOptions = groupList
  // [
  //   // {id: 1, name: "A-Tek Digital Studio"},
  //   // {id: 2, name: "Mad Devs"},
  //   // {id: 3, name: "TextLab"},
  //   // {id: 4, name: "TimelySoft"},
  //   // {id: 5, name: "FininanSoft"},
  // ];
  const typeOfUser = [
    { id: 2, name: "Admin" },
    { id: 3, name: "User" },
    // {id: 3, name: "HR menager"},
    // {id: 4, name: "Finance menager"}
  ];

  const FirmaList = companyList;

  const fields = [
    // {id: "1", control: "switch", name: "enabled", label: t("Is user enabled?")},
    // {id: "3", control: "switch", name: "changePassword", label: t("change password?")},
    // {id: "2", control: "switch", name: "termsAndConditions", label: t("terms_and_conditions")},
    { id: "4", control: "select", name: "company", label: t("company"), options: FirmaList },
    { id: "5", control: "select", name: "userGroup", label: t("User group"), options: companyOptions },
    { id: "6", control: "select", name: "typeOfUser", label: t("Type of user"), options: typeOfUser },
  ];

  return (
    <div style={{ backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50 }} onMouseEnter={() => setBg(true)}
      onMouseLeave={() => setBg(false)}>
      <h4 style={{ color: colors.colorPrimary0, backgroundColor: bgColors.bgPrimary500 }}
        className={`${style.title} mb-3`}>
        {t('textlab-status')}
      </h4>
      <div className={`d-flex justify-content-between pr-4 pl-4 ml-2 mr-2 pt-3`}>
        <TypeOfField labelPlacementEnd={true} control={"switch"} formik={formik}
          name={'enabled'}
          label={t("Is user enabled?")} />
        <TypeOfField labelPlacementEnd={true} control={"switch"} formik={formik}
          name={'changePassword'}
          label={t("change password?")} />
        <TypeOfField labelPlacementEnd={true} control={"switch"} formik={formik}
          name={'termsAndConditions'}
          label={t("terms_and_conditions")} />
      </div>
      <ul className={`mb-0`}>
        {
          fields.map(
            f => (
              <li key={f.id}>
                <TypeOfField control={f.control} formik={formik} name={f.name} label={f.label} {...f} />
              </li>
            )
          )
        }
      </ul>
    </div>
  )
}

export default TextLabStatus;
