import React, {useState} from "react";
import style from "../style.module.sass"
import {useTheme} from "@material-ui/core/styles";
import {Button, Collapse} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useTranslation } from "react-i18next";

export default function Benchmarks() {
  const { t } = useTranslation();
  const [bg, setBg] = useState(false)
  const [collapse, setCollapse] = useState(true)
  const {theme: {bgColors, colors}} = useTheme();
  const rows = [
    {id: 0, title: t("Letters")},
    {id: 1, title: t("Websites")},
    {id: 2, title: t("Email correspondence")},
    {id: 3, title: t("Technical support")},
    {id: 4, title: t("Chatbot snippets")},
    {id: 5, title: t("Snippets")},
  ]
  return <>
    <div style={{backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50}}
         onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}
         className={`${style.benchmarks}`}>
      <div style={{backgroundColor: bgColors.bgPrimary500}} className={`${style.header} pr-4`}>
        <h4 style={{color: colors.colorPrimary0}}
            className={style.title}>{t('text-types-assigned-to-group')}</h4>
        <div>
          <Button variant={'contained'} color={"primary"} className={`${style.benchmarks_btn}`}>{t('edit-text-type')}</Button>
          <button className={`ml-2 p-0`} onClick={(e) => {
            e.preventDefault()
            setCollapse(!collapse)
          }}>
            {!collapse ? <AddIcon style={{fill: "white"}} fontSize="small"/> :
              <RemoveIcon style={{fill: "white"}} fontSize="small"/>}
          </button>
        </div>
      </div>
      <Collapse in={collapse}>
        <ul className={`mt-3 pb-4`}>
          {
            rows.map(
              f => (
                <li key={f.id} className={`d-flex align-items-center pt-0 pb-1`}>
                  <div style={{backgroundColor: bgColors.bgBlue1000}} className={`${style.circle} mr-3`}/>
                  <p className={`m-0`}>{f.title}</p>
                </li>
              )
            )
          }
        </ul>
      </Collapse>
    </div>
  </>
}
