import React from "react";
import style from "./style.module.sass";
import arrow from "../../../../Profile/ProfileWrapper/assets/arrow.svg";
import {NavLink} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import groupIcon from "../../../Info/assets/groupIcon.svg"
import {Button} from "@material-ui/core";
import saveIcon from "../../../Info/assets/saveIcon.svg";
import {useFormik} from 'formik';
import * as yup from 'yup';
import TextLabStatus from "./CorporateLanguage/CorporateLanguage";
import Features from "./Features/Features";
import UserRights from "./UserRights/UserRights";
import GroupProfile from "./GroupProfile/GroupProfile";
import Countings from "./Countings/Countings";
import Benchmarks from "./Benchmarks/Benchmarks";
import Languages from "./Languages/Languages";
import ColorWords from "./ColorWords";
import { useTranslation } from "react-i18next";

//state validation by YUP
const validationSchema = yup.object({
  groupName: yup
  .string('Enter your group name')
  .required('Group name is required'),
  maxNumberUsersLoggedSimultaneously: yup
  .string('Enter the maximum number of users logged in simultaneously')
  .required('Maximum number of users logged in simultaneously is required'),
  maximumUsers: yup
  .string('Enter the maximum users')
  .required('The maximum of users is required'),
  groupLanguages: yup
  .string('Enter the languages of group')
  .required('Languages of group is required'),
});

function Form() {
  const {theme: {bgColors, colors}} = useTheme()
  const { t } = useTranslation();
  
  const formik = useFormik({
    initialValues: {
      scheduleLicenseChange: true,
      groupName: "",
      maxNumberUsersLoggedSimultaneously: "",
      maximumUsers: "",
      expirationDate: null,
      userNum: "",
      informAdmins: false,
      //----- general -----/
      textModules: true,
      hyphenatedSpelling: true,
      activateCleaningRules: true,
      usersCanUseCleaningRules: true,
      saveTextAfterSessionExpired: true,
      setSessionLength: "",
      showSimilarMatches: true,
      activateCLIX: true,
      activateSpellCheck: "",
      addressing: true,
      bodyCopyRecognition: true,
      newsChannel: true,
      //-------statistics-------//
      showPdf: true,
      timelineDefaultHIX: true,
      //-------Editor--------//
      headerLine: true,
      footerLine: true,
      //-------Archive--------//
      showArchive: true,
      showGroupArchive: true,
      analysesNumberPerPage: "",
      //-------Countings------//
      includeHyphensInCountings: true,
      paragraphRecognition: true,
      dontAnalyseTablesContent: true,
      sentenceLineBreakClosing: '',
      sentenceClosingOnSemicolon: true,
      sentenceClosingOnColon: true,
      //-------UserRights--------//
      mustChangePassLogin: true,
      mayAdministrateTerms: true,
      viewAnalysisResults: true,
      mayAdminBasicVocabulary: true,
      mayAdminGeneralTerminology: true,
      mayEditBenchmark: true,
      mayAdminUserRights: true,
      mayViewLogs: true,
      mayCRUDBlockUsers: true,
      mayCRUDUserGroup: true,
      mayAdminControlList: true,
      mayUpdateAGroupRes: true,
      mayUpdateAGroupText: true,
      mayCRUDUsers: true,
      mayAdminWhiteList: true,
      mayAddListAndAssignUsers: true,
      mayAdjustTextTypesSettings: true,
      mayEditSentimentList: true,
      mayEditTerminologyList: true,
      headerLineFooterLine: true,
      mayEditBesPractice: true,
      //-------Corporate Language-------//
      uploadLogo: null,
      exclamationMarks: "Exclamation marks",
      exclamationMarksType: "Eng",

      bankDetails: 'Bank detailes',
      bankDetailsType: "Eng",

      hyphenationRule: 'Hyphenation rule',
      hyphenationRuleType: "Eng",

      companyName: 'Company name',
      companyNameType: "Eng",

      currencyEng: 'Currency (English)',
      currencyDeu: 'Currency (German)',
      currencyType: 'Eng',

      dateEng: 'Date (English)',
      dateDeu: 'Date (German)',
      dateType: 'Eng',

      telephoneNums: 'Telephone numbers',
      telephoneNumsType: "Eng",

      timeSpecificationsEng: 'Time specifications (English)',
      timeSpecificationsGer: 'Time specifications (German)',
      timeSpecificationsType: 'Eng',

      numbers: 'Numbers',
      numbersType: "Eng",

      bestPractice: 'Best practice',
      //------benchmark-------//
      letters: "",
      websites: "",
      emailCorrespondence: "",
      technicalReport: "",
      chatBotSnippets: "",

      //-------languages assigned to group------//
      germanHandler: true,
      german: "1",
      englishHandler: true,
      english: "1",
      russiaHandler: true,
      russia: "1",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <form className={`pb-5 ${style.mainCont}`} onSubmit={formik.handleSubmit}>
      <NavLink className={`${style.breadcrumb}`} to="/administration/groups" exact={true}>
        <Button color="primary" variant="contained">
          <img src={arrow} className={`mr-3`} alt=""/>
          {t('groups')}
        </Button>
      </NavLink>
      <div className={`mt-5`}>
        <div className={`d-flex align-items-center justify-content-between mb-3`}>
          <h3 style={{color: colors.colorPrimary1000}} className={`${style.main_title} d-flex align-items-center`}>
            <img style={{height: 50, width: 50}}
                  className={`mr-3`} src={groupIcon} alt=""/>
            {t('groups')}</h3>
          <Button type="submit" variant={"contained"} color={"primary"}
                  style={{backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0}}
                  className={`mr-2`}>
            <img src={saveIcon} className={`mr-2`} alt=""/> {t('save-changes')}
          </Button>
        </div>
        <div style={{backgroundColor: bgColors.bgPrimary500}}
             className={`pl-0 mb-4 pb-3 pr-0 col-12 ${style.borderTop}`}>
          <GroupProfile formik={formik}/>
          <Benchmarks/>
          <Languages formik={formik}/>
          <Features formik={formik}/>
          <Countings formik={formik}/>
          <UserRights formik={formik}/>
          <TextLabStatus formik={formik}/>
          <ColorWords formik={formik}/>
          {/*If you want to see state of form, decomment two lines*/}
          {/*<pre className="mt-4">{JSON.stringify(formik.values, null, 2)}</pre>*/}
          {/*<pre>{JSON.stringify(formik.errors, null, 2)}</pre>*/}
        </div>
        <div className={`${style.saveChangesBtn} mb-5`}>
          <Button type="submit" variant={"contained"} color={"primary"}
                  style={{backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0}}
                  className={`mr-2`}>
            <img src={saveIcon} className={`mr-2`} alt=""/> {t('save-changes')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default Form;
