import React, { useState, useEffect, useRef } from 'react'
import style from "./style.module.sass"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Rows from "./Rows"
import { useTheme } from '@material-ui/core/styles'
import EnhancedTableHead from "./TableHead"
import TableFilter from "./TableFilter"
import TableFooter from "./TableFooter"
import UserProfileWrapper from "../../UserProfileWrapper/UserProfileWrapper"
import AddNew from "./AddNew"
import TerminologyAddEditModal from "./TerminologyAddEditModal"
import { checkUserRight, convertFromErrorObject, isObjectEmpty } from '../../../../utils'
import { useSelector } from "react-redux"
import TLServiceComponent from "../../../../contexts/TLServiceComponent"
import { useTranslation } from "react-i18next"
import Spinner from "../../../Spinner"
import { TableCell, TableRow } from "@material-ui/core"
import {
  tableSetColumns
} from "../../../../actions"
import { useActions } from "../../../../utils/action-helper"

function createData(id, category_type, parent_id, description, name, itemCount, settings, rights, global_visible, locale_name, owner) {
  return { id, category_type, parent_id, description, name, itemCount, settings, rights, global_visible, locale_name, owner }
}

// const letterRows = [
//   createData(314132, 'exception', "12", "exception", "exception words", "132"),
//   createData(45232, 'abbreviate', "3", "abbreviate", "abbreviate words", "232"),
//   createData(133342, 'color', "234", "color", "blue", "342"),
//   createData(33442, 'color', "13", "color", "blue", "442"),
//   createData(13354, 'exception', "53", "exception", "exception words", "354"),
//   createData(93245, 'color', "15", "color", "green", "245"),
//   createData(46532, 'abbreviate', "3", "abbreviate", "abbreviate words", "532"),
//   createData(1376342, 'color', "234", "color", "red", "342"),
//   createData(33482, 'color', "13", "color", "blue", "482"),
//   createData(13394, 'exception', "53", "exception", "exception words", "394"),
//   createData(933424, 'color', "15", "color", "red", "424"),
//   createData(453652, 'abbreviate', "3", "abbreviate", "abbreviate words", "652"),
//   createData(133342, 'color', "234", "color", "blue", "342"),
//   createData(33472, 'color', "13", "color", "yellow", "472"),
//   createData(1334654, 'exception', "53", "exception", "exception words", "654"),
//   createData(9327874, 'color', "15", "color", "blue", "874"),
// ];

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array?.length && array.map((el, index) => [el, index]);
//   !!stabilizedThis && stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (!!stabilizedThis) {
//     return stabilizedThis.map((el) => el[0]);
//   }
// }

export default function TerminologyMainTable() {
  const [visibility, setVisibility] = useState(false)
  const [noData, setNoData] = useState(false)
  const { theme: { bgColors } } = useTheme()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [selected, setSelected] = useState([])
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddNewFields,] = useState(false)
  const [terminologyModal, setTerminologyModal] = useState(false)
  const user = useSelector(state => state.userData.user)
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector(state => state.userData.token)
  const [letterRows, setLetterRows] = useState([])
  const forward = useRef(0)
  const [allDataCount, setAllDataCount] = useState()
  const [limitIs, setLimitIs] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [spinner, setSpinner] = useState(false)
  const [pageData, setPageData] = useState()
  const [deleteRecall, setDeleteRecall] = useState()
  // const [textValue, setTextValue] = useState('')
  const [deleteCheckedTermModal, setDeleteCheckedTermModal] = useState(false)
  const [deleteAlertMsg, setDeleteAlertMsg] = useState(false)
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const languageIs = useRef()
  const [owners, setOwners] = useState()
  const [orderByValue, setOrderByValue] = useState('id')
  const [orderASCDESC, setOrderASCDESC] = useState(0)
  const [filter, setFilter] = useState(1)
  const handleFilter = e => setFilter(e.target.value)
  const [currentTerm, setCurrentTerm] = useState()
  const [mainBox, setMainBox] = useState([])
  const [color, setColor] = useState("")
  const [categoryType, setCategoryType] = useState('')
  const [groups, setGroups] = useState()
  const [groupIs, setGroupIs] = useState()
  const [company, setCompanys] = useState()
  const [companyIs, setCompanyIs] = useState()
  const [groupId, setGroupId] = useState()
  const [companyId, setCompanyId] = useState();
  const [disabledColumn, setDisabledColumn] = useState([]);

  let headCells = [
    { id: 'id', columnDisabled: true, numeric: false, disablePadding: true, label: 'id', orderBy_value: 'id' },
    // {id: 'parent_id', numeric: false, disablePadding: false, label: 'parent_id', orderBy_value: 'parent_id'},
    {id: 'category_type', columnDisabled: false, numeric: false, disablePadding: false, label: 'admin_texttypes_column-category_type', orderBy_value: 'category_type'},
    {id: 'language', columnDisabled: false, numeric: false, disablePadding: false, label: 'admin_texttypes_column-language', orderBy_value: 'locale_name'},
    {id: 'made_for', columnDisabled: false, numeric: false, disablePadding: false, label: 'admin_texttypes_column-made_for', orderBy_value: 'parent_id'},
    { id: 'name', columnDisabled: false, numeric: false, disablePadding: false, label: 'admin_texttypes_column-name', orderBy_value: 'name'},
    { id: 'itemCount', columnDisabled: false, numeric: false, disablePadding: false, label: 'admin_texttypes_column-count-of-items', orderBy_value: 'term_count'},
    // { id: 'edit', numeric: false, disablePadding: false, label: 'editMissing' },
  ];

  const userType = useSelector(state => state.userData.user.user_type_id)
  const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})
  const columns = useSelector(state => state.tableData.columns)

  const {
    atableSetColumns
  } = useActions({
    atableSetColumns : tableSetColumns
  })

  // Set Language accordingly when user lands on archive page:
  useEffect(() => {
    languageIs.current = Object.keys(backendLanguages).filter(lang =>
      backendLanguages[lang]['shortName'] === i18nLanguage
    )
  }, [i18nLanguage])

  const fetchData = async () => {
    let groups
    let company
    try {
      groups = await tlService.getFilteredGroups(token, {}, {})
    } catch (e) {
      console.log(e)
    }
    try {
      company = await tlService.getFilteredCompanies(token, {}, {})
    } catch (e) {
      console.log(e)
    }
    if (groups?.data) {
      setGroups(groups.data)
    }
    if (company?.data) {
      setCompanys(company.data)
    }
  }

  // Get Groups:
  useEffect(() => {
    fetchData()
    // Add the Default column headers:
    atableSetColumns(headCells)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get the list of term by group ID filter:
  useEffect(() => {
    if (groups?.length) {
      let group = groups.find(el => el.name === groupIs)
      if (!isObjectEmpty(group)) {
        callEndPoint("", group.id, companyId)
        setGroupId(group.id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupIs, limitIs])

  // Get the list of term by company ID filter:
  useEffect(() => {
    if (company?.length) {
      let company_IS = company.find(el => el.name === companyIs)
      if (!isObjectEmpty(company_IS)) {
        callEndPoint("", groupId, company_IS.id)
        setCompanyId(company_IS.id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyIs, limitIs])

  // Set page to 1 & offset to 0 and checked archive to unchecked when limit is changed or language is changed or archive is changed :
  useEffect(() => {
    setCurrentPage(1)
    forward.current = 0
    setSelected([])
  }, [limitIs])

  const pageSetter = async (val) => {
    setCurrentPage(val)
    forward.current = val * limitIs - 10
    await callEndPoint()
  }

  const handleSetDisabledColumns = (id) => {
    let newColumn = columns
    newColumn.map(el => {
      if(String(el.id) === String(id)){
        const currentColumn = el['columnDisabled']
        el['columnDisabled'] = !currentColumn
        if(!el['columnDisabled']){
          let newDisabledColumns = [...disabledColumn];
          const newArray = newDisabledColumns.filter((item, index) => item !== id);
          setDisabledColumn(newArray)
        }else{
          setDisabledColumn([...disabledColumn, id])
        }
      }
    })
    // Add new Column:
    atableSetColumns(newColumn)
  }

  const callEndPoint = async (val, groupId, companyId) => {
    setSpinner(true)
    switch (val) {
      case 0:
        forward.current = forward.current - limitIs
        if (selected.length !== 0) {
          setSelected([])
        }
        break
      case 1:
        forward.current = forward.current + limitIs
        if (selected.length !== 0) {
          setSelected([])
        }
        break
      default:
        break
    }
    let terms
    let filterIs
    if (val === 'delete') {
      forward.current = 0
      filterIs = { offset: forward.current, order_by: orderByValue, order: orderASCDESC }
    }
    // else if (textValue?.length) {
    //   forward.current = 0;
    //   filterIs = { offset: forward.current, name: `%${textValue}%`, order_by: orderByValue, order: orderASCDESC };
    // }
    else {
      filterIs = { offset: forward.current, order_by: orderByValue, order: orderASCDESC }
    }
    // Add group id if user add:
    if (!!groupId) {
      filterIs.user_group_id = groupId
    }
    // Add company id if user add:
    if (!!companyId) {
      filterIs.company_id = companyId
    }
    try {
      terms = await tlService.getFilteredTermCategories(token, { limit: limitIs }, filterIs)
      setSpinner(false)
      setNoData(false)
    } catch (e) {
      console.log('error', e)
      setSpinner(false)
      setPageData('')
      setNoData(true)
      switch (val) {
        case 0:
          forward.current = forward.current + limitIs
          break
        case 1:
          forward.current = forward.current - limitIs
          break
        default:
          break
      }
      setLetterRows('')
      // Setting Total page to 0 if no data found:
      setAllDataCount(0)
      // setting current input page no. to 1 and all data to empty as it will be 1 as well
      if (val !== 0 && val !== 1 && !!val) {
        setCurrentPage(1)
        // setAllData('');
      }
    }
    if (terms?.fullcount) {
      setAllDataCount(terms.fullcount)
    }
    if (terms?.data) {
      //  setUserIds
      let aryOfOwner = []
      // set of user id to check for user right:
      terms.data.map(el => {
        aryOfOwner.push(el.owner)
      })
      setOwners(aryOfOwner)
      setPageData(terms?.data)
    }
    if (terms?.data) {
      let arry = []
      terms?.data.map(el => {
        if (el !== undefined) {
          //  let createTime = el?.create_time?.replace(/[^\0-9\:]/g, '').slice(0, -1);
          arry.push(createData(el?.id, el?.category_type, el?.parent_id, el?.description, el?.name, el?.term_count, el?.settings, el?.rights, el?.global_visible, el?.locale_name, el?.owner))
        }
      })
      setLetterRows([...arry])
      let pageNumber = Math.ceil(allDataCount / limitIs)
      // When user delete, it should go to first page:
      if (val === 'delete') {
        setCurrentPage(1)
      }
      if (forward.current >= 0) {
        let currentPageIs
        if (currentPage < pageNumber) {
          if (val === 0) {
            currentPageIs = Math.floor(forward.current / limitIs - 1)
            if (currentPageIs <= 0) {
              setCurrentPage(1)
            } else {
              if (currentPageIs > 0) {
                setCurrentPage(currentPageIs)
              }
            }
          } else if (val === 1) {
            currentPageIs = Math.floor(forward.current / limitIs + 1)
            if (currentPageIs > 0) {
              setCurrentPage(currentPageIs)
            }
          }
        }
        // here currently when user page no. input and total page is name, this func run to decrease the page no. :
        if (val === 0) {
          // currentPageIs = Math.floor(forward.current / limitIs);
          currentPageIs = currentPage - 1
          if (currentPageIs > 0) {
            setCurrentPage(currentPageIs)
          }
        }
      }
      // to clean the rows for next page filter by id
      // setRows([]);
    }
  }

  // Consecutive call!
  useEffect(() => {
    callEndPoint()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecall, orderBy, orderASCDESC, limitIs])

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  //   setPage(0)
  //   setRowsPerPage(10)
  // };

  const handleRequestSort = (event, property, orderBy_value) => {
    // const isAsc = orderBy === property && order === 'asc';
    // setOrder(isAsc ? 'desc' : 'asc');
    // setPage(0)
    // setRowsPerPage(10)
    setOrderBy(property)
    setOrderByValue(orderBy_value)
    if (orderASCDESC === 1) {
      setOrder('asc')
      setOrderASCDESC(0)
    } else {
      setOrder('desc')
      setOrderASCDESC(1)
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = letterRows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const deleteChecked = async () => {
    const elementsId = letterRows?.length ? letterRows.map(
      element => selected.includes(element.id) ? element.id : null) : []
    setSpinner(true)
    if (filter === 1) {
      for (let element of elementsId) {
        try {
          if (!!element) {
            await tlService.deleteTermCategory(token, element)
            setDeleteCheckedTermModal(false)
          }
        } catch (e) {
          setDeleteAlertMsg(convertFromErrorObject(t, e))
          console.log('error', convertFromErrorObject(t, e))
          break
        }
      }
    } else if (filter === 2) {
      for (let element of letterRows) {
        try {
          if (!!element) {
            await tlService.deleteTermCategory(token, element.id)
            setDeleteCheckedTermModal(false)
          }
        } catch (e) {
          setDeleteAlertMsg(convertFromErrorObject(t, e))
          console.log('error', convertFromErrorObject(t, e))
          break
        }
      }
    }
    setSelected([])
    // try to reduce offset by limit amount if delete whole data on one page:
    if (selected?.length === letterRows?.length || filter === 2) {
      forward.current = forward.current - limitIs
      setCurrentPage(currentPage - 1)
      // if the offset is negative than make it 0:
      if (Math.sign(forward.current) === -1) {
        forward.current = 0
      }
    }
    callEndPoint()
  }

  // check for  user right
  if (!checkUserRight(user, [701, 702, 703]))
    return null

  return (
    <UserProfileWrapper>
      <div className={`${style.mainCont}`}>
        {spinner && <Spinner />}
        <Paper className={``}>
          <TerminologyAddEditModal
            visibility={visibility}
            setVisibility={setVisibility}
            color={color}
            setColor={setColor}
            mainBox={mainBox}
            setMainBox={setMainBox}
            callEndPoint={callEndPoint}
            currentTerm={currentTerm}
            open={terminologyModal}
            setOpen={setTerminologyModal}
            categoryType={categoryType}
            setCategoryType={setCategoryType}
            letterRows={letterRows}
          />
          <TableContainer>
            <Table
              style={{ backgroundColor: bgColors.bgPrimary100, borderColor: "transparent" }}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              {checkUserRight(user, [701, 702, 703]) &&
                <AddNew
                  visibility={visibility}
                  setVisibility={setVisibility}
                  color={color}
                  setColor={setColor}
                  categoryType={categoryType}
                  setCategoryType={setCategoryType}
                  mainBox={mainBox}
                  setMainBox={setMainBox}
                  setCurrentTerm={setCurrentTerm}
                  setTerminologyModal={setTerminologyModal}
                />
              }
              {userType === 1 && <TableFilter
                companyIs={companyIs}
                groupId={groupId}
                companyId={companyId}
                groups={groups}
                setGroupIs={setGroupIs}
                callEndPoint={callEndPoint}
                setCompanyIs={setCompanyIs}
                company={company}
                setCompanyId={setCompanyId}
                setGroupId={setGroupId}
              />}
             <EnhancedTableHead
                handleSetDisabledColumns={handleSetDisabledColumns}
                deleteAlertMsg={deleteAlertMsg}
                deleteCheckedTermModal={deleteCheckedTermModal}
                setDeleteCheckedTermModal={setDeleteCheckedTermModal}
                deleteChecked={deleteChecked}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                openAddNewFields={openAddNewFields}
                rowCount={letterRows.length}
              />
              <TableBody>
                {letterRows?.length ? letterRows.map((row, index) => {
                  /* {!!stableSort(letterRows, getComparator(order, orderBy)) &&
                 stableSort(letterRows, getComparator(order, orderBy))
                   // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                   .map((row, index) => { */
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <Rows
                      setCurrentTerm={setCurrentTerm}
                      setTerminologyModal={setTerminologyModal}
                      key={index}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      row={row}
                      isEven={index % 2 === 0}
                      setDeleteRecall={setDeleteRecall}
                      owners={owners}
                      callEndPoint={callEndPoint}
                    />
                  )
                })
                  :
                  (noData && <TableRow>
                    <TableCell colSpan="10" style={{ height: "400px", textAlign: "center", marginTop: "140px" }}>
                      <h2>   {t('no-data-term')} </h2>
                    </TableCell>
                  </TableRow>)
                }
              </TableBody>
              <TableFooter
                groupId={groupId}
                companyId={companyId}
                offsetIs={forward.current}
                handleFilter={handleFilter}
                allDataCount={allDataCount}
                pageSetter={pageSetter}
                currentPage={currentPage}
                pageData={pageData}
                callEndPointForward={callEndPoint}
                callEndPointBackward={callEndPoint}
                setLimitIs={setLimitIs}
                filter={filter}
                selected={selected} />
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </UserProfileWrapper>
  )
}
