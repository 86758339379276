import React, {useState} from 'react';
import style from "./style.module.sass";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from "@material-ui/core/Checkbox";
import {useTheme} from '@material-ui/core/styles';
import deleteIcon from "../assets/deleteIcon.svg";
import { Button, Collapse, Modal } from "@material-ui/core";
import TLServiceComponent from "../../../../../contexts/TLServiceComponent";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import closeIcon from "../../../../../assets/close-circle.svg";
import config from '../../../../../config'
import {checkUserRight, convertFromErrorObject} from '../../../../../utils'

function Rows({row, isItemSelected, labelId, handleClick, isEven, setDeleteRecall}) {
  const {theme: {bgColors, colors}} = useTheme();
  const tlService = React.useContext(TLServiceComponent);
  const token = useSelector(state => state.userData.token);
  const [groupModal, setGroupModal] = useState(false);
  const [deleteAlertMsg, setDeleteAlertMsg] = useState();
  const { t } = useTranslation();
  const user = useSelector(state => state.userData.user);

  const deleteGroup = async (e, id) => {
    e.preventDefault();
    try {
      await tlService.deleteGroup(token, id);
      setGroupModal(false);
      setDeleteRecall(id)
    } catch (e) {
      setDeleteAlertMsg(convertFromErrorObject(t,e));
      setTimeout(() => {
        setDeleteAlertMsg('');
      }, config.timeOutDuration)
    }
  }

  return (
    <React.Fragment>
      <Modal open={groupModal} onClose={() => setGroupModal(false)} style={{ width: '50%', margin: '0 auto', marginTop: '20%', height: '300px' }}>
              <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
                <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
                  style={{ backgroundColor: bgColors.bgPrimary400 }}>
                  <p className={`m-0 ${style.title}`} style={{ color: 'white', fontSize: '25px' }}>
                    {t('clear-group')}
                  </p>
                  <button className={`border-0 bg-transparent`} onClick={() => setGroupModal(false)}>
                    <img src={closeIcon} alt="" />
                  </button>
                </div>
                <br />
                <div className={style.error} >
                  <Collapse in={!!deleteAlertMsg} >
                    <Alert
                      className={`m-0`}
                      severity='error'>
                      <h6 className={`m-0`}>
                        {/* {t('delete-error-msg')} */}
                        {deleteAlertMsg}
                      </h6>
                    </Alert>
                  </Collapse>
                </div>

                <div>
                  <Button color='primary' variant='contained' onClick={(e) => deleteGroup(e, row.id)} style={{ marginLeft: '40%', marginTop: '4%' }}>
                    {t('yes')}
                  </Button>
                  <Button color='primary' variant='contained' onClick={() => { setGroupModal(false); }} style={{ marginLeft: '4%', marginTop: '4%' }}>
                    {t('no')}
                  </Button>
                </div>
              </div>
            </Modal>
      <TableRow
        style={{backgroundColor: isItemSelected ? bgColors.bgBlue300 : isEven ? bgColors.bgPrimary0 : bgColors.bgBlue100}}
        hover
        role="checkbox"
        className={`${style.row}`}
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{'aria-labelledby': labelId}}
          />
        </TableCell>
        <TableCell className={style.cell}
                   style={{maxWidth: "248px", color: colors.colorStatusInfo, cursor: "pointer"}} component="th"
                   id={labelId} scope="row" padding="none">
          {row.groupName}
        </TableCell>
        <TableCell className={style.cell} align="left">
          {row.companyName}
        </TableCell>
        <TableCell className={style.cell} align="left">
          {row.changeDate}
        </TableCell>
        <TableCell className={style.cell} align="left">
          {row.maxLicense}
        </TableCell>
            {(checkUserRight(user, [208])) &&
        <TableCell className={`${style.cell} d-flex`} align="right">
          <button className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                  style={{backgroundColor: bgColors.bgBlue1000}}
                  onClick={() => {
                    setGroupModal(true);}
                    }>
            <img src={deleteIcon} alt=""/>
          </button>
        </TableCell>
            }
      </TableRow>
    </React.Fragment>
  );
}

export default Rows;
