import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Select as SelectField } from '@material-ui/core';
import FormHelperText from "@material-ui/core/FormHelperText";
import { TextField } from "@material-ui/core";

function Select({ formik, name, label, options, value, disabled, setAutoFillState, withTextField, ...rest }) {
  return (
    <FormControl id="name-label" fullWidth variant="outlined" disabled={disabled} className={``}
      error={formik?.touched[name] && Boolean(formik?.errors[name])} {...rest}>
      <InputLabel id="name-label">{label}</InputLabel>
      <SelectField
        name={name && name}
        labelId="name-label"
        id="name"
        fullWidth
        value={value ? value : name && formik?.values[name]}
        onChange={formik?.handleChange}
        onClick={() => setAutoFillState && setAutoFillState(null)}
        labelWidth={label.length * 11}
      >
        {options.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            {o.name}
          </MenuItem>
        ))}
        {withTextField &&
          <MenuItem dense={true} className={`p-1`}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
            />
          </MenuItem>
        }
      </SelectField>
      <FormHelperText id="outlined-adornment-password">{formik?.touched[name] && formik?.errors[name]}</FormHelperText>
    </FormControl>
  )
}

export default Select;
