import React, {useState} from "react";
import style from "../style.module.sass"
import {useTheme} from "@material-ui/core/styles";
import TypeOfField from "../../../../../../components/FormikFields/TypeOfField";
import FileUpload from "../../../../../../components/FormikFields/FileUpload";
import {Button, Collapse} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useTranslation } from "react-i18next";

function CorporateLanguage({formik}) {
  const [bg, setBg] = useState(false)
  const [status, setStatus] = useState("none")
  const [collapse, setCollapse] = useState(true)
  const {theme: {bgColors, colors}} = useTheme();
  const { t } = useTranslation();

  const bankDetailsOptions = [
    {
      id: 1, name: <>
        <p className={`mb-1 m-0 ${style.select_options}`}>IBAN: DE12 3456 7890 1234 5678 90</p>
        <p className={`m-0 ${style.select_options}`}>BIC: ABCDEFGHXXX</p>
      </>,
      value: "IBAN: DE12 3456 7890 1234 5678 90"
    },
    {
      id: 2, name:
        <>
          <p className={`mb-1 m-0 ${style.select_options}`}>{t('bank-account-number')}: 123456789</p>
          <p className={`m-0 ${style.select_options}`}>{t('sort-code')}: 000 000 00</p>
        </>,
      value: "Kontonummer: 123456789"
    },
    {
      id: 3, name: <>
        <p className={`mb-1 m-0 ${style.select_options}`}>IBAN: DE12 3456 7890 1234 5678 90</p>
        <p className={`mb-1 m-0 ${style.select_options}`}>BIC: ABCDEFGHXXX</p>
        <p className={`mb-1 m-0 ${style.select_options}`}>{t('bank-account-number')}: 123456789</p>
        <p className={`m-0 ${style.select_options}`}>{t('sort-code')}: 000 000 00"</p>
      </>,
      value: "IBAN: DE12 3456 7890 1234 5678 90"
    }
  ]

  const currencyEngOptions = [
    {
      id: 1, name: "EUR 5.00 USD 1,512.31",
    },
    {
      id: 2, name: "EUR 5 USD 1,512.31",
    },
    {
      id: 3, name: "€ 5.00 $ 1,512.31",
    },
    {
      id: 4, name: "€ 5 $ 1,512.31",
    }
  ]

  const currencyDeuOptions = [
    {
      id: 1, name: "10 €   2.500,90 €",
    },
    {
      id: 2, name: "10,00 €   2.500,90 €",
    },
    {
      id: 3, name: "10 EUR   2.500,90 EUR",
    },
    {
      id: 4, name: "10,00 EUR   2.500,90 EUR",
    },
    {
      id: 5, name: "10 Euro   2.500,90 Euro",
    },
    {
      id: 6, name: "10,00 Euro   2.500,90 Euro",
    }
  ]

  const dateOptions = [
    {id: 1, name: "01-06-2021"}
  ]

  const telOptions = [
    {id: 1, name: "+49 123 4567-890"},
    {id: 2, name: "+49 12 34 56 78-90"},
    {id: 3, name: "+49 (0)123 4567-890"},
    {id: 4, name: "0123 456-78"},
    {id: 5, name: "0123/456-78"},
  ]
  const numberOptions = [
    {id: 1, name: "123 456"},
    {id: 2, name: "123,456"},
    {id: 3, name: "123.456"},
  ]

  const timeEngOptions = [
    {id: 1, name: "8 pm"},
  ]

  const timeDeuOptions = [
    {id: 1, name: "8 pm"},
    {id: 2, name: "8:30 Uhr"},
    {id: 3, name: "8.30 Uhr"},
    {id: 4, name: "8 bis 20 Uhr"},
  ]

  const fields = [
    {
      id: "0",
      name: "exclamationMarks",
      freesolo: true,
      label: t("Exclamation marks"),
      handlerName: "exclamationMarksType"
    },
    {
      id: "1",
      name: "bankDetails",
      freesolo: true,
      label: t("Bank Details"),
      options: bankDetailsOptions,
      handlerName: "bankDetailsType"
    },
    {
      id: "2",
      name: "hyphenationRule",
      freesolo: true,
      label: t("Hyphenation rule"),
      handlerName: "hyphenationRuleType"
    },
    {id: "4", control: "text", name: "companyName", label: "Company name", handlerName: "companyNameType"},
    {
      id: "5",
      name: "telephoneNums",
      freesolo: true,
      label: t("Telephone numbers"),
      options: telOptions,
      handlerName: "telephoneNumsType"
    },
    {
      id: "6",
      name: formik.values.currencyType === "Eng" || formik.values.currencyType === "Rus" ? "currencyEng" : "currencyDeu",
      freesolo: true,
      label: formik.values.currencyType === "Eng" || formik.values.currencyType === "Rus" ? "Currency (English)" : "Currency (German)",
      options: formik.values.currencyType === "Eng" || formik.values.currencyType === "Rus" ? currencyEngOptions : currencyDeuOptions,
      handlerName: "currencyType"
    },
    {
      id: "8",
      name: formik.values.dateType === "Eng" || formik.values.dateType === "Rus" ? "dateEng" : "dateDeu",
      freesolo: true,
      label: formik.values.dateType === "Eng" || formik.values.dateType === "Rus" ? "Date (English)" : "Date (German)",
      options: formik.values.dateType === "Eng" || formik.values.dateType === "Rus" ? dateOptions : dateOptions,
      withDate: true,
      handlerName: "dateType"
    },
    {
      id: "9",
      name: formik.values.timeSpecificationsType === "Eng" || formik.values.timeSpecificationsType === "Rus" ? "timeSpecificationsEng" : "timeSpecificationsGer",
      label: formik.values.timeSpecificationsType === "Eng" || formik.values.timeSpecificationsType === "Rus" ? "HH:MM pm/am" : "Time specifications (German)",
      freesolo: true,
      options: formik.values.timeSpecificationsType === "Eng" || formik.values.timeSpecificationsType === "Rus" ? timeEngOptions : timeDeuOptions,
      handlerName: "timeSpecificationsType"
    },
    {
      id: "11", name: "numbers", freesolo: true, label: t("numbers"), options: numberOptions,
      handlerName: "numbersType"
    },
  ];
  return (
    <div style={{backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50}}
         onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}>
      <div className={`d-flex justify-content-between align-items-center pr-4`}
           style={{color: colors.colorPrimary0, backgroundColor: bgColors.bgPrimary500}}>
        <h4 className={style.title}>{t('Corporate Language')}</h4>
        <div>
          <Button onMouseEnter={() => setStatus(status !== "group" ? "hover" : "group")}
                  onMouseLeave={() => setStatus(status !== "group" ? "none" : "group")}
                  onClick={() => setStatus("group")}
                  variant={'contained'} color={status === "hover" || status === "group" ? "primary" : "default"}
                  className={`${style.benchmarks_btn} mr-1`}>{t('Company Default')}</Button>
          <button className={`ml-2 p-0`} onClick={(e) => {
            e.preventDefault()
            setCollapse(!collapse)
          }}>
            {!collapse ? <AddIcon style={{fill: "white"}} fontSize="small"/> :
              <RemoveIcon style={{fill: "white"}} fontSize="small"/>}
          </button>
        </div>
      </div>
      <Collapse in={collapse}>
        <div style={{paddingLeft: "27px"}} className={`mt-5 mb-5`}>
          <FileUpload formik={formik} label={t('Change logo')} name={'uploadLogo'}/>
        </div>
        <ul className={`mb-0 ${style.corporateLanguageUl}`}>
          {
            fields.map(
              f => (
                <li key={f.id} className={`position-relative d-flex flex-column mb-4 mt-2`}>
                  <div className={`position-absolute d-flex align-items-center ${style.langSwitch}`}>
                    {formik.values[f.handlerName] !== "Rus" &&
                    <Button color="primary"
                            onClick={() => f.handlerName && formik.setFieldValue(f.handlerName, "Rus")}>Rus</Button>}
                    {formik.values[f.handlerName] !== "Eng" &&
                    <Button color="primary"
                            onClick={() => f.handlerName && formik.setFieldValue(f.handlerName, "Eng")}>Eng</Button>}
                    {formik.values[f.handlerName] !== "Deu" &&
                    <Button color="primary"
                            onClick={() => f.handlerName && formik.setFieldValue(f.handlerName, "Deu")}>Deu</Button>}
                  </div>
                  <div style={{width: "100%"}} className={`${style.fields} mb-2`}>
                    <TypeOfField control={"text"} formik={formik} name={f.name} label=''/>
                    {status === "group" || status === "hover" ?
                      <TypeOfField name={f.name} status={status === "group" || status === "hover" ? "group" : null}
                                   setAutoFillState={setStatus} control={'select-with-text-field'} withTextField={true}
                                   formik={formik} label={t(f.label)}
                                   options={
                                     f.options ?
                                       f.options :
                                       [{id: 1, name: "1"}, {id: 2, name: "2"}, {id: 3, name: "3",}]
                                   }
                                   withDate={f.withDate && f.withDate}/> :
                      <TypeOfField name={f.name} control={'select-with-text-field'} withTextField={true} formik={formik}
                                   label={t(f.label)}
                                   options={
                                     f.options ?
                                       f.options :
                                       [{id: 1, name: "1"}, {id: 2, name: "2"}, {id: 3, name: "3",}]
                                   }
                                   withDate={f.withDate && f.withDate}/>
                    }
                  </div>
                  <TypeOfField control={"textarea"} label={t("description")}/>
                </li>
              )
            )
          }
        </ul>
      </Collapse>
    </div>
  )
}

export default CorporateLanguage;
