import React, { useEffect } from 'react'
import style from './style.module.sass'
import DashFilterHeader from './FilterHeader'
import { Check } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import ArchivesSelectionTable from './ArchivesSelectionTable'
import { useActions } from '../../../utils/action-helper'
import { dashboardGetArchivesByFilter, dashboardSetIdList } from '../../../actions'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import arrow from '../../../assets/arrow.svg'
import { useTheme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

export default function FilterPanel ({ panel }) {
  const { t } = useTranslation()
  const { theme: { bgColors } } = useTheme()
  const tlService = React.useContext(TLServiceComponent)

  const { aDashboardGetArchivesByFilter, aDashboardSetIdList } = useActions({
    aDashboardGetArchivesByFilter: dashboardGetArchivesByFilter(tlService),
    aDashboardSetIdList: dashboardSetIdList
  })

  const token = useSelector(state => state.userData.token)
  const limit = useSelector(state => state.dashboardData.limit[panel])
  const sort = useSelector(state => state.dashboardData.sort[panel])
  const offset = useSelector(state => state.dashboardData.offset[panel])
  const folderSelected = useSelector(state => state.dashboardData.folderSelected[panel])
  const idList = useSelector(state => state.dashboardData.idList[panel])
  const activeTab = useSelector(state => state.dashboardData.folderType[panel])
  const userId = useSelector(state => state.userData.user.id)
  const userGroup = useSelector(state => state.userData.user.user_group_id)
  const userCompany = useSelector(state => state.userData.user.company_id)
  const language = useSelector(state => state.dashboardData.language)

  const runRequest = (folderSelected, userId, userGroup, userCompany, token, panel, sort, language, limit, offset) => {
    let extendedFilter = [['&folder_id', `i=${JSON.stringify(folderSelected)}`]]
    if (folderSelected?.length) {
      if (folderSelected.indexOf(0) > -1) {
        extendedFilter = [['|folder_id', `i=${JSON.stringify(folderSelected)}`], ['|folder_id', '=']]
      }
    }
    let folderFilter
    if (activeTab === 'personal')
      folderFilter = { user_id: userId }
    else if (activeTab === 'group')
      folderFilter = { user_group_id: userGroup }
    else if (activeTab === 'company')
      folderFilter = { company_id: userCompany }

    if (folderSelected?.length) {
      aDashboardGetArchivesByFilter(token, panel, {
          ...folderFilter,
          order_by: sort,
          locale_name: language,
          order: 0,
          extended_filter: JSON.stringify(extendedFilter)
        },
        { limit, offset })
    } else {
      aDashboardGetArchivesByFilter(token, panel, {
          order_by: sort,
          locale_name: language,
          order: 0,
          ...folderFilter
        },
        { limit, offset })
    }
  }

  const onFindClick = () => {
    runRequest(folderSelected, userId, userGroup, userCompany, token, panel, sort, language, limit, offset)
  }

  // Call api when user change language:
  useEffect(() => {
    onFindClick()
  }, [language])

  const onClear = () => {
    aDashboardSetIdList(panel, [])
  }

  const inscriptionColor = panel === 0 ? '#415c98' : '#efa90b'
  return (
    <div className={`${style.contWrapper}`} style={{ overflow: 'auto' }}>
      <div className={`mt-1 mb-3 d-flex align-items-center`}>
        <p style={{ color: inscriptionColor }}
           className="m-0 font-weight-bold">{t('Auswahl {{panel}}', { panel: panel + 1 })}</p>
      </div>
      <DashFilterHeader panel={panel}/>
      <div className="p-2 text-right"
           style={{ backgroundColor: 'rgba(88, 88, 88, 1)', border: '0 0 6px 6px' }}>
        {idList?.length ? <Button
          onClick={onClear}
          className={'mr-2'}
          style={{ backgroundColor: bgColors.bgProgress40, color: 'white' }} variant="contained">
          <img src={arrow} className={`mr-2`} alt=""/>
          {t('clear ana')}
        </Button> : <div/>}
        {idList?.length ? <NavLink className={'p-0 mr-2'} to="/dashboard/compare">
          <Button style={{ backgroundColor: 'green', color: 'white' }} variant="contained">
            <img src={arrow} className={`mr-2`} alt=""/>
            {t('show results')}
          </Button>
        </NavLink> : <div/>}
        <Button color="primary" variant="contained" onClick={onFindClick}>
          <Check className={`mr-1`}/>
          {t('find')}
        </Button>
      </div>
      <div className={style.fileSelector}>
        <ArchivesSelectionTable runRequest={runRequest} panel={panel}/>
      </div>
    </div>
  )
}
