import { getFromLanguageTool } from '../components/Analysis/analysisFunctions'

export const analyseSetLanguageToolResult =
  (tlService) => (token, language, text, resultData, benchmarkSettings) => (dispatch) => {
    dispatch({
      type: 'FETCH_LANGUAGE_TOOL_REQUEST',
    })
    getFromLanguageTool(tlService, token, language, text, resultData, benchmarkSettings)
      .then((data) =>
        dispatch({
          type: 'FETCH_LANGUAGE_TOOL_SUCCESS',
          payload: data,
        })
      )
      .catch(() =>
        dispatch({
          type: 'FETCH_LANGUAGE_TOOL_FAILURE',
        })
      )
  }

export const analyseSetPopoverSuggestion =
  (tlService) => (token, language, text, id, benchmark_id) => (dispatch) => {
    dispatch({
      type: 'FETCH_POPOVER_SUGGESTION_REQUEST',
    })
    tlService
      .getSuggestion(token, language, JSON.stringify([text]), benchmark_id)
      .then((data) =>
        dispatch({
          type: 'FETCH_POPOVER_SUGGESTION_SUCCESS',
          payload: {
            suggestion: data?.suggestions[text]?.length
              ? data.suggestions[text]
              : [],
            id,
          },
        })
      )
      .catch(() =>
        dispatch({
          type: 'FETCH_POPOVER_SUGGESTION_FAILURE',
        })
      )
  }

export const analyseSetNNSuggestion =
  (tlService) => (token, text, request, num_return) => (dispatch) => {
    dispatch({
      type: 'FETCH_NN_SUGGESTION_REQUEST',
      payload: { text }
    })
    tlService.netPost(token, text, request, num_return)
      .then((data) =>
        dispatch({
          type: 'FETCH_NN_SUGGESTION_SUCCESS',
          payload: {
            suggestion: data || {},
            text
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: 'FETCH_NN_SUGGESTION_FAILURE',
          payload: { err, text }
        })
      )
  }

export const analyseUpdateNNSuggestion = (mainText, index, text) => {
  return {
    type: 'ANALYSE_UPDATE_NN_SUGGESTION',
    payload: { mainText, index, text },
  }
}
export const analyseSetSaveArchive = (value) => {
  return {
    type: 'ANALYSE_SET_SAVE_ARCHIVE',
    payload: value,
  }
}

export const analyseClearSaveArchive = () => {
  return {
    type: 'ANALYSE_CLEAR_SAVE_ARCHIVE',
  }
}

export const analyseSetAiMode = (aiMode) => {
  return {
    type: 'ANALYSE_SET_AI_MODE',
    payload: !!aiMode,
  }
}

export const analyseSetAutoTextLanguage = (data) => {
  return {
    type: 'ANALYSE_SET_AUTO_TEXT_LANGUAGE',
    payload: data,
  }
}

export const analyseSetTermCatTextTone = (data) => {
  return {
    type: 'ANALYSE_SET_TERMCAT_TEXTTONE',
    payload: data,
  }
}

export const analyseSetBodyBorderShow = (data) => {
  return {
    type: 'ANALYSE_SET_BODY_BORDER_SHOW',
    payload: data,
  }
}

export const analyseSetLtOffset = (data) => {
  return {
    type: 'ANALYSE_SET_LT_OFFSET',
    payload: data,
  }
}

export const analyseSetTextLanguage = (data) => {
  return {
    type: 'ANALYSE_SET_TEXT_LANGUAGE',
    payload: data,
  }
}

export const analyseSetIgnoreList = (data) => {
  return {
    type: 'ANALYSE_SET_IGNORE_LIST',
    payload: data,
  }
}

export const analyseClearIgnoreList = () => {
  return {
    type: 'ANALYSE_CLEAR_IGNORE_LIST',
  }
}

export const analyseSetUseBenchmark = (data) => {
  return {
    type: 'ANALYSE_SET_USE_BENCHMARK',
    payload: data,
  }
}
export const analyseSetEnabledBenchmark = (data) => {
  return {
    type: 'ANALYSE_SET_ENABLED_BENCHMARK',
    payload: data,
  }
}

export const analyseSetTopAccordion = (data) => {
  return {
    type: 'ANALYSE_SET_TOP_ACCORDION',
    payload: data,
  }
}
export const analyseSetModalAccordion = (data) => {
  return {
    type: 'ANALYSE_SET_MODAL_ACCORDION',
    payload: data,
  }
}

export const analyseSetLocalResult = (localAnalyseResultData) => {
  return {
    type: 'ANALYSE_SET_LOCAL_RESULT',
    payload: { localAnalyseResultData },
  }
}

export const analyseSetAiLocalResult = (aiLocalAnalyseResultData) => {
  return {
    type: 'ANALYSE_SET_AI_LOCAL_RESULT',
    payload: { aiLocalAnalyseResultData },
  }
}

export const analyseSetResult = (resultData, resultTemplate, benchmark) => {
  return {
    type: 'ANALYSE_SET_RESULT',
    payload: { resultData, resultTemplate, benchmark },
  }
}

export const analyseClearResult = () => {
  return {
    type: 'ANALYSE_CLEAR_RESULT',
  }
}

export const analyseClearAll = () => {
  return {
    type: 'ANALYSE_CLEAR_ALL',
  }
}

export const analyseClearPopovers = () => {
  return {
    type: 'ANALYSE_CLEAR_POPOVERS',
  }
}

export const analyseClearFilter = () => {
  return {
    type: 'ANALYSE_CLEAR_FILTER',
  }
}

export const analyseSetFilter = (filter) => {
  return {
    type: 'ANALYSE_SET_FILTER',
    payload: filter,
  }
}

export const analyseSetPopups = (popups) => {
  return {
    type: 'ANALYSE_SET_POPUPS',
    payload: popups,
  }
}

export const analyseSetCurrentPopup = (currentPopup) => {
  return {
    type: 'ANALYSE_SET_CURRENT_POPUP',
    payload: currentPopup,
  }
}

export const analyseSetText = (text) => {
  return {
    type: 'ANALYSE_SET_TEXT',
    payload: text,
  }
}

export const analyseSetOldText = (oldText) => {
  return {
    type: 'ANALYSE_SET_OLD_TEXT',
    payload: oldText,
  }
}

export const allowlistText = (data) => {
  return {
    type: 'ANALYSE_SET_ALLOWLISTTEXT',
    payload: data,
  }
}

export const popUpData = (data) => {
  return {
    type: 'ANALYSE_SET_POPUPDATA',
    payload: data,
  }
}

export const analyseSetProgressBar = (data) => {
  return {
    type: 'ANALYSE_SET_PROGRESS_BAR',
    payload: data,
  }
}

export const analyseSetFileName = (data) => {
  return {
    type: 'ANALYSE_SET_FILE_NAME',
    payload: data,
  }
}

