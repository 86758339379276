import { convertFoldersToArray } from '../utils'

const prepareArchiveVersions = (arr) => {
  const retArr = []
  for (let val of arr) {
    const index = retArr.findIndex(v => v.analyse_id === val.analyse_id)
    if (index === -1) {
      retArr.push({
        create_time: val.create_time,
        id: val.id,
        analyse_id: val.analyse_id,
        archive_name: val.archive_name,
        versions: [{ id: val.id, version: val.version, create_time: val.create_time }]
      })
    } else {
      retArr[index].versions.push({ id: val.id, version: val.version, create_time: val.create_time })
      retArr[index].versions.sort((a, b) => Date.parse(b.create_time) - Date.parse(a.create_time))
      retArr[index].id = retArr[index].versions[0].id
    }
  }
  return retArr
}

export const dashboardGetArchivesByFilter = (tlService) => (token, id, filter, limits) => (dispatch) => {
  dispatch({
    type: 'DASHBOARD_SET_LOADING',
  })
  filter = {
    ...filter,
    return_values: JSON.stringify(['id', 'analyse_id', 'version', 'archive_name', 'benchmark_settings_hash', 'create_time']),
    exact_result: 2,
    by_last: 0
  }
  tlService.getFilteredArchive(token, limits, filter)
    .then((data) => dispatch({
      type: 'FETCH_ARCHIVE_LIST_SUCCESS',
      payload: data.data !== undefined && data.data.length > 0 ? {
        id,
        fullcount: data['archives_fullcount'],
        data: prepareArchiveVersions(data.data)
      } : { fullcount: 0, id, data: [] }
    }))
    .catch(() => dispatch({
      type: 'FETCH_ARCHIVE_LIST_ERROR',
      payload: id
    }))
}

export const dashboardGetFoldersByFilter = (tlService) => (token, id, filter) => (dispatch) => {
  dispatch({
    type: 'DASHBOARD_SET_LOADING',
  })
  tlService.getFilteredArchiveFolders(token, {}, filter)
    .then((data) => dispatch({
      type: 'FETCH_FOLDER_LIST_SUCCESS',
      payload: data.data !== undefined && data.data.length > 0 ? { data: convertFoldersToArray(data.data), id } : {
        id,
        data: []
      }
    }))
    .catch(() => dispatch({
      type: 'FETCH_FOLDER_LIST_ERROR',
      payload: id
    }))
}

export const dashboardSetFolderType = (id, data) => {
  return {
    type: 'DASHBOARD_SET_FOLDER_TYPE',
    payload: { id, data },
  }
}

export const dashboardSetOpenedFolders = (id, data) => {
  return {
    type: 'DASHBOARD_SET_OPENED_FOLDERS',
    payload: { id, data },
  }
}

export const dashboardSetArchiveList = (id, data) => {
  return {
    type: 'DASHBOARD_SET_ARCHIVE_LIST',
    payload: { id, data },
  }
}

export const dashboardSetSort = (id, data) => {
  return {
    type: 'DASHBOARD_SET_SORT',
    payload: { id, data },
  }
}
export const dashboardSetOffset = (id, data) => {
  return {
    type: 'DASHBOARD_SET_OFFSET',
    payload: { id, data },
  }
}
export const dashboardSetLimit = (id, data) => {
  return {
    type: 'DASHBOARD_SET_LIMIT',
    payload: { id, data },
  }
}

export const dashboardSetFolderSelected = (id, data) => {
  return {
    type: 'DASHBOARD_SET_FOLDER_SELECTED',
    payload: { id, data },
  }
}
export const dashboardSetFolderList = (id, data) => {
  return {
    type: 'DASHBOARD_SET_FOLDER_LIST',
    payload: { id, data },
  }
}

export const dashboardSetResultList = (id, data) => {
  return {
    type: 'DASHBOARD_SET_RESULT_LIST',
    payload: { id, data },
  }
}

export const dashboardSetIdList = (id, data) => {
  return {
    type: 'DASHBOARD_SET_ID_LIST',
    payload: { id, data },
  }
}

export const dashboardSetOldIdList = (id, data) => {
  return {
    type: 'DASHBOARD_SET_OLD_ID_LIST',
    payload: { id, data },
  }
}

export const dashboardSetFilter = (id, data) => {
  return {
    type: 'DASHBOARD_SET_FILTER',
    payload: { id, data },
  }
}

export const dashboardInitOne = (id, filter, idList, type, language, folderType) => {
  return {
    type: 'DASHBOARD_INIT_ONE',
    payload: { id, data: { id, filter, idList, type, language, folderType } },
  }
}

export const dashboardSetType = (data) => {
  return {
    type: 'DASHBOARD_SET_TYPE',
    payload: data,
  }
}

export const dashboardSetLanguage = (data) => {
  return {
    type: 'DASHBOARD_SET_LANGUAGE',
    payload: data,
  }
}

export const dashboardClearAll = (id) => {
  return {
    type: 'DASHBOARD_CLEAR_ALL',
    payload: { id },
  }
}

export const dashboardSetArchiveResult = (data) => {
  return {
    type: 'DASHBOARD_SET_ARCHIVE_RESULT',
    payload: data,
  }
}

export const dashboardSettermWordCount = (data) => {
  return {
    type: 'DASHBOARD_SET_TERM_WORD_COUNT',
    payload: data,
  }
}