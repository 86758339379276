import React, { useState } from 'react';
import style from "./style.module.sass";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from "@material-ui/core/Checkbox";
import { useTheme } from '@material-ui/core/styles';
import deleteIcon from "../assets/deleteIcon.svg";
import { NavLink } from "react-router-dom";
import { Menu, Settings } from "@material-ui/icons";
import { checkUserRight, convertLanguagesToShort } from '../../../../utils'
import { useSelector } from "react-redux";
import { Button, Collapse, Modal } from "@material-ui/core";
import TLServiceComponent from "../../../../contexts/TLServiceComponent";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import closeIcon from "../../../../assets/close-circle.svg";
import config from '../../../../config'
import { convertFromErrorObject } from "../../../../utils";
import siteLanguages from "../../../../config/sitelanguages.json";

// const assignedToItems = [
//   { id: 'assigned1', name: "user" },
//   { id: 'assigned2', name: "company" },
//   { id: 'assigned3', name: "group" }
// ];

// const descItems = [
//   { id: 'desc1', name: "beschreibung" },
//   { id: 'desc2', name: "description" },
//   { id: 'desc3', name: "описание" }
// ];

function Rows({ row, setCurrentTerm, callEndPoint, isItemSelected, labelId, handleClick, isEven, setTerminologyModal, setDeleteRecall, owners }) {
  const { theme: { bgColors, colors } } = useTheme();
  // const [assignedTo, setAssignedTo] = useState('assigned1');
  // const [desc, setDesc] = useState('desc1');
  // const [open, setOpen] = useState(false);
  const user = useSelector(state => state.userData.user);
  // const userType = useSelector(state => state.userData.user.user_type_id);
  // const handleSelectAssign = event => setAssignedTo(event.target.value);
  // const handleSelectDesc = event => setDesc(event.target.value);
  const { t, i18n: { language: i18nLanguage } } = useTranslation();
  const tlService = React.useContext(TLServiceComponent);
  const token = useSelector(state => state.userData.token);
  const [termModal, setTermModal] = useState(false);
  const [deleteAlertMsg, setDeleteAlertMsg] = useState();

  /******** Redux *********/
  // const userArchives = useSelector(state => state.userData.user.archives);
  const userType = useSelector(state => state.userData.user.user_type_id);
  const columns = useSelector(state => state.tableData.columns)

  const deleteTerm = async (e, id) => {
    e.preventDefault();
    try {
      await tlService.deleteTermCategory(token, id);
      setTermModal(false);
      setDeleteRecall(id)
    } catch (e) {
      setDeleteAlertMsg(convertFromErrorObject(t, e));
      setTimeout(() => {
        setDeleteAlertMsg('');
      }, config.timeOutDuration)
    }
  }

  return (
    <React.Fragment>
      <Modal open={termModal} onClose={() => setTermModal(false)} style={{ width: '50%', margin: '0 auto', marginTop: '20%', height: '300px' }}>
        <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
          <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
            style={{ backgroundColor: bgColors.bgPrimary400 }}>
            <p className={`m-0 ${style.title}`} style={{ color: 'white', fontSize: '25px' }}>
              {t('clear-terms')}
            </p>
            <button className={`border-0 bg-transparent`} onClick={() => setTermModal(false)}>
              <img src={closeIcon} alt="" />
            </button>
          </div>
          <br />
          <div className={style.error} >
            <Collapse in={!!deleteAlertMsg} >
              <Alert
                className={`m-0`}
                severity='error'>
                <h6 className={`m-0`}>
                  {/* {t('delete-error-msg')} */}
                  {deleteAlertMsg}
                </h6>
              </Alert>
            </Collapse>
          </div>
          <div>
            <Button color='primary' variant='contained' onClick={(e) => deleteTerm(e, row.id)} style={{ marginLeft: '40%', marginTop: '4%' }}>
              {t('yes')}
            </Button>
            <Button color='primary' variant='contained' onClick={() => { setTermModal(false); }} style={{ marginLeft: '4%', marginTop: '4%' }}>
              {t('no')}
            </Button>
          </div>
        </div>
      </Modal>
      <TableRow
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isEven ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}
        hover
        role="checkbox"
        className={`${style.row}`}
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell className={style.cell} padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
       {columns.map(el => (el.id === "id" && !el.columnDisabled) &&  <TableCell className={style.cell}
          style={{ width: 120, cursor: "pointer" }}
          id={labelId} scope="row" padding="none">
          {row.id}
        </TableCell>)}
        {/* <TableCell className={style.cell} align="left"> */}
        {/* {row.parent_id} */}
        {/* {userType === 1 ? row.parent_id : '' } */}
        {/* </TableCell> */}
        {columns.map(el => (el.id === "category_type" && !el.columnDisabled) &&  <TableCell className={style.cell} align="left">
          {!!row.category_type ? t(row.category_type) : ""}
        </TableCell>)}
        {columns.map(el => (el.id === "language" && !el.columnDisabled) && <TableCell className={style.cell} align="left">
          {!!row.locale_name ? (siteLanguages[convertLanguagesToShort(row.locale_name)].payloadName !== undefined
           ? t(siteLanguages[convertLanguagesToShort(row.locale_name)].name) :  "") :  ""}
        </TableCell>)}
        {columns.map(el => (el.id === "made_for" && !el.columnDisabled) && <TableCell className={style.cell} align="left">
          {row.parent_id === 0 ? t("admin_term-cats_column-our_company") :  t("admin_term-cats_column-all")}
        </TableCell>)}
        {columns.map(el => (el.id === "name" && !el.columnDisabled) &&  <TableCell className={style.cell} align="left" style={{ color: colors.colorStatusInfo }}>
          <NavLink to={`/administration/terminology/update/${row.id}`} style={{ textDecoration: "none" }}>
            {!!row.name[i18nLanguage] ? [row.name[i18nLanguage]] : ""}
          </NavLink>
        </TableCell>)}
        {columns.map(el => (el.id === "itemCount" && !el.columnDisabled) &&  <TableCell className={style.cell} align="right">
          <div className={`d-flex align-items-center`}>
            <span style={{ marginRight: '40px' }}>
              <NavLink to={`/administration/terminology/update/${row.id}`}>
                <Button className={`ml-1 p-0`} style={{ minWidth: 35, height: 35 }} size='small' variant='contained'
                  color="primary">
                  <Menu />
                </Button>
              </NavLink>
              {row.itemCount}
            </span>
            </div>
            </TableCell>)}
        <TableCell className={`${style.cell}`} align="right">
            {/* {checkUserRight(user, [701, 702, 703]) && */}
          <div className={`d-flex align-items-center`} style={{ float: "right", marginRight: "80%" }}>
            {(userType === 1) ?
              <div className={`d-flex align-items-center`}>
                <button onClick={() => {
                  // Calling endpoint coz the object assiang for user right give undeifned value otherwise!
                  setTerminologyModal(s => !s);
                  setCurrentTerm(row);
                  callEndPoint()
                }
                }
                  className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                  style={{ backgroundColor: bgColors.bgBlue1000 }}>
                  <Settings style={{ fill: "#fff", width: 22, height: 22 }} />
                </button>
                <button className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                  style={{ backgroundColor: bgColors.bgBlue1000, marginRight: "-50px" }}
                  onClick={() => {
                    setTermModal(true);
                  }
                  }>
                  <img src={deleteIcon} alt="" />
                </button>
                {/* <button style={{backgroundColor: bgColors.bgBlue1000}} className={`${style.iconBtn} pr-0 pl-0 ml-2`}>
               <ControlPointDuplicate style={{fill: "#fff", width: 22, height: 22}}/>
             </button> */}
              </div> :
              ((
                  ((userType === 2 && checkUserRight(user, [701, 702, 703])) || userType === 3 && checkUserRight(user, [701, 702, 703])) || (owners?.includes(user.id))) &&
                <div className={`d-flex align-items-center`}>
                  <button onClick={() => {
                    setTerminologyModal(s => !s);
                    setCurrentTerm(row);
                    callEndPoint()
                  }
                  }
                    className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                    style={{ backgroundColor: bgColors.bgBlue1000 }}>
                    <Settings style={{ fill: "#fff", width: 22, height: 22 }} />
                  </button>
                  <button className={`${style.iconBtn} pr-0 pl-0 ml-2`}
                    style={{ backgroundColor: bgColors.bgBlue1000, marginRight: "-50px" }}
                    onClick={() => {
                      setTermModal(true);
                    }
                    }>
                    <img src={deleteIcon} alt="" />
                  </button>
                  {/* <button style={{backgroundColor: bgColors.bgBlue1000}} className={`${style.iconBtn} pr-0 pl-0 ml-2`}>
              <Settings style={{fill: "#fff", width: 22, height: 22}}/>
              <ControlPointDuplicate style={{fill: "#fff", width: 22, height: 22}}/>
            </button> */}
                </div>
              )
            }
            <>
            </>
            {/* } */}
          </div>
        </TableCell>
      </TableRow>
      {/* <TableRow className={`${style.accordion}`}
        style={{ backgroundColor: isItemSelected ? bgColors.bgBlue300 : isEven ? bgColors.bgPrimary0 : bgColors.bgBlue100 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={`pb-5 d-flex flex-wrap`}>
              <br />
              <br />
              <div className={`d-flex align-items-center col-3`}>
                <h6 className={`m-0 mr-2`}>{t('category_type')} :</h6>
                <p className={`m-0 ml-2`}>{t(row.category_type)}</p>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

export default Rows;
