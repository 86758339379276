import React, {useState} from "react";
import style from "../style.module.sass"
import {useTheme} from "@material-ui/core/styles";
import TypeOfField from "../../../../../../components/FormikFields/TypeOfField";
import {Button, Collapse} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useTranslation } from "react-i18next";


  export default function Countings({formik}) {
  const { t } = useTranslation();
  const [bg, setBg] = useState(false)
  const [status, setStatus] = useState("none")
  const [collapse, setCollapse] = useState(true)
  const {theme: {bgColors, colors}} = useTheme();
  const generalFields = [
    {
      id: 5, control: "select", name: "sentenceLineBreakClosing", label: t("Sentence closing rule"), options: [
        {id: 1, name: "Ignore break"},
        {id: 2, name: "2 break"},
        {id: 3, name: "3 break"},
      ]
    },
    {
      id: 2,
      control: "switch",
      name: "includeHyphensInCountings",
      label: t("Include hyphens in countings"),
      labelPlacementEnd: true
    },
    {id: 3, control: "switch", name: "paragraphRecognition", label: t("Paragraph recognition"), labelPlacementEnd: true},
    {
      id: 4,
      control: "switch",
      name: "dontAnalyseTablesContent",
      label: t("Don't analyse content of tables"),
      labelPlacementEnd: true
    },
    {
      id: 6,
      control: "switch",
      name: "sentenceClosingOnSemicolon",
      label: t("Sentence closingon semicolon"),
      labelPlacementEnd: true
    },
    {
      id: 7,
      control: "switch",
      name: "sentenceClosingOnColon",
      label: t("Sentence closing on colon"),
      labelPlacementEnd: true
    },
  ];
  return (
    <div style={{backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50}}
         onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}>
      <div className={`d-flex align-items-center justify-content-between pr-4`}
           style={{color: colors.colorPrimary0, backgroundColor: bgColors.bgPrimary500}}>
        <h4 className={style.title}>{t('countings')}</h4>
        <div>
          <Button onMouseEnter={() => setStatus(status !== "group" ? "hover" : "group")}
                  onMouseLeave={() => setStatus(status !== "group" ? "none" : "group")}
                  onClick={() => setStatus("group")}
                  variant={'contained'} color={status === "hover" || status === "group" ? "primary" : "default"}
                  className={`${style.benchmarks_btn} mr-1`}>{t('Company Default')}</Button>
          <button className={`ml-2 p-0`} onClick={(e) => {
            e.preventDefault()
            setCollapse(!collapse)
          }}>
            {!collapse ? <AddIcon style={{fill: "white"}} fontSize="small"/> :
              <RemoveIcon style={{fill: "white"}} fontSize="small"/>}
          </button>
        </div>
      </div>

      <Collapse in={collapse}>
        {status === "group" || status === "hover" ?
          <ul className={`mb-0 pl-5`}>
            {
              generalFields.map(
                f => (
                  <li key={f.id}>
                    <TypeOfField status={status === "group" || status === "hover" ? "group" : null}
                                 setAutoFillState={setStatus} control={f.control} formik={formik} name={f.name}
                                 label={f.label} {...f}/>
                  </li>
                )
              )
            }
          </ul> :
          <ul className={`mb-0 pl-5`}>
            {
              generalFields.map(
                f => (
                  <li key={f.id}>
                    <TypeOfField control={f.control} formik={formik} name={f.name} label={f.label} {...f}/>
                  </li>
                )
              )
            }
          </ul>
        }
      </Collapse>
    </div>
  )
}
