import React from "react"
import { Switch as SwitchField } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'

function Switch({ formik, label, name, labelPlacementEnd, status, value = null, onChange, defaultChecked, color = 'primary', setAutoFillState, rights, ...rest }) {
  return (
    <FormControlLabel
      name={name && name}
      // onClick={() => setAutoFillState && setAutoFillState(null)}
      onChange={formik?.handleChange}
      {...rest}
      color={color}
      labelPlacement={labelPlacementEnd ? "end" : "top"}
      control={<SwitchField
        defaultChecked={defaultChecked}
        onChange={onChange && onChange}
        // checked={status && status === "company" ? true : status && status === "group" ? false : value ? value : formik?.values[name]}
        checked={rights ? defaultChecked : value ? value : formik?.values[name]}
        color={rest?.disabled ? "default" : color} />}
      disabled={rest?.disabled}
      label={label}
    />
  )
}

export default Switch
