import 'date-fns';
import { useTheme } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableCell from "@material-ui/core/TableCell";
import React, { useEffect } from "react";
import { Box, Button, IconButton } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { checkUserRight } from '../../../../../utils'

function TableFilter({
  callEndPointReCall,
  searchFirma,
  setTextValue,
  textValue,
  limitIs
}) {
  const [open, setOpen] = React.useState(false);
  const { theme: { bgColors, borderColors, colors } } = useTheme();
  const { t } = useTranslation();
  const user = useSelector(state => state.userData.user);

  // Clearing up the input filter if those condition are meet!
  useEffect(() => {
    setTextValue('');
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitIs])

  const resetFilter = () => {
    // if (textValue !== '') {
      setOpen(false);
      setTextValue('');
      callEndPointReCall('delete')
    // }
    // callEndPointReCall();
  }

  return (
    <>
      <TableHead style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.tableFilter}`}>
        <TableRow>
          <TableCell colSpan={12} padding="checkbox">
            <Box style={{ color: colors.colorPrimary500 }}
              className={`d-flex align-items-center p-3`} margin={1}>
              <Button className={`${style.filterBtn} mr-4`} onClick={() => setOpen(!open)} variant="contained"
                color="primary"
                startIcon={<FilterListIcon />}>
                {t('filter')}
              </Button>

              <Paper className={`${style.search} d-flex`}
                style={{ backgroundColor: bgColors.bgPrimary0, borderColor: borderColors.borderPrimary400 }}>
                <IconButton style={{ padding: "9px" }} type="submit" aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  value={textValue}
                  id='searchBox'
                  placeholder={t('search')}
                  onChange={(e) => setTextValue(e.target.value)}
                // style={{ marginTop: "5px" }}
                />
              </Paper>
              <Button className={`${style.filterBtn} ml-2 mr-3`}
                onClick={() => {
                  if (textValue !== '') {
                    searchFirma()
                  }
                }
                } variant="contained"
                style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary"
              >
                {t("apply-filter")}
              </Button>
              <Button className={`${style.filterBtn} ml-2 mr-3`} onClick={() => resetFilter()} variant="contained"
                style={{ backgroundColor: bgColors.bgBlue1000 }} color="primary"
              >
                {t("reset-filter")}
              </Button>
              {(checkUserRight(user, [307])) &&
                <NavLink className={`${style.addBtn}`} to={'/administration/group/update/add-new-group'}>
                  <Button variant="contained" color="primary" startIcon={<AddIcon />}>
                    {t('add-new-firma')}
                  </Button>
                </NavLink>
              }
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableHead style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.filterDashboard}`}>
        {/* <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{color: colors.colorPrimary500}} className={`d-flex align-items-center mb-4 pt-2 pl-3 pr-3`}
                 margin={1}>
              <CloseIcon onClick={() => setOpen(false)} className={`mr-3`}/>
              <Autocomplete
                id="combo-box-demo"
                options={selectVariants}
                getOptionLabel={(option) => option.title}
                className={`d-flex align-items-center mr-3`}
                style={{width: 300, height: "52px"}}
                renderInput={(params) => <TextField {...params} style={{height: "52px"}} label="Text type"
                                                    variant="filled"/>}
              />
              <Autocomplete
                id="combo-box"
                options={selectVariants}
                getOptionLabel={(option) => option.title}
                className={`d-flex align-items-center mr-3`}
                style={{width: 300, height: "52px"}}
                renderInput={(params) => <TextField {...params} style={{height: "52px"}} label="Feature"
                                                    variant="filled"/>}
              />
            </Box>
          </Collapse>
        </TableCell>
        </TableRow> */}
      </TableHead>
    </>
  );
}

// const selectVariants = [
//   {title: 'Einkauf'},
//   {title: 'Einkauf 2'},
//   {title: 'Einkauf 3'},
//   {title: 'Einkauf 4'},
//   {title: 'Einkauf 5'},
//   {title: "Einkauf 6"},
//   {title: 'Einkauf 7'},
// ];

export default TableFilter
