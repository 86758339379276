import React from "react";
import style from "./style.module.scss"
import DashHeader from "../Header";
import RadialBar from "../UI/RadialBar";
import SentenceConst from "./SentenceConst";
import ColumnCharts from "./ColumnCharts";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {chooseColor, convertLanguagesToShort, removeTagMark, roundWithPrecision, stripHtml} from "../../../utils";
import {Redirect} from "react-router";
import config from "../../../config";

export default function HIXComponent() {

    const {t, i18n: {language: i18nLanguage}} = useTranslation();
    const archiveName = useSelector(state => state.analyseData.archives?.name);
    const benchmark = useSelector(state => state.analyseData.result.benchmark);
    const themeColors = {
        red: "#b00014",
        yellow: "#dd540a",
        green: "#9db10d",
        blue: "#415C98"
    };

    const resultTemplate = useSelector(state => state.analyseData.result.resultTemplate);
    const resultData = useSelector(state => state.analyseData.result.resultData);
    const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);
    const targetHix = currentBenchmarkSettings?.settings?.formulaHix?.values?.target || 0;
    const fullText = removeTagMark(useSelector(state => state.analyseData.text));

    if (resultTemplate < 1)
        return <Redirect to={"/"}/>;


    /*** SentenceConst ***/
    let counters = [];

    if (resultData?.moreSentencesInWords && resultData?.moreSentencesInWords?.length > 0) {
        const vaName = "moreSentencesInWords";
        const val = resultData[vaName];
        const countNumber = val.length;
        const localTarget = currentBenchmarkSettings?.settings[vaName]?.values?.target || -100;
        //const localThreshold = currentBenchmarkSettings?.settings[vaName]?.values?.threshold || 0;
        const localTargetYellowGreen = currentBenchmarkSettings?.settings[vaName]?.values?.yellowGreen || -100;
        const localTargetYellowRed = currentBenchmarkSettings?.settings[vaName]?.values?.yellowRed || -100;
        const localPercent = countNumber / (resultData[config.fullCountBase[vaName]] / 100);
        const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
        const textPosition = val[Math.floor(Math.random() * val.length)]

        const text = stripHtml(fullText.substring(resultData.dataTokenizer[textPosition[0]]?.begin, resultData.dataTokenizer[textPosition[1]]?.end))
        counters.push({
            value: roundWithPrecision(localPercent, 0),
            color: themeColors[localColor],
            text: [text],
            title: t("LONG SENTENCES"),
            subTitle: t("Longest sentence"),
            lCount: countNumber,
            lCountMax: resultData?.countSentences || 0,
            lText: [t("Target values are no more than n sentences too long", {number: localTarget})],
            lTitle: t("Sentences are too long"),
            label: "",
            withLinear: false,
            textsComp: false
        })
    }
    if (resultData?.moreSentencesInClauses && resultData?.moreSentencesInClauses?.length > 0) {
        const vaName = "moreSentencesInClauses";
        const val = resultData[vaName];
        const countNumber = val.length;
        const localTarget = currentBenchmarkSettings?.settings[vaName]?.values?.target || -100;
        //const localThreshold = currentBenchmarkSettings?.settings[vaName]?.values?.threshold || 0;
        const localTargetYellowGreen = currentBenchmarkSettings?.settings[vaName]?.values?.yellowGreen || -100;
        const localTargetYellowRed = currentBenchmarkSettings?.settings[vaName]?.values?.yellowRed || -100;
        const localPercent = countNumber / (resultData[config.fullCountBase[vaName]] / 100);
        const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
        const textPosition = val[Math.floor(Math.random() * val.length)]

        const text = stripHtml(fullText.substring(resultData.dataTokenizer[textPosition[0]]?.begin, resultData.dataTokenizer[textPosition[1]]?.end));

        counters.push({
            value: roundWithPrecision(localPercent, 0),
            color: themeColors[localColor],
            text: [text],
            title: t("COMPLEX SENTENCES"),
            subTitle: t("Complicated sentence"),
            lCount: countNumber,
            lCountMax: resultData?.countSentences || 0,
            lText: [t("Target value is no more than n sentences that are too complicated", {number: localTarget})],
            lTitle: t("Sentences are too complicated"),
            label: "",
            withLinear: false,
            textsComp: false
        })
    }
    if (resultData?.moreWordsInLetters && resultData?.moreWordsInLetters?.length > 0) {
        const vaName = "moreWordsInLetters";
        const val = resultData[vaName];
        const countNumber = val.length;
        const localTarget = currentBenchmarkSettings?.settings[vaName]?.values?.target || -100;
        //const localThreshold = currentBenchmarkSettings?.settings[vaName]?.values?.threshold || 0;
        const localTargetYellowGreen = currentBenchmarkSettings?.settings[vaName]?.values?.yellowGreen || -100;
        const localTargetYellowRed = currentBenchmarkSettings?.settings[vaName]?.values?.yellowRed || -100;
        const localPercent = countNumber / (resultData[config.fullCountBase[vaName]] / 100);
        const localColor = chooseColor(localPercent, localTargetYellowGreen, localTargetYellowRed);
        const textPosition = val.sort((a, b) => b[1] - a[1]).slice(0,4);
            //new Array(4).fill(null).map(()=>val[Math.floor(Math.random() * val.length)]);

        const randomText =
            textPosition.reduce((acc,v) => acc + `${stripHtml(fullText.substring(resultData.dataTokenizer[v[0]]?.begin, resultData.dataTokenizer[v[0]]?.end))},`, '').split(',');

        const uniqueText = [...new Set([...randomText])];

        const sortedText = uniqueText.sort((a, b) => b.length - a.length)

        const seperateText =  !!sortedText && sortedText.map(el =>  `${el}<br/>` )

        const text = !!seperateText && seperateText.join('')

        counters.push({
            value: roundWithPrecision(localPercent, 0),
            color: themeColors[localColor],
            text: [text],
            title: t("LONG WORDS"),
            subTitle: t("Longest words"),
            lCount: countNumber,
            lCountMax: resultData?.countWords || 0,
            lText: [t("The target is no more than n words that are too long", {number: localTarget})],
            lTitle: t("Words are too long"),
            label: "",
            withLinear: false,
            textsComp: false
        })
    }
    /*** SentenceConst ***/


    /*** ColumnCharts ***/
    const categories = resultData['dataTokenizer'].reduce((acc,val) =>{
        if (val.tag !== 'SGML_TAG') {
            if (!acc.words[val?.word.length])
                acc.words[val?.word.length] = 0
            acc.words[val?.word.length]++;
            acc.wordcount++;
        }
        if (val?.cla === 'CLA') {
            if (!acc.clauses[val?.cla_cnt])
                acc.clauses[val?.cla_cnt] = 0
            acc.clauses[val?.cla_cnt]++;
        }
        if (val?.snt === 'SNT') {
            if (!acc.sentences[val?.snt_cnt])
                acc.sentences[val?.snt_cnt] = 0
            acc.sentences[val?.snt_cnt]++;
        }
        return {...acc};
    }, {clauses:{},sentences:{}, words:{}, wordcount: 0})

    const seriesClauses =  [{
        name: '',
        data: Object.values(categories.clauses).map(v=> roundWithPrecision(v/(resultData['countClauses']/100))),
    }];
    const seriesSentences = [{
        name: '',
        data: Object.values(categories.sentences).map(v => roundWithPrecision(v / (resultData['countSentences'] / 100))),
    }];
    const seriesWords = [{
        name: '',
        data: Object.values(categories.words).map(v => roundWithPrecision(v / (categories.wordcount / 100))),
    }];

    /*** HIX wordlist ***/

    // hix
    function calculateWords(resultData) {
        let wordsWithFilters = [];
        for (let wordInLetter of resultData['moreWordsInLetters']) {
            const word = resultData.dataTokenizer[wordInLetter[0]];
            if (!word) {
                continue;
            }
            const lowercaseWord = word.word.toLowerCase()
            if (word.tag !== 'SGML_TAG' && wordsWithFilters.findIndex(v => v.word.toLowerCase() === lowercaseWord) === -1) {
                wordsWithFilters.push({
                    word: word.word,
                    length: word.word.length,
                    freq: resultData['dataTokenizer'].reduce((acc, v) => v.word.toLowerCase() === lowercaseWord ? acc + 1 : acc, 0)
                });
            }
        }
        return wordsWithFilters.sort((a, b) => b.word.length - a.word.length);
    }

    const hixTableCells = calculateWords(resultData);


    /*** ColumnCharts ***/

    const colorHIX = resultData["formulaHix"] < targetHix / 2 ? "#dd0d08" : resultData["formulaHix"] < targetHix ? "#da7d27" : "#a8c721";

    // hix text
    let dash_hix_text;
    if (resultData["formulaHix"] > 16.6)
        dash_hix_text = t("dash_hix_text_16_6")
    else if (resultData["formulaHix"] > 13.3)
        dash_hix_text = t("dash_hix_text_13_3")
    else if (resultData["formulaHix"] > 10)
        dash_hix_text = t("dash_hix_text_10")
    else if (resultData["formulaHix"] > 6.6)
        dash_hix_text = t("dash_hix_text_6_6")
    else if (resultData["formulaHix"] > 3.3)
        dash_hix_text = t("dash_hix_text_3_3")
    else
        dash_hix_text = t("dash_hix_text_0")

    return (
        <div className={style.mainCont}>
            <DashHeader archiveName={archiveName}
                        benchmarkName={benchmark['name'][convertLanguagesToShort(i18nLanguage)]}
                        name={t('HIX_dashboard_header')} />
            <div className={`d-flex`} style={{margin: "0 30px 30px 0"}}>
                <RadialBar
                    value={roundWithPrecision(resultData["formulaHix"], 1)}
                    max={20}
                    color={"#f0f0f0"}
                    text={dash_hix_text}
                    bg={colorHIX}
                    name={t("HIX_dashboard_header")}
                    // targetVal={roundWithPrecision(resultData["formulaHix"], 1)}
                    targetVal={targetHix}
                    withNavLink={false}
                    mainText={t("dash_hix_main_text")}
                />
            </div>
            <SentenceConst hixTableCells={hixTableCells} counters={counters}/>
            <ColumnCharts categories={categories} seriesClauses={seriesClauses} seriesSentences={seriesSentences}
                          seriesWords={seriesWords}/>
    </div>
  )
}
