import React, {useState} from "react";
import style from "../style.module.sass"
import {useTheme} from "@material-ui/core/styles";
import TypeOfField from "../../../../../../components/FormikFields/TypeOfField";
import {Collapse, Switch as SwitchField} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useTranslation } from "react-i18next";

export default function GroupProfile({formik}) {
  const [bg, setBg] = useState(false)
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(true)
  const [collapseLicense, setCollapseLicense] = useState(true)
  const {theme: {bgColors, colors}} = useTheme();
  const fields = [
    {id: "1", control: "text", name: "groupName", label: t("User group name")},
    {id: "5", control: "date", name: "expirationDate", label: t("Expiration date")},
    {id: "3", control: "text", name: "maximumUsers", label: t("Maximum users")},
    {
      id: "2",
      control: "text",
      name: "maxNumberUsersLoggedSimultaneously",
      label: t("Maximum logged users")
    },
  ];
  return (
    <div style={{backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50}}
         onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}>
      <div style={{backgroundColor: bgColors.bgPrimary500}}
           className={`${style.header} ${style.borderTop} d-flex align-items-center justify-content-between pr-4`}>
        <h4 style={{color: colors.colorPrimary0}}
            className={style.title}>{t('group-profile')}</h4>
        <button className={`ml-2 p-0`} onClick={(e) => {
          e.preventDefault()
          setCollapse(!collapse)
        }}>
          {!collapse ? <AddIcon style={{fill: "white"}} fontSize="small"/> :
            <RemoveIcon style={{fill: "white"}} fontSize="small"/>}
        </button>
      </div>
      <Collapse in={collapse}>
        <ul className={`mb-0`}>
          {
            fields.map(
              f => (
                <li key={f.id}>
                  <TypeOfField control={f.control} formik={formik} name={f.name} label={f.label} {...f}/>
                </li>
              )
            )
          }
        </ul>
        <FormControlLabel
          onChange={() => setCollapseLicense(!collapseLicense)}
          value={collapseLicense}
          className={`ml-3 pl-2 pt-2`}
          labelPlacement={"end"}
          control={<SwitchField
            color={"primary"}/>}
          label={t("Schedule licence change")}
        />
        <Collapse in={!collapseLicense}>
          <div className={`position-relative d-flex align-items-center ml-4 mr-4 pl-2 pr-2 pb-2 mb-1 pt-2`}>
            {t('on')}
            <TypeOfField className={`mr-3 ml-3`} control={"date"} formik={formik} name="expirationDate"
                         label={"Expiration date"}/>
            <span className={`mr-3`}>{t('change')}</span>
            <TypeOfField control={"select"} formik={formik}
                         name="maxNumberUsersLoggedSimultaneously"
                         options={[{id: 1, name: "max logged users"}, {id: 2, name: "max users"},]}
                         label={"Max logged users"}/>
            <span className={`ml-3`}>to</span>
            <TypeOfField className={`ml-3`} style={{width: "300px"}} type={"number"} control={"text"} formik={formik}
                         name="userNum"
                         label={"number"}/>
          </div>
          <TypeOfField className={`ml-3 pl-2 mr-4 pr-2 pb-2 mb-4 pt-2`} control={"switch"} formik={formik}
                       name={"informAdmins"} label={"inform admin(s) 7 days prior"} labelPlacementEnd={true}/>
        </Collapse>
      </Collapse>
    </div>
  )
};
