import {useTheme} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import style from "./style.module.sass";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React, {useState, useEffect} from "react";
import deleteIcon from "../assets/deleteIcon.svg";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useTranslation } from "react-i18next";
import { Collapse, Modal, Box, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import closeIcon from "../../../../../assets/close-circle.svg";
import config from '../../../../../config'

const headCells = [
  {id: 'firmaName', numeric: false, disablePadding: true, label: 'Company name', orderBy_value: 'name'},
  {id: 'associatedName', numeric: false, disablePadding: false, label: 'Associated groups', orderBy_value: 'associated_name'},
  {id: 'changeDate', numeric: false, disablePadding: false, label: 'Change date', orderBy_value: 'update_time'},
  {id: 'maxLicense', numeric: false, disablePadding: false, label: 'Licenses', orderBy_value: 'license_id'},
  {id: 'arrow', numeric: false, disablePadding: false, label: 'editMissing'},
];

function EnhancedTableHead({onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
  deleteAlertMsg,
  deleteCheckedFirmaModal,
  setDeleteCheckedFirmaModal,
  deleteChecked,
  onWordExport,
}) {
  const {theme: {bgColors, colors}} = useTheme();
  const createSortHandler = (property,orderBy_value) => (event) => {
    if(property !== 'arrow'){
      onRequestSort(event, property,orderBy_value);
    }
  };
  const {t} = useTranslation()
  const [showError, setShowError] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState();

  useEffect(() => {
    setDeleteMsg(deleteAlertMsg);
    setTimeout(() => {
      setShowError(false)
      setDeleteMsg('');
    }, config.timeOutDuration)
    return () => {
      setShowError(false)
      setDeleteMsg('');
  }
  }, [deleteAlertMsg, showError])

  return (
    <>
    <Modal open={deleteCheckedFirmaModal} onClose={() => setDeleteCheckedFirmaModal(false)} style={{ width: '50%', margin: '0 auto', marginTop: '20%', height: '300px' }}>
        <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
          <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
            style={{ backgroundColor: bgColors.bgPrimary400 }}>
            <p className={`m-0 ${style.title}`} style={{ color: 'white', fontSize: '25px' }}>
              {t('clear-firma')}
            </p>
            <button className={`border-0 bg-transparent`} onClick={() => setDeleteCheckedFirmaModal(false)}>
              <img src={closeIcon} alt="" />
            </button>
          </div>
          <br />
          <div className={style.error} >
            <Collapse in={!!deleteMsg} >
              <Alert
                className={`m-0`}
                severity='error'>
                <h6 className={`m-0`}>
                  {t('delete-error-msg')}
                </h6>
              </Alert>
            </Collapse>
          </div>
          <div>
            <Button color='primary' variant='contained'
              onClick={(e) => {
                deleteChecked();
                setShowError(true)
              }
              }
              style={{ marginLeft: '40%', marginTop: '4%' }}>
              {t('yes')}
            </Button>
            <Button color='primary' variant='contained'
              onClick={() => { setDeleteCheckedFirmaModal(false); }}
              style={{ marginLeft: '4%', marginTop: '4%' }}>
              {t('no')}
            </Button>
          </div>
        </div>
      </Modal>
      <TableHead style={{backgroundColor: bgColors.bgBlue300}} className={`${style.filterDashboard}`}>
        <TableRow>
        <TableCell colSpan={12} padding="checkbox">
          <Collapse in={numSelected  ? true : false} timeout="auto" unmountOnExit>
            <Box className={`${style.selectRow} pl-3 pr-3 d-flex align-items-center`} margin={1}>
              <p style={{color: colors.colorPrimary1000}} className={`m-0`}>{numSelected} {t('selected')}</p>
              <Button className={style.export} variant="contained" color="primary"
                      style={{backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0}}
                      onClick={onWordExport}>
                {t('export')}
              </Button>
              <button className={`${style.delete} pr-0 pl-0 ml-2`}
                      style={{backgroundColor: bgColors.bgBlue1000}}
                      onClick={e => setDeleteCheckedFirmaModal(true)}
                      >
                <img src={deleteIcon} alt=""/>
              </button>
            </Box>
          </Collapse>
        </TableCell>
        </TableRow>
      </TableHead>
      <TableHead style={{backgroundColor: bgColors.bgPrimary500}} className={`${style.tableHead}`}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{'aria-label': 'select all desserts'}}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                style={{flexDirection: "row"}}
                onClick={createSortHandler(headCell.id, headCell.orderBy_value)}
                IconComponent={(props) => (
                  <ArrowDownwardIcon style={{order: "9", color: colors.colorPrimary0}} alt="" {...props}/>)}
              >
                <p style={{color: colors.colorPrimary0}} className={`m-0`}>{headCell.label}</p>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default EnhancedTableHead
