import React, { Fragment, useEffect, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import style from './style.module.sass'
import { Box, Tooltip } from '@material-ui/core'
import TLServiceComponent from '../../../contexts/TLServiceComponent'
import { useActions } from '../../../utils/action-helper'
import {
  archiveSetFolderForceReload,
  dashboardClearAll, dashboardInitOne,
  dashboardSetFilter, dashboardSetIdList, dashboardSetLanguage,
  dashboardSetType,
  tableSetDialog, tableSetOtherData, tableSetSelectAll, tableSetSelected,

} from '../../../actions'
import { useSelector } from 'react-redux'
import { Delete, Description, Equalizer, SwitchCamera, TableChart } from '@material-ui/icons'
import { Redirect } from 'react-router'
import Spinner from '../../Spinner'
import {
  convertFromErrorObject, convertLanguagesToFull,
  convertLanguagesToShort,
  deepCopy,
  downloadBufferAsFile,
  isObjectEmpty, onlyUnique, uploadAsFile
} from '../../../utils'
import config from '../../../config'
import ExportExcelModal from '../ExportExcelModal/ExportExcelModal'
import MoveToFolderModal from '../MoveToFolderModal/MoveToFolderModal'

export default function TableMassActionComponent ({
  reloadAll
}) {

  const { theme: { bgColors, colors } } = useTheme()
  const { t, i18n: { language: i18nLanguage } } = useTranslation()
  const tlService = React.useContext(TLServiceComponent)

  const {
    aDashboardClearAll, aTableSetSelectAll,
     aTableSetSelected,
     aDashboardInitOne,
     aArchiveSetFolderForceReload,
    aTableSetDialog, aTableSetOtherData
  } = useActions({
    aDashboardClearAll: dashboardClearAll,
    aDashboardSetFilter: dashboardSetFilter,
    aDashboardSetType: dashboardSetType,
    aDashboardSetLanguage: dashboardSetLanguage,
    aDashboardSetIdList: dashboardSetIdList,
    aTableSetDialog: tableSetDialog,
    aTableSetOtherData: tableSetOtherData,
    aTableSetSelectAll: tableSetSelectAll,
    aTableSetSelected: tableSetSelected,
    aArchiveSetFolderForceReload: archiveSetFolderForceReload,
    aDashboardInitOne: dashboardInitOne
  })

  const userId = useSelector(state => state.userData.user.id)
  const userGroupId = useSelector(state => state.userData.user.user_group_id)
  const companyId = useSelector(state => state.userData.user.company_id)

  const dialogType = useSelector(state => state.tableData.dialog.type)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const dialogResponse = useSelector(state => state.tableData.dialog.response)
  const archiveLanguage = useSelector(state => state.userData.archiveLanguage)
  const currentPage = useSelector(state => state.tableData.currentPage)
  const limit = useSelector(state => state.tableData.limit)
  const otherData = useSelector(state => state.tableData.otherData)
  const selected = useSelector(state => state.tableData.selected)
  const selectAll = useSelector(state => state.tableData.selectAll)
  const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])
  const enabledTemplate = useSelector(state => state.userData.user.enabled_templates)
  const selectAllIds = useSelector(state => state.tableData.selectAllIds)
  const token = useSelector(state => state.userData.token)
  const filters = useSelector(state => state.tableData.filters)
  const orderBy = useSelector(state => state.tableData.orderBy || 'analyse_id')
  const order = useSelector(state => state.tableData.order)
  const archiveType = useSelector(state => state.archiveData.archiveType)

  const [redirect, setRedirect] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [exportExcelModal, setExportExcelModal] = useState(false)
  const [moveFolderModal, setMoveFolderModal] = useState(false)
  const [massDelete, setMassDelete] = useState([])
  const [spinnerMessage, setSpinnerMessage] = useState('')


  const getAnalizeIdById = async (ids) => {
    if (ids?.length) {
      try {
        const res = await tlService.getFilteredArchive(token, {}, {
          exact_result: 2,
          by_last: 0,
          extended_filter: JSON.stringify([["&id", `i=${JSON.stringify(ids)}`]]),
          return_values: '["id","analyse_id"]'
        })
        if (res?.data?.length) {
          const arrRet = []
          for (const el of res.data) {
            arrRet.push(el.analyse_id)
          }
          return arrRet.filter(onlyUnique)
        }
      } catch (err) {
        console.log(err)
      }
    }
    return []
  }

  useEffect(() => {
    const effectFunction = async () => {
      if (!dialogOpen && dialogType) {
        switch (dialogType) {
          case 'massDeleteArchives':
            if (dialogResponse && !isObjectEmpty(massDelete)) {
              setSpinner(true)

              const analyseIds = await getAnalizeIdById(massDelete)
              for (const id of analyseIds) {
                try {
                  setSpinnerMessage(t('deleting_archives') + id  )
                  await tlService.deleteArchive(token, id)
                } catch (error) {
                  aTableSetDialog({
                    type: 'errorMassDelete',
                    open: true,
                    RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
                    response: null,
                    buttons: {
                      yes: false,
                      no: false,
                      close: true,
                      save: false
                    }
                  })
                  setMassDelete([])
                  setSpinner(false)
                  reloadAll(convertLanguagesToFull(archiveLanguage), limit, order, orderBy, currentPage, filters, setSpinner)
                  aArchiveSetFolderForceReload(true)
                  aTableSetSelectAll(false)
                  aTableSetSelected([])
                  return false
                }
              }
              aTableSetSelectAll(false)
              aTableSetSelected([])
              setMassDelete([])
              setSpinner(false)
              reloadAll(convertLanguagesToFull(archiveLanguage), limit, order, orderBy, currentPage, filters, setSpinner)
              aArchiveSetFolderForceReload(true)
            }
            break
          default:
            break
        }
      }
    }
    effectFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen, dialogType])

  const exportExcelRun = async () => {
    const exportList = otherData.exportList
    let allIds = selected
    if (selectAll) {
      allIds = selectAllIds
    }
    if (!allIds?.length) {
      return false
    }

    const requestList = []
    for (const el of exportList) {
      for (const child of el.children) {
        if (child?.selected) {
          if (el.startWith && !child?.ignoreStart) {
            requestList.push(el.startWith + child.name)
          } else {
            requestList.push(child.name)
          }
        }
      }
    }
    if (!requestList?.length) {
      return false
    }
    let pageResponse = {}
    try {
      pageResponse = await tlService.archiveExportExcel(token, enabledTemplate['export_template_excel'], JSON.stringify(requestList), JSON.stringify(allIds))
    } catch (err) {
      console.log(err)
    }
    if (!isObjectEmpty(pageResponse?.resultfile)) {
      const b = Buffer.from(pageResponse.resultfile, 'base64')
      let today = new Date()
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      downloadBufferAsFile(t('analyse-result').concat(date), b, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    }
    aTableSetSelectAll(false)
    aTableSetSelected([])
  }

  const handleStartExport = () => {
    setExportExcelModal(false)
    exportExcelRun()
  }

  const handleExcelExport = async () => {
    const excelExportList = config.excelExportList
    let allIds = selected
    if (selectAll) {
      allIds = selectAllIds
    }
    if (!allIds?.length) {
      return false
    }
    let res = []
    const finalExportList = []
    try {
      res = await tlService.archiveExcelValues(token, convertLanguagesToFull(archiveLanguage), JSON.stringify(allIds))
    } catch (error) {
      aTableSetDialog({
        type: 'errorGetExcelValues',
        open: true,
        RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
        response: null,
        buttons: {
          yes: false,
          no: false,
          close: true,
          save: false
        }
      })
      aTableSetSelectAll(false)
      aTableSetSelected([])
      return false
    }

    if (!isObjectEmpty(res.values)) {
      for (const key in excelExportList) {
        if (excelExportList[key]?.startWith) {
          const dataTermsList = res.values.filter(v => v.startsWith(excelExportList[key].startWith))
          if (dataTermsList?.length) {
            finalExportList.push({
              name: excelExportList[key].name,
              translation: excelExportList[key].translation,
              children: [],
              selected: true,
              startWith: excelExportList[key].startWith,
              baseOn: excelExportList[key].baseOn
            })
            for (const term of dataTermsList) {
              const id = term.split('.')[1]
              finalExportList[finalExportList.length - 1].children.push({
                name: id,
                translation: res.categories[id] || id,
                selected: true,
                useTranslation: false
              })
            }
          }
        }
        if (excelExportList[key]?.baseOn) {
          if (res.values.includes(excelExportList[key].baseOn)) {
            const newChild = deepCopy(excelExportList[key].children)
            finalExportList.push({
              name: excelExportList[key].name,
              translation: excelExportList[key].translation,
              children: newChild.map(v => {return { ...v, selected: true, useTranslation: true }}),
              selected: true,
              startWith: excelExportList[key].startWith,
              baseOn: excelExportList[key].baseOn
            })
          }
        } else {
          for (const child of excelExportList[key].children) {
            if (res.values.includes(child.name)) {
              let sectionIndex = finalExportList.findIndex(v => v.name === excelExportList[key].name)
              if (sectionIndex === -1) {
                finalExportList.push({
                  name: excelExportList[key].name,
                  translation: excelExportList[key].translation,
                  children: [],
                  selected: true
                })
                sectionIndex = finalExportList.length - 1
              }
              finalExportList[sectionIndex].children.push({
                name: child.name,
                translation: child.translation,
                ignoreStart: child?.ignoreStart,
                selected: true,
                useTranslation: true
              })
            }
          }
        }
      }
    }
    aTableSetOtherData({ ...otherData, exportList: finalExportList })
    setExportExcelModal(true)
  }

  const handleDashboard = async () => {
    let allIds = selected

    if (selectAll) {
      allIds = selectAllIds
    }

    if (!allIds?.length) {
      return false
    }

    aDashboardClearAll(1)
    aDashboardInitOne(
      0,
      {
        user_id: filters['type']?.value === 'personal' ? userId : undefined,
        user_group_id: filters['type']?.value === 'group' ? userGroupId : undefined,
        company_id: filters['type']?.value === 'company' ? companyId : undefined,
      },
      allIds,
      'single',
      archiveLanguage,
      filters['type']?.value === 'personal' ? 'user' : filters['type']?.value)
    setTimeout(() => setRedirect('/dashboard/compare'), 100)

  }

  const handleRunMoveFolder = async (objFolder, type) => {
    setMoveFolderModal(false)
    setSpinner(true)
    await runMoveFolder(objFolder, type)
    reloadAll(convertLanguagesToFull(archiveLanguage), limit, order, orderBy, currentPage, filters, setSpinner)
    aArchiveSetFolderForceReload(true)
  }

  const runMoveFolder = async (objFolder, type) => {
    console.log('handleRunMoveFolder', objFolder, type)
    let allIds = selected
    if (selectAll) {
      allIds = selectAllIds
    }
    if (!allIds?.length) {
      return false
    }

    // set  UGC data
    const requestBody = {
      folder: parseInt(objFolder.folder),
      user_id: 0,
      user_group_id: 0,
      company_id: 0,
    }
    switch (type) {
      case 'personal':
        requestBody.user_id = userId
        break
      case 'group':
        requestBody.user_group_id = userGroupId
        break
      case 'company':
        requestBody.company_id = companyId
        break
      default:
        break
    }

    const analyseIds = await getAnalizeIdById(allIds)
    for (const id of analyseIds) {
      try {
        setSpinnerMessage(t('moving_archive') + ` ${id}` )
        await tlService.editArchive(token, id, requestBody)
      } catch (error) {
        aTableSetDialog({
          type: 'errorUpdateArchive',
          open: true,
          RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
          response: null,
          buttons: {
            yes: false,
            no: false,
            close: true,
            save: false
          }
        })
        aTableSetSelectAll(false)
        aTableSetSelected([])
        return false
      }
    }
    aTableSetSelectAll(false)
    aTableSetSelected([])
    return true
  }

  const handleMassDelete = () => {
    let allIds = selected
    if (selectAll) {
      allIds = selectAllIds
    }
    if (!allIds?.length) {
      return false
    }
    setMassDelete(allIds)
    aTableSetDialog({
      type: 'massDeleteArchives',
      open: true,
      RequestComponent: () => <>{t('are_you_sure_want_to_delete_archives')}</>,
      response: null,
      buttons: {
        yes: true,
        no: true,
        close: false,
        save: false
      }
    })
  }

  const handleWordExport = async () => {

    let allIds = selected
    if (selectAll) {
      allIds = selectAllIds
    }
    if (!allIds?.length) {
      return false
    }
    const values = '["archive_name","version","owner","formulaHix","formulaCLIX","formulaSPIX","create_time","text"]'
    try {
      const res = await tlService.archiveWordExportData(token, '', enabledTemplate['export_template_word'], values, JSON.stringify(allIds))
      uploadAsFile('word-data', Buffer.from(res.resultfile, 'base64').toString('utf-8').toString(), 'docx')
    } catch (error) {
      aTableSetDialog({
        type: 'errorUpdateArchive',
        open: true,
        RequestComponent: () => <>{convertFromErrorObject(t, error)}</>,
        response: null,
        buttons: {
          yes: false,
          no: false,
          close: true,
          save: false
        }
      })
    }
    aTableSetSelectAll(false)
    aTableSetSelected([])
  }

  if (!selectAll && !selected?.length) {
    return null
  }

  if (redirect) {
    return <Redirect to={redirect}/>
  }

  return (
    <Box className={`${style.selectRow} pl-3 pr-3 d-flex align-items-center`} margin={1}>
      {spinner && <div>
            <Spinner/>
            {spinnerMessage && <div
            style={{
              position: 'absolute',
              zIndex: 10001,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            ><strong>{spinnerMessage}</strong></div>}
        </div>}
      <MoveToFolderModal
        handleRunMoveFolder={handleRunMoveFolder}
        moveFolderModal={moveFolderModal}
        setMoveFolderModal={setMoveFolderModal}
        archiveName={archiveType}
      />
      <ExportExcelModal
        handleStartExport={handleStartExport}
        exportExcelModal={exportExcelModal}
        setExportExcelModal={setExportExcelModal}/>
      <div style={{ color: colors.colorPrimary1000, width: '100%' }}>
        {selectAll ? otherData?.archives_fullcount : selected?.length} {t('selected')}
      </div>
      <div>
      </div>
      {enabledSections.indexOf('dashboard-compare') > -1 ?
        <Tooltip title={<h6 style={{ fontSize: '15px' }}>
          {t('multi-dashboard')}
        </h6>} placement="top-start">
          <Equalizer
            className={`ml-2 ${style.export}`}
            variant="contained"
            color="primary"
            style={{ backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0 }}
            onClick={() => handleDashboard()}
          />
        </Tooltip> : <></>}
      {!!enabledTemplate['export_template_word'] ?
        <Tooltip title={<h6 style={{ fontSize: '15px' }}>
          {t('export-word')}
        </h6>} placement="top-start">
          <Description
            className={`ml-2  ${style.export}`}
            variant="contained" color="primary"
            style={{ backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0 }}
            onClick={() => handleWordExport()}/>
        </Tooltip>
        : <div/>
      }
      {!!enabledTemplate['export_template_excel'] ?
        <Tooltip title={<h6 style={{ fontSize: '15px' }}>
          {t('export-excel')}
        </h6>} placement="top-start">
          <TableChart
            className={`ml-2 ${style.export}`}
            onClick={() => handleExcelExport()}
            variant="contained"
            color="primary"
            style={{ backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0 }}
          />
        </Tooltip>
        : <div/>}
      <Tooltip title={<h6 style={{ fontSize: '15px' }}>
        {t('move-analysis')}
      </h6>} placement="top-start">
        <SwitchCamera
          className={`ml-2 ${style.export}`}
          onClick={() => setMoveFolderModal(true)}
          variant="contained"
          color="primary"
          style={{ backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0 }}
        />
      </Tooltip>
      <Tooltip title={<h6 style={{ fontSize: '15px' }}>
        {t('delete')}
      </h6>} placement="top-start">
        <Delete
          onClick={() => handleMassDelete()}
          className={`ml-2 ${style.export}`}
          color={'secondary'}
          style={{ backgroundColor: bgColors.bgBlue1000 }}/>
      </Tooltip>
    </Box>
  )
}