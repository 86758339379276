import React, { useEffect, useRef, useState } from 'react'
import style from './style.module.sass'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Rows from './Rows'
import { useTheme } from '@material-ui/core/styles'
import EnhancedTableHead from './TableHead'
import TableFilter from './TableFilter'
import TableFooter from './TableFooter'
import TLServiceComponent from '../../../../../contexts/TLServiceComponent'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Spinner from '../../../../Spinner'
import { checkUserRight, convertUTCDateToLocalDate, printConvertedDate, uploadAsFile } from '../../../../../utils'

function createData(id, groupName, companyName, groupId, changeDate, primary, featuresActive) {
  return {
    id,
    groupName,
    companyName,
    groupId,
    changeDate,
    primary,
    featuresActive
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.length && array.map((el, index) => [el, index]);
  !!stabilizedThis && stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (!!stabilizedThis) {
    return stabilizedThis.map((el) => el[0]);
  }
}

export default function GroupsTable() {
  const { theme: { bgColors } } = useTheme();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tlService = React.useContext(TLServiceComponent);
  const token = useSelector(state => state.userData.token);
  const [rows, setRows] = useState([])
  const forward = useRef(0)
  const [allDataCount, setAllDataCount] = useState()
  const [limitIs, setLimitIs] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [spinner, setSpinner] = useState(false)
  const [pageData, setPageData] = useState()
  const [deleteRecall, setDeleteRecall] = useState()
  const [textValue, setTextValue] = useState('')
  const [deleteCheckedGroupModal, setDeleteCheckedGroupModal] = useState(false);
  const [deleteAlertMsg, setDeleteAlertMsg] = useState(false)
  const [orderByValue, setOrderByValue] = useState('id');
  const [orderASCDESC, setOrderASCDESC] = useState(0);
  const user = useSelector(state => state.userData.user);
  const { t, i18n: { language: i18nLanguage } } = useTranslation()


  useEffect(() => {
    callEndPoint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Set page to 1 & offset to 0 and checked archive to unchecked when limit is changed or language is changed or archive is changed :
  useEffect(() => {
    setCurrentPage(1)
    forward.current = 0;
    setSelected([])
  }, [limitIs])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleRequestSort = (event, property, orderBy_value) => {
    // const isAsc = orderBy === property && order === 'asc';
    // setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setOrderByValue(orderBy_value)
    if (orderASCDESC === 1) {
      setOrder('asc');
      setOrderASCDESC(0)
    } else {
      setOrder('desc');
      setOrderASCDESC(1)
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const pageSetter = async (val) => {
    setCurrentPage(val)
    forward.current = val * limitIs - 10
    await callEndPoint();
  }

  const callEndPoint = async (val) => {
    setSpinner(true)
    switch (val) {
      case 0:
        forward.current = forward.current - limitIs;
        if (selected.length !== 0) {
          setSelected([]);
        }
        break;
      case 1:
        forward.current = forward.current + limitIs;
        if (selected.length !== 0) {
          setSelected([]);
        }
        break;
      default:
        break;
    }
    let groups;
    let filterIs;
    if (val === 'delete') {
      forward.current = 0;
      filterIs = { offset: forward.current, order_by: orderByValue, order: orderASCDESC };
    } else if (textValue?.length) {
      forward.current = 0;
      filterIs = { offset: forward.current, name: `%${textValue}%`, order_by: orderByValue, order: orderASCDESC };
    } else {
      filterIs = { offset: forward.current, order_by: orderByValue, order: orderASCDESC };
    }
    try {
      groups = await tlService.getFilteredGroups(token, { limit: limitIs }, filterIs);
      setSpinner(false)
    } catch (e) {
      console.log('error', e)
      setSpinner(false);
      setPageData('');
      switch (val) {
        case 0:
          forward.current = forward.current + limitIs;
          break;
        case 1:
          forward.current = forward.current - limitIs;
          break;
        default:
          break;
      }
      setRows('')
      // Setting Total page to 0 if no data found:
      setAllDataCount(0)
      // setting current input page no. to 1 and all data to empty as it will be 1 as well
      if (val !== 0 && val !== 1 && !!val) {
        setCurrentPage(1)
        // setAllData('');
      }
    }
    if (groups?.fullcount) {
      setAllDataCount(groups.fullcount)
    }
    let groupsData = groups?.data && groups?.data;
    setPageData(groupsData);
    // `4/5`, 
    if (!!groups) {
      let arry = [];
      groups?.data.map(el => {
        if (el !== undefined) {
          let createTime = el.create_time ? printConvertedDate(convertUTCDateToLocalDate(el.create_time), i18nLanguage) : ''
          arry.push(createData(el?.id, el?.name, el?.company_name, "123", createTime,{
            spellCheck: true,
            groupArchive: false,
            cleaningRules: false,
            plugin: false,
            coloredWording: true,
            textModule: true
          }))
        }
      })
      setRows([...arry])
      let archiveDataLength;
      archiveDataLength = allDataCount;
      let pageNumber = Math.ceil(archiveDataLength / limitIs);
      if (forward.current >= 0) {
        let currentPageIs;
        if (currentPage < pageNumber) {
          if (val === 0) {
            currentPageIs = Math.floor(forward.current / limitIs - 1);
            if (currentPageIs <= 0) {
              setCurrentPage(1)
            } else {
              if (currentPageIs > 0) {
                setCurrentPage(currentPageIs)
              }
            }
          } else if (val === 1) {
            currentPageIs = Math.floor(forward.current / limitIs + 1);
            if (currentPageIs > 0) {
              setCurrentPage(currentPageIs)
            }
          }
        }
        // here currently when user page no. input and total page is name, this func run to decrease the page no. :
        if (val === 0) {
          // currentPageIs = Math.floor(forward.current / limitIs);
          currentPageIs = currentPage - 1;
          if (currentPageIs > 0) {
            setCurrentPage(currentPageIs)
          }
        }
      }
      // to clean the rows for next page filter by id
      // setRows([]);
    }
  }

  // Consecutive call!
  useEffect(() => {
    callEndPoint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitIs, deleteRecall, orderBy, orderASCDESC])

  const onWordExport = () => {
    /* eslint-disable */
    if (rows?.length) {
      let groupdata = [];
      rows.map(async element => {
        if (selected.includes(element.id)) {
          let row = `<div>
                          <h3>${t("group-name")}  : ${element.groupName}</h3> 
                          <h3> ${t("maxLicence")} : ${element.maxLicense}</h3> 
                          <h3> ${t("create-date")} : ${element?.changeDate?.replace(/[^0-9:]/g, '').slice(0, -1)}</h3>
                          <h3>${t("company-name")}  : ${element.companyName}</h3> 
                        </div>`
          groupdata.push(row)
        }
      })
      let data = groupdata.toString();
      uploadAsFile('word-data', data, 'docx')
    }
  }

  const deleteChecked = async () => {
    const elementsId = rows?.length
      ? rows.map((element) =>
        selected.includes(element.id) ? element.id : null
      )
      : [];
    setSpinner(true);
    for (let element of elementsId) {
      try {
        if (!!element) {
          await tlService.deleteGroup(token, element);
          setDeleteCheckedGroupModal(false);
        }
      } catch (e) {
        setDeleteAlertMsg(e.message);
        console.log("error", e.message);
        break;
      }
    }
    setSelected([]);
    callEndPoint();
  }

  // check for  user right
  if (!checkUserRight(user, [206]))
    return null;

  return (
    <div className={`${style.mainCont}`}>
      <Paper className={`mb-5`}>
        {spinner && <Spinner />}
        <TableContainer>
          <Table
            style={{ backgroundColor: bgColors.bgPrimary100, borderColor: "transparent" }}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableFilter
              callEndPointReCall={callEndPoint}
              searchGroup={callEndPoint}
              setTextValue={setTextValue}
              textValue={textValue}
              numSelected={selected.length}
              order={order}
              limitIs={limitIs}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <EnhancedTableHead
              deleteAlertMsg={deleteAlertMsg}
              deleteCheckedGroupModal={deleteCheckedGroupModal}
              setDeleteCheckedGroupModal={setDeleteCheckedGroupModal}
              deleteChecked={deleteChecked}
              onWordExport={onWordExport}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <Rows key={row.id} handleClick={handleClick} isItemSelected={isItemSelected} labelId={labelId}
                      row={row}
                      isEven={index % 2 === 0}
                      setDeleteRecall={setDeleteRecall}
                    />
                  );
                })}
            </TableBody>
            <TableFooter
              allDataCount={allDataCount}
              pageSetter={pageSetter}
              currentPage={currentPage}
              // allData={allData}
              pageData={pageData}
              callEndPointForward={callEndPoint}
              callEndPointBackward={callEndPoint}
              setLimitIs={setLimitIs}
              rowsPerPageOptions={[10, 25, 40]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
