import React, { useState } from "react";
import style from "./style.module.sass";
import {useTheme} from '@material-ui/core/styles';
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {checkUserRight} from "../../utils";
import {useTranslation} from "react-i18next";
import ErrorPopup from "../Analysis/ModalWindows/ErrorModal";

export default function AdministrationsCard({link, title, logo, content}) {

    const {t} = useTranslation();
    const [error, setError] = useState(null);
    const user = useSelector(state => state.userData.user);
    const {theme: {bgColors, borderColors, colors}} = useTheme();
    const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || []);

    // const settingPopUp = () => {
    //   setError({ text: t("be-patient"), header: t("be-patient") });
    // }
  
    return (
    <div style={{borderColor: borderColors.borderPrimary300, backgroundColor: bgColors.bgPrimary0}}
         className={`${style.card} pr-4 pl-4 pt-3`}>
            <ErrorPopup error={error} setError={setError}/>
        <div className={`d-flex align-items-center mb-4`}>
          {logo && logo}
          {/* {title === "Terminology menu" ?  */}
          <NavLink to={link} style={{color: colors.colorPrimary800}} className={`m-0 ${style.title}`}>
            {t(title)}
          </NavLink> 
           {/* :
           <div style={{color: colors.colorPrimary800}} className={`m-0 ${style.title}`} onClick={settingPopUp} >
                         {t(title)}
             </div>
           } */}
        </div>
        {content &&
        content.map((c, index) =>  {
            // check for section enable
            if (enabledSections.indexOf(c.section) === -1)
                return null;
            if (c?.rights?.length && !checkUserRight(user,c.rights))
                return null;
            return (
          <div key={index} className={`d-flex align-items-center mb-2 ${style.row}`}>
            <div style={{backgroundColor: bgColors.bgBlue1000}} className={`mr-3 ${style.circle}`}/>
            {/* {title === "Terminology menu" ?  */}
            <NavLink style={{color: colors.colorPrimary1000}} to={c.link} className={`m-0`}>
                {t(c.text)}
              </NavLink>
            {/* :
            <a  style={{color: colors.colorPrimary1000}} className={`m-0`} onClick={settingPopUp} >
                  {t(c.text)} 
              </a>
            } */}
          </div>
        )})
        }
    </div>
  )
}

