import React, { useEffect, useRef, useState } from 'react'
import style from '../Universal/style.module.sass'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Rows from '../Universal/Rows'
import { useTheme } from '@material-ui/core/styles'
import EnhancedTableHead from './TableHead'
import TableFooter from '../Universal/TableFooter'
import UserProfileWrapper from '../../UserProfileWrapper/UserProfileWrapper'
import AddNew from './AddNew'
import {
    checkUserRight,
    convertFromErrorObject,
    convertLanguagesToShort,
    getTCLocaleName,
    getTreeTaggerResult, isObjectEmpty
} from '../../../../utils'
import { useSelector } from 'react-redux'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import { useTranslation } from 'react-i18next'
import Spinner from '../../../Spinner'
import { TableCell, TableRow } from '@material-ui/core'
// import AddNewTermModal from "./AddNewTermModal";
import AddNewTermModal from '../../../ModalWindows/TermModal/TermModal.jsx'
import config from '../../../../config'
import { userGetAllowLists } from '../../../../actions'
import { Redirect } from 'react-router-dom'
import { useActions } from '../../../../utils/action-helper'

function createData(
    id,
    locale_name,
    term_category_name,
    term_category_id,
    check_words,
    words,
    lemma,
    state,
    tag
) {
    return {
        id,
        locale_name,
        term_category_name,
        term_category_id,
        check_words,
        words,
        lemma,
        state,
        tag,
    }
}
// const letterRows = [
//   createData(314132, 'exception', "12", "exception", "exception words", "132"),
//   createData(45232, 'abbreviate', "3", "abbreviate", "abbreviate words", "232"),
//   createData(133342, 'color', "234", "color", "blue", "342"),
//   createData(33442, 'color', "13", "color", "blue", "442"),
//   createData(13354, 'exception', "53", "exception", "exception words", "354"),
//   createData(93245, 'color', "15", "color", "green", "245"),
//   createData(46532, 'abbreviate', "3", "abbreviate", "abbreviate words", "532"),
//   createData(1376342, 'color', "234", "color", "red", "342"),
//   createData(33482, 'color', "13", "color", "blue", "482"),
//   createData(13394, 'exception', "53", "exception", "exception words", "394"),
//   createData(933424, 'color', "15", "color", "red", "424"),
//   createData(453652, 'abbreviate', "3", "abbreviate", "abbreviate words", "652"),
//   createData(133342, 'color', "234", "color", "blue", "342"),
//   createData(33472, 'color', "13", "color", "yellow", "472"),
//   createData(1334654, 'exception', "53", "exception", "exception words", "654"),
//   createData(9327874, 'color', "15", "color", "blue", "874"),
// ];
// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }
// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }
// function stableSort(array, comparator) {
//   const stabilizedThis = array?.length && array.map((el, index) => [el, index]);
//   !!stabilizedThis &&
//     stabilizedThis.sort((a, b) => {
//       const order = comparator(a[0], b[0]);
//       if (order !== 0) return order;
//       return a[1] - b[1];
//     });
//   if (!!stabilizedThis) {
//     return stabilizedThis.map((el) => el[0]);
//   }
// }

export default function TerminologyMainTable() {
    const [addNewAllowedListModal, setAddNewAllowedListModal] = useState(false)
    const [noData, setNoData] = useState(false)
    const {
        theme: { bgColors },
    } = useTheme()
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('calories')
    const [selected, setSelected] = useState([])
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openAddNewFields] = useState(false)
    // const [terminologyModal, setTerminologyModal] = useState(false);
    const user = useSelector((state) => state.userData.user)
    const tlService = React.useContext(TLServiceComponent)
    const token = useSelector((state) => state.userData.token)
    const [letterRows, setLetterRows] = useState([])
    const forward = useRef(0)
    const [allDataCount, setAllDataCount] = useState()
    const [limitIs, setLimitIs] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [spinner, setSpinner] = useState(false)
    const [pageData, setPageData] = useState()
    const [deleteRecall, setDeleteRecall] = useState()
    // const [textValue, setTextValue] = useState("");
    const [deleteCheckedTermModal, setDeleteCheckedTermModal] = useState(false)
    const [deleteAlertMsg, setDeleteAlertMsg] = useState(false)
    const [approveCheckedTermModal, setApproveCheckedTermModal] = useState(false)
    const [approveAlertMsg, setApproveAlertMsg] = useState(false)
    const listOfError = useRef([])
    const {
        t,
        i18n: { language: i18nLanguage },
    } = useTranslation()
    const languageIs = useRef()
    const [owners, setOwners] = useState()
    const [orderByValue, setOrderByValue] = useState('id')
    const [orderASCDESC, setOrderASCDESC] = useState(0)
    const [filter, setFilter] = useState(1)
    const handleFilter = (e) => setFilter(e.target.value)
    const [currentAllowedList, setCurrentAllowedList] = useState()
    const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])
    const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})
    const allowedLists = useSelector(state => state.userData.allowedLists || [])


    /************************************* ALLOWED LIST ****************************************/
    const { actionUserGetAllowLists } = useActions({
        actionUserGetAllowLists: userGetAllowLists(tlService),
    })

    // Set Language accordingly when user lands on archive page:
    useEffect(() => {
        languageIs.current = Object.keys(backendLanguages).filter(
            (lang) => backendLanguages[lang]['shortName'] === i18nLanguage
        )
    }, [i18nLanguage])

    // Set page to 1 & offset to 0 and checked archive to unchecked when limit is changed or language is changed or archive is changed :
    useEffect(() => {
        setCurrentPage(1)
        forward.current = 0
        setSelected([])
    }, [limitIs])

    // Get the allowedlist when User open allowed list Modal:
    useEffect(() => {
        // Call this only on Admin Page:
        actionUserGetAllowLists(token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const pageSetter = async (val) => {
        setCurrentPage(val)
        forward.current = val * limitIs - 10
        await callEndPoint()
    }

    const callEndPoint = async (val) => {
        if (isObjectEmpty(allowedLists)){
            return false
        }
        setSpinner(true)

        switch (val) {
            case 0:
                forward.current = forward.current - limitIs
                if (selected.length !== 0) {
                    setSelected([])
                }
                break
            case 1:
                forward.current = forward.current + limitIs
                if (selected.length !== 0) {
                    setSelected([])
                }
                break
            default:
                // forward.current = forward.current;
                break
        }

        let terms
        let filterIs

        if (val === 'delete') {
            forward.current = 0
            filterIs = {
                offset: forward.current,
                order_by: orderByValue,
                order: orderASCDESC,
                state: 0
            }
        } else {
            filterIs = {
                offset: forward.current,
                order_by: orderByValue,
                order: orderASCDESC,
                state: 0

            }
        }
        try {
            terms = await tlService.getFilteredAllowedList(
                token,
                { limit: limitIs },
                filterIs
            )

            setSpinner(false)
            setNoData(false)
        } catch (e) {
            console.log('error', e)
            setSpinner(false)
            setPageData('')
            setNoData(true)
            switch (val) {
                case 0:
                    forward.current = forward.current + limitIs
                    break
                case 1:
                    forward.current = forward.current - limitIs
                    break
                default:
                    // forward.current = forward.current;
                    break
            }
            setLetterRows('')
            // Setting Total page to 0 if no data found:
            setAllDataCount(0)
            // setting current input page no. to 1 and all data to empty as it will be 1 as well
            if (val !== 0 && val !== 1 && !!val) {
                setCurrentPage(1)
                // setAllData('');
            }
        }

        if (terms?.fullcount) {
            setAllDataCount(terms.fullcount)
        }
        if (terms?.data) {
            //  setUserIds
            let aryOfOwner = []
            // set of user id to check for user right:
            terms.data.map((el) => {
                aryOfOwner.push(el.owner)
            })
            setOwners(aryOfOwner)
            setPageData(terms?.data)
        }
        if (terms?.data) {
            let arry = []
            terms?.data.map((el) => {
                if (el !== undefined && el?.id) {
                    const list = allowedLists.find(v=> v.id = el.term_category_id)
                    const localeName = list ? convertLanguagesToShort(list.locale_name) : convertLanguagesToShort(i18nLanguage)
                    arry.push(
                        createData(
                            el.id,
                            localeName,
                            el.term_category_name,
                            el.term_category_id,
                            el.check_words,
                            el.words,
                            el.lemma,
                            el.state,
                            el.tag
                        )
                    )
                }
            })
            setLetterRows([...arry])

            let pageNumber = Math.ceil(allDataCount / limitIs)
            // When user delete, it should go to first page:
            if (val === 'delete') {
                setCurrentPage(1)
            }
            if (forward.current >= 0) {
                let currentPageIs
                if (currentPage < pageNumber) {
                    if (val === 0) {
                        currentPageIs = Math.floor(forward.current / limitIs - 1)
                        if (currentPageIs <= 0) {
                            setCurrentPage(1)
                        } else {
                            if (currentPageIs > 0) {
                                setCurrentPage(currentPageIs)
                            }
                        }
                    } else if (val === 1) {
                        currentPageIs = Math.floor(forward.current / limitIs + 1)
                        if (currentPageIs > 0) {
                            setCurrentPage(currentPageIs)
                        }
                    }
                }
                // here currently when user page no. input and total page is name, this func run to decrease the page no. :
                if (val === 0) {
                    // currentPageIs = Math.floor(forward.current / limitIs);
                    currentPageIs = currentPage - 1
                    if (currentPageIs > 0) {
                        setCurrentPage(currentPageIs)
                    }
                }
            }
            // to clean the rows for next page filter by id
            // setRows([]);
        }
    }

    // Consecutive call!
    useEffect(() => {
        callEndPoint()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowedLists, limitIs, deleteRecall, orderBy, orderASCDESC])

    // const handleRequestSort = (event, property) => {
    //   const isAsc = orderBy === property && order === 'asc';
    //   setOrder(isAsc ? 'desc' : 'asc');
    //   setOrderBy(property);
    //   setPage(0)
    //   setRowsPerPage(10)
    // };

    const handleRequestSort = (event, property, orderBy_value) => {
        // const isAsc = orderBy === property && order === 'asc';
        // setOrder(isAsc ? 'desc' : 'asc');
        // setPage(0);
        // setRowsPerPage(10);
        setOrderBy(property)
        setOrderByValue(orderBy_value)
        if (orderASCDESC === 1) {
            setOrder('asc')
            setOrderASCDESC(0)
        } else {
            setOrder('desc')
            setOrderASCDESC(1)
        }
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = letterRows.map((n) => n.id)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = []
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }
        setSelected(newSelected)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    // DELETE multiple allowlist:
    const deleteChecked = async () => {
        const elementsId = letterRows?.length
            ? letterRows.map((element) =>
                selected.includes(element.id) ? element.id : null
            )
            : []
        setSpinner(true)
        if (filter === 1) {
            for (let element of elementsId) {
                try {
                    if (!!element) {
                        await tlService.deleteAllowedList(token, element)
                        setDeleteCheckedTermModal(false)
                    }
                } catch (e) {
                    setDeleteAlertMsg(convertFromErrorObject(t, e))
                    console.log('error', convertFromErrorObject(t, e))
                    break
                }
            }
        } else if (filter === 2) {
            for (let element of letterRows) {
                try {
                    if (!!element) {
                        await tlService.deleteAllowedList(token, element.id)
                        setDeleteCheckedTermModal(false)
                    }
                } catch (e) {
                    setDeleteAlertMsg(convertFromErrorObject(t, e))
                    console.log('error', convertFromErrorObject(t, e))
                    break
                }
            }
        }
        setSelected([])
        // try to reduce offset by limit amount if delete whole data on one page:
        if (selected?.length === letterRows?.length || filter === 2) {
            forward.current = forward.current - limitIs
            setCurrentPage(currentPage - 1)
            // if the offset is negative than make it 0:
            if (Math.sign(forward.current) === -1) {
                forward.current = 0
            }
        }
        callEndPoint()
    }

    // APPROVE multiple allowlist:
    const approveChecked = async () => {
        const elementIs = letterRows?.length
            ? letterRows.map((element) =>
                selected.includes(element.id) ? element : null
            )
            : []
        // console.log('selected is', elementIs, letterRows, selected);
        setSpinner(true)
        if (filter === 1) {
            for (let element of elementIs) {
                if (element !== null) {
                    await approving(element)
                    try {
                        await tlService.calculateCategory(token, element.term_category_id)
                        await tlService.clearAllCache(token, 1, parseInt(element.term_category_id))
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        } else if (filter === 2) {
            for (let element of letterRows) {
                if (element !== null) {
                    await approving(element)
                    try {
                        await tlService.calculateCategory(token, element.term_category_id)
                        await tlService.clearAllCache(token, 1, parseInt(element.term_category_id))
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        }
        setSelected([])
        // try to reduce offset by limit amount if delete whole data on one page:
        if (selected?.length === letterRows?.length || filter === 2) {
            forward.current = forward.current - limitIs
            setCurrentPage(currentPage - 1)
            // if the offset is negative than make it 0:
            if (Math.sign(forward.current) === -1) {
                forward.current = 0
            }
        }
        setSpinner(false)
        if (!listOfError.current?.length) {
            setApproveCheckedTermModal(false)
        }
    }

    const approving = async (element) => {

        if (element === undefined || element === null) {
            return false
        }

        const categoryLocaleName = getTCLocaleName(tlService, token, element.term_category_id)
        if (!categoryLocaleName) {
            listOfError.current = [...listOfError.current, 'Wrong TC id']
            setDeleteAlertMsg(t('Wrong TC id'))
            setTimeout(() => {
                setDeleteAlertMsg('')
            }, config.timeOutDuration)
            return false
        }

        listOfError.current = listOfError.current.filter(el => el !== undefined)
        if (!!categoryLocaleName) {
            let res
            try {
                res = await tlService.addTerm(
                    token,
                    element.term_category_id,
                    element.words,
                    element.lemma,
                    element.tag,
                    element.check_words,
                    {},
                    element.description
                )
            } catch (e) {
                let errorIs = `${t('Allowed list')} ${(element.term_category_name[i18nLanguage])} : ${convertFromErrorObject(t, e)}`
                listOfError.current = [...listOfError.current, errorIs]

                console.log('error ' + convertFromErrorObject(t, e))
                // setErrorModal(true)
                setDeleteAlertMsg(convertFromErrorObject(t, e))
                // setErrorIs(convertFromErrorObject(t, e));
                setTimeout(() => {
                    setDeleteAlertMsg('')
                }, config.timeOutDuration)
                return false
            }
            let arrayIs = element.replacements
            if (arrayIs?.length && res?.data) {
                for (const element of arrayIs) {
                    const tokenizeTerm = await getTreeTaggerResult(tlService, token, categoryLocaleName, element)
                    if (!tokenizeTerm?.error) {
                        try {
                            await tlService.addTermReplacement(token, res.data.id, element.term_category_id,
                                tokenizeTerm.word, tokenizeTerm.lemma, tokenizeTerm.tag, element.description, 1)
                        } catch (e) {
                            console.log('error ' + convertFromErrorObject(t, e))
                            return false
                        }
                    } else {
                        console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                        return false
                    }
                }
            }
            try {
                await tlService.updateWhitelist(
                    token,
                    element.id,
                    1
                )
                // Recall Endpoint :
                callEndPoint()
            } catch (e) {
                console.log('error ' + convertFromErrorObject(t, e))
                setDeleteAlertMsg(convertFromErrorObject(t, e))
                setTimeout(() => {
                    setDeleteAlertMsg('')
                }, config.timeOutDuration)
                return false
            }
        }
        return true
    }

    //check for enabled section
    if (enabledSections.indexOf('allowedlist') === -1)
        return <Redirect to={'/'}/>

    // check for  user right
    if (!checkUserRight(user, [701, 702, 703])) return null

    return (
        <UserProfileWrapper>
            <div className={`${style.mainCont}`}>
                {spinner && <Spinner/>}
                <Paper className={``}>
                    <AddNewTermModal
                        component="AdminAllowedlist"
                        addAPI={tlService.addAllowedList}
                        editAPI={tlService.editAllowedList}
                        setDeleteRecall={setDeleteRecall}
                        currentAllowedList={currentAllowedList}
                        open={addNewAllowedListModal}
                        setOpen={setAddNewAllowedListModal}
                    />
                    <TableContainer>
                        <Table
                            style={{
                                backgroundColor: bgColors.bgPrimary100,
                                borderColor: 'transparent',
                            }}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            {checkUserRight(user, [701, 702, 703]) && (
                                <AddNew
                                    // setTerminologyModal={setTerminologyModal}
                                    setAddNewAllowedListModal={setAddNewAllowedListModal}
                                    setCurrentAllowedList={setCurrentAllowedList}
                                />
                            )}
                            {/* <TableFilter/> */}
                            <EnhancedTableHead
                                listOfError={listOfError}
                                setApproveCheckedTermModal={setApproveCheckedTermModal}
                                approveCheckedTermModal={approveCheckedTermModal}
                                approveAlertMsg={approveAlertMsg}
                                setApproveAlertMsg={setApproveAlertMsg}
                                approveChecked={approveChecked}
                                deleteAlertMsg={deleteAlertMsg}
                                deleteCheckedTermModal={deleteCheckedTermModal}
                                setDeleteCheckedTermModal={setDeleteCheckedTermModal}
                                deleteChecked={deleteChecked}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                openAddNewFields={openAddNewFields}
                                rowCount={letterRows.length}
                            />
                            <TableBody>
                                {letterRows?.length
                                    ? letterRows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id)
                                        const labelId = `enhanced-table-checkbox-${index}`
                                        return (
                                            <Rows
                                                deleteAPI={tlService.deleteAllowedList}
                                                updateAPI={tlService.updateWhitelist}
                                                key={index}
                                                handleClick={handleClick}
                                                isItemSelected={isItemSelected}
                                                labelId={labelId}
                                                row={row}
                                                isEven={index % 2 === 0}
                                                setDeleteRecall={setDeleteRecall}
                                                owners={owners}
                                                setSelected={setSelected}
                                                setAddNewAllowedListModal={setAddNewAllowedListModal}
                                                currentAllowedList={currentAllowedList}
                                                setCurrentAllowedList={setCurrentAllowedList}
                                                callEndPointReCall={callEndPoint}
                                            />
                                        )
                                    })
                                    : noData && (
                                    <TableRow>
                                        <TableCell
                                            colSpan="10"
                                            style={{
                                                height: '400px',
                                                textAlign: 'center',
                                                marginTop: '140px',
                                            }}
                                        >
                                            <h2> {t('no-data')} </h2>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter
                                offsetIs={forward.current}
                                handleFilter={handleFilter}
                                allDataCount={allDataCount}
                                pageSetter={pageSetter}
                                currentPage={currentPage}
                                pageData={pageData}
                                callEndPointForward={callEndPoint}
                                callEndPointBackward={callEndPoint}
                                setLimitIs={setLimitIs}
                                filter={filter}
                                selected={selected}
                            />
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </UserProfileWrapper>
    )
}
