import React, {useEffect, useRef, useState} from "react";
import {TextField} from "@material-ui/core";

export default function Textarea({formik, label, name, type, rows = 5, ...rest}) {
  const [open, setOpen] = useState(false)
  const node = useRef(null)

  useEffect(() => {
    document.addEventListener("mousedown", handleOnClick, false)
    return document.addEventListener("mousedown", handleOnClick, false)
  }, [node])

  function handleOnClick(e) {
    if (node?.current?.contains(e.target)) {
      setOpen(true)
      return
    }
    setOpen(false)
  }

  return (
    formik ?
      <TextField
        ref={node}
        fullWidth
        name={name}
        type={type ? type : "text"}
        {...rest}
        label={label}
        variant="outlined"
        multiline
        onClick={() => setOpen(true)}
        value={formik.values[name]}
        onChange={formik.handleChange}
        rows={open ? rows : 1}
        style={{transition: ".3s easy-in"}}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
      /> :
      <TextField
        ref={node}
        fullWidth
        label={label}
        multiline
        rows={open ? rows : 1}
        variant="outlined"
        style={{transition: ".3s easy-in"}}
        onClick={() => setOpen(true)}
      />
  )
}