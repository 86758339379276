import React, {useEffect, useState} from "react";
import style from "./style.module.sass"
import {useTheme} from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import {IconButton} from "@material-ui/core";
import { useTranslation } from "react-i18next";

function FileUpload({formik, name, label}) {
  const {theme: {bgColors, colors}} = useTheme();
  const [value, setValue] = useState(null)
  const [load, setLoad] = useState(false)
  const [src, setSrc] = useState(null)
  const { t } = useTranslation();

  function handleValue(e) {
    setLoad(true)
    setTimeout(() => {
      setValue(e.target.files[0]);
      formik.setFieldValue(name, e.target.files[0])
    }, 1000)
  }

  function handleDelete() {
    setLoad(true)
    setTimeout(() => {
      setValue(null);
      formik.setFieldValue(name, null)
    }, 300)
  }

  useEffect(() => {
    value ? setSrc(URL.createObjectURL(value)) : setSrc(null)
    setLoad(false)
  }, [value])

  return <div className={`${style.fileUpload} pr-4`}>
    <input name={name} id="333333" onChange={e => handleValue(e)} type="file" className={`d-none`}/>
    <label htmlFor="333333" style={{backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0}}
           className={`pr-4 pl-4 pt-1 pb-1 m-0 mr-3`}>
      {label}
    </label>
    <div>
      {load &&
      <div className={style.progress}>
        <div className={style.progressValue}/>
      </div>}
      {!load && src && <img src={src} alt=""/>}
      {!load && src && <IconButton onClick={handleDelete} className={`ml-2`} size="small" color="primary"><DeleteIcon fontSize="small"/></IconButton>}
      {!src && !load && <p className={`m-0`}>
        {value?.name ? value?.name.length > 30 ? value.name.substring(0, 29) + "..." : value.name : t("Accepted formats JPG, PNG, GIF. Resolution 20x20 px (will be scaled automatically)")}
      </p>}
    </div>
  </div>
}

export default FileUpload;
