import { isObjectEmpty } from '../utils'

export const renewUser = (tlService) => (token, remember, ttl = 0) => (dispatch) => {
  tlService.renewUser(token, ttl)
    .then((data) => {
      if (remember) {
        localStorage.setItem('token', data.token)
      }
      dispatch({ type: 'FETCH_USER_RENEW_SUCCESS', payload: data })
    })
    .catch((error) => dispatch({ type: 'FETCH_USER_RENEW_FAILURE', payload: error }))
}

export const userGetNNPaths = (tlService) => (token) => (dispatch) => {
  tlService.getNNPaths(token)
    .then((data) => dispatch({
      type: 'FETCH_NN_PATHS_SUCCESS',
      payload: !isObjectEmpty(data) ? data : {}
    }))
    .catch((err) => dispatch({
      type: 'FETCH_NN_PATHS_FAILURE',
      payload: err
    }))
}

//FETCH_ALLOW_LIST_SUCCESS
export const userGetAllowLists = (tlService) => (token, language) => (dispatch) => {
  tlService.getFilteredTermCategories(token, {}, { category_type: 'allowedlist', locale_name: language })
    .then((data) => dispatch({
      type: 'FETCH_ALLOW_LIST_SUCCESS',
      payload: data.data !== undefined && data.data.length > 0 ? data.data : []
    }))
    .catch((err) => dispatch({
      type: 'FETCH_ALLOW_LIST_FAILURE',
      payload: err
    }))
}

export const userGetTextTone = (tlService) => (token, language) => (dispatch) => {
  tlService.getFilteredTermCategories(token, {}, { category_type: 'negative', locale_name: language })
    .then((data) => dispatch({
      type: 'FETCH_TEXT_TONE_SUCCESS',
      payload: data.data !== undefined && data.data.length > 0 ? data.data : []
    }))
    .catch((err) => dispatch({
      type: 'FETCH_TEXT_TONE_FAILURE',
      payload: err
    }))
}

export const userGetAllTermCategories = (tlService) => (token) => (dispatch) => {
  dispatch({
    type: 'FETCH_TERM_CATEGORIES_REQUEST',
  })
  tlService.getFilteredTermCategories(token)
    .then((data) => dispatch({
      type: 'FETCH_TERM_CATEGORIES_SUCCESS',
      payload: data.data !== undefined && data.data.length > 0 ? data.data : []
    }))
    .catch((err) => dispatch({
      type: 'FETCH_TERM_CATEGORIES_FAILURE',
      payload: err
    }))
}

export const userSetOption = (optionName, optionValue) => {
  return {
    type: 'USER_SET_OPTION',
    payload: [optionName, optionValue]
  }
}

export const userSaveOption = (tlService) => (token, id, optionName, optionValue) => (dispatch) => {
  dispatch({
    type: 'FETCH_USER_SET_OPTION_REQUEST'
  })
  let error = undefined
  tlService.deleteUserOption(token, id, optionName)
    .catch(err => error = err)
    .finally(() => {
      tlService.addUserOption(token, id, optionName, optionValue)
        .then(() => dispatch({
          type: 'FETCH_USER_SET_OPTION_SUCCESS',
          payload: [optionName, optionValue]
        }))
        .catch(err => dispatch({
          type: 'FETCH_USER_SET_OPTION_FAILURE',
          payload: [err, error]
        }))
    })
}

export const userSetToken = (token) => {
  return {
    type: 'USER_SET_TOKEN',
    payload: token
  }
}

export const userSetArchiveLanguage = (data) => {
  return {
    type: 'USER_SET_ARCHIVE_LANGUAGE',
    payload: data
  }
}


export const userSetTheme = (theme) => {
  return {
    type: 'USER_SET_THEME',
    payload: theme
  }
}

export const userSetAlertState = () => (alert) => (dispatch) => {
  dispatch({
    type: 'USER_SET_ALERT_STATE',
    payload: !!alert
  })
}

const userInternalSetUser = (tlService, i18n, data, remember, dispatch) => {
  if (remember) {
    localStorage.setItem('token', data.token)
  }
  dispatch({ type: 'FETCH_USER_LOGIN_SUCCESS', payload: data })
}

export const userSetUser = (tlService, i18n) => (data, remember) => (dispatch) => {
  userInternalSetUser(tlService, i18n, data, remember, dispatch)
}

export const loginUserLDAP = (tlService, i18n) => (serviceId, remember) => (dispatch) => {
  dispatch({ type: 'FETCH_USER_LOGIN_REQUEST' })
  tlService.loginUserLDAP(serviceId)
    .then(async data => {
      userInternalSetUser(tlService, i18n, data, remember, dispatch)
    })
    .catch((error) => {
      localStorage.removeItem('token')
      dispatch({ type: 'FETCH_USER_LOGIN_FAILURE', payload: error })
    })
}

export const loginUser = (tlService, i18n) => (login, password, remember) => (dispatch) => {
  dispatch({ type: 'FETCH_USER_LOGIN_REQUEST' })
  tlService.loginUser(login, password)
    .then(async data => {
      userInternalSetUser(tlService, i18n, data, remember, dispatch)
    })
    .catch((error) => {
      localStorage.removeItem('token')
      dispatch({ type: 'FETCH_USER_LOGIN_FAILURE', payload: error })
    })
}

export const signupUser = (tlService) => (state) => (dispatch) => {
  dispatch({ type: 'FETCH_SIGNUP_REQUEST' })
  tlService.signupUser(state)
    .then((data) => {
      //localStorage.setItem('token', data.jwt);
      dispatch({ type: 'FETCH_SIGNUP_SUCCESS', payload: data.user })
    })
    .catch((error) => dispatch({ type: 'FETCH_SIGNUP_FAILURE', payload: error }))
}

export const meUser = (tlService) => (token) => (dispatch) => {
  dispatch({ type: 'FETCH_MEUSER_REQUEST' })
  tlService.meUser(token)
    .then((data) => dispatch({ type: 'FETCH_MEUSER_SUCCESS', payload: data }))
    .catch((error) => {
      localStorage.removeItem('token')
      dispatch({ type: 'FETCH_MEUSER_FAILURE', payload: error })
    })
}

export const forgotPasswordUser = (tlService) => (data) => (dispatch) => {
  dispatch({ type: 'FETCH_FORGOTPASSWORD_REQUEST' })
  tlService.forgotPasswordUser(data)
    .then((data) => dispatch({ type: 'FETCH_FORGOTPASSWORD_SUCCESS', payload: data }))
    .catch((error) => dispatch({ type: 'FETCH_FORGOTPASSWORD_FAILURE', payload: error }))
}

export const clearUser = () => () => (dispatch) => {
  dispatch({ type: 'USER_CLEAR' })
}

export const messagesUser = (tlService) => (token, user) => (dispatch) => {
  dispatch({ type: 'FETCH_MESSAGES_REQUEST' })
  tlService.getAllMessages(token, user)
    .then((data) => dispatch({
      type: 'FETCH_MESSAGES_SUCCESS',
      payload: data.data !== undefined && data.data.length > 0 ? data.data : []
    }))
    .catch((error) => dispatch({ type: 'FETCH_MESSAGES_FAILURE', payload: error }))
}

export const userGetAllBenchmarks = (tlService) => (token) => (dispatch) => {
  dispatch({ type: 'FETCH_ALL_BENCHMARKS_REQUEST' })
  tlService.getBenchmarks(token)
    .then((data) => dispatch({
      type: 'FETCH_ALL_BENCHMARKS_SUCCESS',
      payload: data.data !== undefined && data.data.length > 0 ? data.data : []
    }))
    .catch((error) => dispatch({ type: 'FETCH_ALL_BENCHMARKS_FAILURE', payload: error }))
}

export const userUpdateUser = (tlService) => (token, id, newUserData = {}) => (dispatch) => {
  dispatch({
    type: 'FETCH_USER_UPDATE_REQUEST'
  })
  tlService.updateUser(token, id, newUserData)
    .catch(err => dispatch({
      type: 'FETCH_USER_UPDATE_FAILURE',
      payload: err
    }))
    .then(() => dispatch({
      type: 'FETCH_USER_UPDATE_SUCCESS',
      payload: newUserData
    }))
}