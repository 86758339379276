import React, {useState} from "react";
import style from "../style.module.sass"
import {useTheme} from "@material-ui/core/styles";
import TypeOfField from "../../../../../../components/FormikFields/TypeOfField";
import {Button, Collapse} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useTranslation } from "react-i18next";
  
  function UserRights({formik}) {
  const { t } = useTranslation();
  const [bg, setBg] = useState(false)
  const [status, setStatus] = useState("none")
  const [collapse, setCollapse] = useState(true)
  const {theme: {bgColors, colors}} = useTheme();

  // switches arrays for groupOfFields
  const generalUserRights = [
    {
      id: 1,
      control: "switch",
      labelPlacementEnd: true,
      name: "mustChangePassLogin",
      label: t("Must change password after the next login")
    },
  ];
  const superAdminRights = [
    {
      id: 1,
      control: "switch", labelPlacementEnd: true,
      name: "mayAdministrateTerms",
      label: t("May administrate terms & conditions")
    },
    {
      id: 2,
      control: "switch",
      labelPlacementEnd: true,
      name: "viewAnalysisResults",
      label: t("May view analysis results of the group")
    },
    {
      id: 3,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayAdminBasicVocabulary",
      label: t("May administrate basic vocabulary and the respective collection basin")
    },
    {
      id: 4,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayAdminGeneralTerminology",
      label: t("May administrate general terminology lists")
    },
    {
      id: 5,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayEditBenchmark",
      label: t("May edit benchmark values")
    },
    {
      id: 6,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayAdminUserRights",
      label: t("May administrate user rights")
    },
    {
      id: 7,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayViewLogs",
      label: t("May view logs")
    },
    {
      id: 8,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayCRUDBlockUsers",
      label: t("May add, delete, rename, block users")
    },
    {
      id: 9,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayCRUDUserGroup",
      label: t("May add new user groups, may rename and delete any user group")
    },
    {
      id: 10,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayAdminControlList",
      label: t("May administrate control lists")
    },
  ];
  const adminUserRights = [
    {
      id: 1,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayUpdateAGroupRes",
      label: t("May view analysis results of the group")
    },
    {
      id: 2,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayUpdateAGroupText",
      label: t("May view analysed texts of the group")
    },
    {
      id: 3,
      control: "switch", labelPlacementEnd: true,
      name: "mayCRUDUsers",
      label: t("May add, edit or delete users")
    },
    {
      id: 4,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayAdminWhiteList",
      label: t("May administrate the white list of the group")
    },
    {
      id: 5,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayAddListAndAssignUsers",
      label: t("May add new lists and assign them to users of the group")
    },
    {
      id: 6,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayAdjustTextTypesSettings",
      label: t("May adjust settings of text types")
    },
    {
      id: 7,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayEditSentimentList",
      label: t("May edit sentiment list")
    },
    {
      id: 8,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayEditTerminologyList",
      label: t("May edit terminology lists")
    },
    {
      id: 9,
      control: "switch",
      labelPlacementEnd: true,
      name: "headerLineFooterLine",
      label: t("Header line / footer line")
    },
    {
      id: 10,
      control: "switch",
      labelPlacementEnd: true,
      name: "mayEditBesPractice",
      label: t("May edit best practice")
    },
  ];

  // User rights switches groups
  const groupOfFields = [
    {id: 3, title: t("general"), options: generalUserRights},
    {id: 1, title: t("Admin"), options: adminUserRights},
    {id: 2, title: t("Super-admin"), options: superAdminRights},
  ];

  return (
    <div style={{backgroundColor: !bg ? bgColors.bgPrimary0 : bgColors.bgBlue50}}
         onMouseEnter={() => setBg(true)} onMouseLeave={() => setBg(false)}>
      <div className={`align-items-center d-flex justify-content-between pr-4`}
           style={{color: colors.colorPrimary0, backgroundColor: bgColors.bgPrimary500}}>
        <h4 className={style.title}>{t('user-rights')}</h4>
        <div>
          <Button onMouseEnter={() => setStatus(status !== "group" ? "hover" : "group")}
                  onMouseLeave={() => setStatus(status !== "group" ? "none" : "group")}
                  onClick={() => setStatus("group")}
                  variant={'contained'} color={status === "hover" || status === "group" ? "primary" : "default"}
                  className={`${style.benchmarks_btn} mr-1`}>{t('Company Default')}</Button>
          <button className={`ml-2 p-0`} onClick={(e) => {
            e.preventDefault()
            setCollapse(!collapse)
          }}>
            {!collapse ? <AddIcon style={{fill: "white"}} fontSize="small"/> :
              <RemoveIcon style={{fill: "white"}} fontSize="small"/>}
          </button>
        </div>
      </div>
      <Collapse in={collapse}>
        {
          groupOfFields.map(
            (f, indexIs) => (
              <span key={indexIs}>
                <div style={{backgroundColor: bgColors.bgPrimary400}}
                     className={`${style.subTitle} d-flex align-items-center justify-content-between`}>
                  <p
                    style={{color: colors.colorPrimary0}} className={`m-0`}>{f.title}</p>
                </div>
                {status === "group" || status === "hover" ?
                  <ul className={`pl-5 pb-2 p-0 mb-0`}>
                    {
                      f.options.map(
                        (o, index) => (
                          <li className={``} key={index}>
                            <TypeOfField control={o.control} formik={formik} name={o.name}
                                         label={o.label}
                                         status={status === "group" || status === "hover" ? "group" : null}
                                         setAutoFillState={setStatus} {...o}/>
                          </li>
                        )
                      )
                    }
                  </ul> :
                  <ul className={`pl-5 pb-2 p-0 mb-0`}>
                    {
                      f.options.map(
                       (o, index) => (
                          <li className={``} key={index}>
                            <TypeOfField control={o.control} formik={formik} name={o.name}
                                         label={o.label} {...o}/>
                          </li>
                        )
                      )
                    }
                  </ul>
                }
              </span>
            )
          )
        }
      </Collapse>
    </div>
  )
}

export default UserRights;
