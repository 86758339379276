import style from "./style.module.scss";
import React, { useMemo } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { roundWithPrecision } from "../../../utils";

export default function VocabularyCircleChart() {
  const { theme: { bgColors } } = useTheme();
  const { t } = useTranslation();
  const currentBenchmarkSettings = useSelector(state => state.analyseData.result.benchmark);
  const resultData = useSelector(state => state.analyseData.result.resultData);

  const allColors = {
    colorGreen: "green",
    colorYellow: "yellow",
    colorBlue: "blue",
    colorRed: "red"
  }

  const cells = useMemo(() => {
    let maxPercent = 0;
    let retVal = Object.entries(allColors)
      .reduce((acc, [valueName, colorName]) => {
        const localTarget = currentBenchmarkSettings?.settings[valueName]?.values?.target || -100;
        const localPercent = resultData[valueName] / (resultData.countWords / 100);
        maxPercent = localPercent > maxPercent ? localPercent : maxPercent;
        maxPercent = localTarget > maxPercent ? localTarget : maxPercent;
        return {
          ...acc,
          [colorName]: {
            localTarget,
            localPercent,
            zIndex: localTarget > localPercent ? 0 : 1,
            transparent: {
              zIndex: localTarget > localPercent ? 1 : 2,
              height: `${localTarget}%`,
              width: `${localTarget}%`
            },
            cell: {
              zIndex: localTarget > localPercent ? 2 : 1,
              height: `${localPercent}%`,
              width: `${localPercent}%`
            }
          }
        }
      }, {})
    // make it bigger
    maxPercent = maxPercent + 5;
    Object.keys(retVal).map(key => {
      const currentCell = retVal[key];
      retVal[key].transparent = {
        ...currentCell.transparent,
        height: `${currentCell.localTarget / (maxPercent / 100)}%`,
        width: `${currentCell.localTarget / (maxPercent / 100)}%`
      }
      retVal[key].cell = {
        ...currentCell.cell,
        height: `${currentCell.localPercent / (maxPercent / 100)}%`,
        width: `${currentCell.localPercent / (maxPercent / 100)}%`
      }
      return null;
    })
    return retVal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBenchmarkSettings, resultData])

  return (
    <div style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.circleCont} ${style.cells}`}>
      <div style={{ maxWidth: 440, margin: "0 auto" }}>
        <div className={`d-flex justify-content-between`}>
          <div className={style.titles}>
            <p><b style={{ color: "rgb(62, 170, 27)" }}>{t("konservativ")} </b></p>
            <p>{t("Target")} {roundWithPrecision(cells.green.localTarget)}%</p>
            <p><b>{t("Current")}: {roundWithPrecision(cells.green.localPercent)}%</b></p>
          </div>
        <div className={`${style.titles} ${style.flexEnd}`}>
            <p><b style={{ color: "#edc43b" }}>{t("innovative")} </b></p>
            <p><b>{t("Current")}: {roundWithPrecision(cells.yellow.localPercent)}%</b></p>
            <p>{t("Target")} {roundWithPrecision(cells.yellow.localTarget)}%</p>
          </div>
        </div>
        <div className={style.circle}>
          {Object.entries(cells).map(([key, val]) => {
            if (val.zIndex === 1)
              return (
                <div key={key} className={style[key]}>
                  <div className={style.cell} style={val.cell} />
                  <div className={style.transparent} style={val.transparent} />
                </div>
              )
            else
              return (
                <div key={key} className={style[key]}>
                  <div className={style.transparent} style={val.transparent} />
                  <div className={style.cell} style={val.cell} />
                </div>
              )
          })}
        </div>
        <div className={`d-flex justify-content-between`}>
          <div className={style.titles}>
            <p>{t("Target")} {roundWithPrecision(cells.blue.localTarget)}%</p>
            <p><b>{t("Current")}: {roundWithPrecision(cells.blue.localPercent)}%</b></p>
            <p><b style={{ color: "rgb(65, 92, 152)" }}>{t("rational")} </b></p>
          </div>
          <div className={`${style.titles} ${style.flexEnd}`}>
            <p><b>{t("Current")}: {roundWithPrecision(cells.red.localPercent)}%</b></p>
            <p>{t("Target")} {roundWithPrecision(cells.red.localTarget)}%</p>
            <p><b style={{ color: "rgb(167, 3, 3)" }}>{t("emotional")} </b></p>
          </div>
        </div>
      </div>
    </div>
  )
}
