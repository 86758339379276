import { useTheme } from '@material-ui/core/styles'
import TableHead from '@material-ui/core/TableHead'
import style from './style.module.sass'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import React, { useState, useEffect, Fragment } from 'react'
import deleteIcon from '../assets/deleteIcon.svg'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { useTranslation } from 'react-i18next'
import { Collapse, Modal, Box, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import closeIcon from '../../../../assets/close-circle.svg'
import { useSelector } from 'react-redux'
import config from '../../../../config'
import { MoreVert } from '@material-ui/icons'
import { IconButton, Menu, MenuItem } from '@material-ui/core'

// const headCells = [
//   { id: 'id', numeric: false, disablePadding: true, label: 'id', orderBy_value: 'id' },
//   // {id: 'parent_id', numeric: false, disablePadding: false, label: 'parent_id', orderBy_value: 'parent_id'},
//   {id: 'category_type', numeric: false, disablePadding: false, label: 'category_type', orderBy_value: 'category_type'},
//   {id: 'language', numeric: false, disablePadding: false, label: 'language', orderBy_value: 'locale_name'},
//   {id: 'made_for', numeric: false, disablePadding: false, label: 'made_for', orderBy_value: 'made_for'},
//   { id: 'name', numeric: false, disablePadding: false, label: 'name', orderBy_value: 'name' },
//   { id: 'itemCount', numeric: false, disablePadding: false, label: 'count-of-items', orderBy_value: 'term_count' },
//   // { id: 'edit', numeric: false, disablePadding: false, label: 'editMissing' },
// ];

function EnhancedTableHead ({
  onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
  deleteAlertMsg,
  deleteCheckedTermModal,
  setDeleteCheckedTermModal,
  deleteChecked,
  handleSetDisabledColumns,
}) {

  const { theme: { bgColors, colors } } = useTheme()
  const { t } = useTranslation()
  const [showError, setShowError] = useState(false)
  const [deleteMsg, setDeleteMsg] = useState()

  const createSortHandler = (property, orderBy_value) => (event) => {
      // Currently only in Deutsch so language have de:
      // if (orderBy_value === "name") {
      //   onRequestSort(event, property, `de${orderBy_value}`);
      // } else {
      // for number, no need to language add:
      onRequestSort(event, property, orderBy_value)
      // }
  }

  /******** Redux *********/
    // const userArchives = useSelector(state => state.userData.user.archives);
  const userType = useSelector(state => state.userData.user.user_type_id)
  const columns = useSelector(state => state.tableData.columns)

  useEffect(() => {
    setDeleteMsg(deleteAlertMsg)
    setTimeout(() => {
      setShowError(false)
      setDeleteMsg('')
    }, config.timeOutDuration)
    return () => {
      setShowError(false)
      setDeleteMsg('')
    }
  }, [deleteAlertMsg, showError])

  const [menuPosition, setMenuPosition] = useState(null)

  const handleRightClick = (event) => {
    if (menuPosition) {
      return
    }
    event.preventDefault()
    event.stopPropagation()
    setMenuPosition({
      top: event.clientY,
      left: event.clientX
    })
  }

  return (
    <>
      <Modal open={deleteCheckedTermModal} onClose={() => setDeleteCheckedTermModal(false)}
             style={{ width: '50%', margin: '0 auto', marginTop: '20%', height: '300px' }}>
        <div className={`${style.modalMainCont} pb-3`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
          <div className={`${style.head} d-flex justify-content-between p-1 pl-3`}
               style={{ backgroundColor: bgColors.bgPrimary400 }}>
            <p className={`m-0 ${style.title}`} style={{ color: 'white', fontSize: '25px' }}>
              {t('clear-terms')}
            </p>
            <button className={`border-0 bg-transparent`} onClick={() => setDeleteCheckedTermModal(false)}>
              <img src={closeIcon} alt=""/>
            </button>
          </div>
          <br/>
          <div className={style.error}>
            <Collapse in={!!deleteMsg}>
              <Alert
                className={`m-0`}
                severity="error">
                <h6 className={`m-0`}>
                  {t('delete-error-msg')}
                </h6>
              </Alert>
            </Collapse>
          </div>
          <div>
            <Button color="primary" variant="contained"
                    onClick={() => {
                      deleteChecked()
                      setShowError(true)
                    }
                    }
                    style={{ marginLeft: '40%', marginTop: '4%' }}>
              {t('yes')}
            </Button>
            <Button color="primary" variant="contained"
                    onClick={() => { setDeleteCheckedTermModal(false) }}
                    style={{ marginLeft: '4%', marginTop: '4%' }}>
              {t('no')}
            </Button>
          </div>
        </div>
      </Modal>
      <TableHead style={{ backgroundColor: bgColors.bgBlue300 }} className={`${style.filterDashboard}`}>
        <TableRow>
          <TableCell colSpan={12} padding="checkbox">
            <Collapse in={!!numSelected} timeout="auto" unmountOnExit>
              <Box className={`${style.selectRow} pl-3 pr-3 d-flex align-items-center`} margin={1}>
                <p style={{ color: colors.colorPrimary1000 }} className={`m-0`}>{numSelected} {t('selected')}</p>
                <button className={`${style.delete} pr-0 pl-0`}
                        style={{ backgroundColor: bgColors.bgBlue1000 }}
                        onClick={() => setDeleteCheckedTermModal(true)}
                >
                  <img src={deleteIcon} alt=""/>
                </button>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableHead style={{ backgroundColor: bgColors.bgPrimary500 }} className={`${style.tableHead}`}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {columns.map((headCell) => (
            !headCell.columnDisabled && <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                style={{ flexDirection: 'row' }}
                onClick={createSortHandler(headCell.id, headCell.orderBy_value)}
                IconComponent={(props) => (
                  <ArrowDownwardIcon style={{ order: '9', color: colors.colorPrimary0 }} alt="" {...props} />)}
              >
                <p style={{ color: colors.colorPrimary0 }}
                   className={`m-0`}>{userType === 1 ? t(headCell.label) : headCell.label === 'parent_id' ? '' : t(headCell.label)}</p>
              </TableSortLabel>
            </TableCell>
          ))}
          <Fragment key={'arrow'}>
            <TableCell
              align={'right'}
              padding={'normal'}
              className="d-flex align-items-center justify-content-center" onClick={handleRightClick}>
              <IconButton className="ml-auto">
                <MoreVert style={{ fill: '#ccc' }}/>
              </IconButton>
              <Menu
                open={!!menuPosition}
                onClose={() => setMenuPosition(null)}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
              >
                {columns.map(({
                  id,
                  label,
                  columnDisabled
                }, index) =>
                  <MenuItem
                    key={index}
                    style={{ color: !columnDisabled ? '#415c98' : '#333' }}
                    onClick={() => handleSetDisabledColumns(id)}
                  >
                    {t(label)}
                  </MenuItem>)}
              </Menu>
            </TableCell>
          </Fragment>

        </TableRow>
      </TableHead>
    </>
  )
}

export default EnhancedTableHead
