import React, { useState, useRef, useEffect } from 'react';
import style from "./style.module.sass";
import TermsModalTabs from "./TermModalTabs";
import NearTermTabs from "./NearTermTabs";
import LowerTermsModalFields from "./LowerTermsModalFields";
import { useTranslation } from "react-i18next";
import TLServiceComponent from "../../../../contexts/TLServiceComponent";
import { useSelector } from "react-redux";
import { convertFromErrorObject, convertLanguagesToFull, convertLanguagesToShort } from '../../../../utils/index'
import { Alert } from "@material-ui/lab";
import { Button, Collapse, Modal } from "@material-ui/core";
import config from "../../../../config";

export default function TerminologyAddEditModal({
  open, currentTerm,
  setOpen, callEndPoint,
  mainBox, setMainBox,
  categoryType,
  setCategoryType,
  color, setColor,
  visibility, setVisibility,
  letterRows
}) {
  const tlService = React.useContext(TLServiceComponent);
  const token = useSelector(state => state.userData.token);
  const { t, i18n: { language: i18nLanguage } } = useTranslation();
  const [lang, setLang] = useState(i18nLanguage)
  // const [language, setLanguage] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parentId, setParentId] = useState(0);
  const [userId, setUserId] = useState([]);
  const [groupId, setGroupId] = useState([]);
  const [firmaId, setFirmaId] = useState([]);
  const languageIs = useRef();
  const [allowListDisabled, setAllowListDisabled] = useState(false);
  const [ignoreDisabled, setIgnoreDisabled] = useState(false);
  const [invisible, setInvisible] = useState(false);
  const [deleteRight, setDeleteRight] = useState([]);
  const [oldRight, setOldRight] = useState([])
  const [alertMsg, setAlertMsg] = useState();
  const [termLanguage, setTermLanguage] = useState();
  const backendLanguages = useSelector(state => state.userData.user?.backend_languages || {})

  // Set Language accordingly when user lands on archive page:
  useEffect(() => {
    let resultLanguage = Object.keys(backendLanguages).filter(lang =>
        backendLanguages[lang]['shortName'] === i18nLanguage
    )
    languageIs.current = resultLanguage
  }, [i18nLanguage])

  // ************** Setting initail value on modal **************
  useEffect(() => {
    if (!!currentTerm) {
      setTermLanguage(convertLanguagesToShort(currentTerm.locale_name))
      setDescription(currentTerm.description);
      setName(currentTerm.name)
      setParentId(currentTerm.parent_id)
      setCategoryType(currentTerm.category_type)
      setVisibility(currentTerm.global_visible === 1 ? true : false)
      if (currentTerm?.settings) {
        setAllowListDisabled(currentTerm.settings?.allowListDisabled);
        setIgnoreDisabled(currentTerm.settings?.ignoreDisabled);
        setInvisible(currentTerm.settings?.invisible);
        setColor(currentTerm.settings?.color)
      }
      if (currentTerm?.rights) {
        if (currentTerm?.rights?.length) {
          for (const i in currentTerm.rights) {
            if (currentTerm.rights[i]?.length) {
              for (const j in currentTerm.rights[i]) {
                if (currentTerm.rights[i][j]?.length) {
                  const [id, title] = currentTerm.rights[i][j];
                  currentTerm.rights[i][j] = { id, title }
                }
              }
            }
          }
        }
        setMainBox(currentTerm.rights)
        setOldRight(currentTerm.rights)
      }
    } else {
      setName("");
      setDescription("");
      setParentId(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTerm, open])

  const addRight = (idIs) => {
    // Add ONly new Rights;
    let newRights = mainBox.filter(d => !oldRight.includes(d));
    newRights?.length && newRights.map(async list => {
      try {
        // await tlService.addTermCategoryUGC(token, idIs, userId, groupId, firmaId);
        await tlService.addTermCategoryUGC(token, idIs, list[0].id, list[1].id, list[2].id);
      } catch (error) {
        console.log(convertFromErrorObject(t, error))
      }
    })
    // Delete Rights"
    deleteRight?.length && deleteRight.map(async list => {
      try {
        await tlService.deleteTermCategoryUGC(token, currentTerm.id, list[0].id, list[1].id, list[2].id);
      } catch (error) {
        console.log(error)
      }
    })
  }

  const addTerms = async () => {
    let idIs;
    let settingIs = {};
    settingIs.allowListDisabled = allowListDisabled;
    settingIs.ignoreDisabled = ignoreDisabled;
    settingIs.invisible = invisible;
    settingIs.color = !!color ? color : "";
    if (!!currentTerm) {
      try {
        const res = await tlService.updateTermCategory(token, currentTerm.id, convertLanguagesToFull(termLanguage), JSON.stringify(name), JSON.stringify(description), categoryType, settingIs, visibility ? 1 : 0);
        idIs = res.id;
        setOpen(false);
      } catch (err) {
        console.log(err)
        setAlertMsg(convertFromErrorObject(t, err));
        setTimeout(() => {
          setAlertMsg('')
        }, config.timeOutDuration)
      }
      //  Calculate the Term Category size N Clear Cache
      try {
        await tlService.calculateCategory(token, Number(idIs));
        await tlService.clearAllCache(token, 1, Number(idIs));
      } catch (error) {
        console.log(error);
      }
      addRight(idIs);
      callEndPoint();
      setDeleteRight([]);
      setOldRight([]);
    } else {
      if (!!name && !!description && !!categoryType) {
        try {
          const res = await tlService.addTermCategory(token, convertLanguagesToFull(termLanguage), JSON.stringify(name), JSON.stringify(description), categoryType, settingIs, visibility ? 1 : 0, parentId);
          idIs = res.id;
          setOpen(false);
        } catch (error) {
          console.log(convertFromErrorObject(t, error))
          setAlertMsg(convertFromErrorObject(t, error));
          setTimeout(() => {
            setAlertMsg('')
          }, config.timeOutDuration)
        }
        //  Calculate the Term Category size N Clear Cache
        try {
          await tlService.calculateCategory(token, Number(idIs));
          await tlService.clearAllCache(token, 1, Number(idIs));
        } catch (error) {
          console.log(error);
        }
        addRight(idIs);
        callEndPoint();
        setDeleteRight([]);
        setOldRight([]);
      } else {
        setAlertMsg(t('fill-option'));
        setTimeout(() => {
          setAlertMsg('')
        }, config.timeOutDuration)
      }
    }
  }

  const defaultState = () => {
    setName("");
    setDescription("");
    setParentId(0);
    setAllowListDisabled(false);
    setIgnoreDisabled(false);
    setInvisible(false);
    setColor("");
    setMainBox([]);
    setDeleteRight([]);
    setOldRight([]);
    setOpen(false);
    setCategoryType("")
    setVisibility(false)
  }

  return (
    <Modal open={open} onClose={() => defaultState()}>
      <div style={{ position: "absolute", marginLeft: "10%", marginTop: "1%" }} >
        <div className={style.termModal}>
          {!!currentTerm ?
            <h2>{t('edit-term-list')} </h2> :
            <h2>{t('add-term-list')} </h2>
          }
          <div className={`d-flex`}>
            <div className={`col-6`}>
              <TermsModalTabs
                setDescription={setDescription} description={description}
                name={name} setName={setName} setLang={setLang} lang={lang} />
            </div>
            <div className={`col-6`}>
              <h3 className={`${style.partTitle}`}>{t("language")}</h3>
              <NearTermTabs
                setTermLanguage={setTermLanguage}
                termLanguage={termLanguage}
                letterRows={letterRows}
                currentTerm={currentTerm}
                parentId={parentId} setParentId={setParentId} setCategoryType={setCategoryType}
                categoryType={categoryType}
                setVisibility={setVisibility} visibility={visibility} />
            </div>
          </div>
          <div>
            <h3 className={`${style.partTitle} mt-4`}>{t('options')} </h3>
            <LowerTermsModalFields
              categoryType={categoryType}
              deleteRight={deleteRight}
              setDeleteRight={setDeleteRight}
              mainBox={mainBox} setMainBox={setMainBox}
              allowListDisabled={allowListDisabled} setAllowListDisabled={setAllowListDisabled}
              ignoreDisabled={ignoreDisabled} setIgnoreDisabled={setIgnoreDisabled}
              invisible={invisible} setInvisible={setInvisible}
              setColor={setColor}
              color={color}
              setUserId={setUserId} setFirmaId={setFirmaId} setGroupId={setGroupId}
              userId={userId} groupId={groupId} firmaId={firmaId} />
          </div>
          <div className={style.error}>
            <Collapse in={!!alertMsg}>
              <Alert
                className={`m-0`}
                severity='error'>
                <h6 className={`m-0`}>
                  {alertMsg}
                </h6>
              </Alert>
            </Collapse>
          </div>
          <div className={`d-flex justify-content-end mt-4`}>
            <Button variant='contained' onClick={() =>
              defaultState()}>
              {t('close')}
            </Button>
            <Button color='primary' variant='contained' className={`ml-2`} onClick={() => {
              addTerms()
            }}>
              {t('save')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
