import React, { useEffect, useMemo, useState } from "react";
import style from "./style.module.scss"
import { useTheme } from "@material-ui/core/styles";
import "./tag-cloud.css"
import { Slider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import WordsTable from "./words-table/WordsTable";
import HomeWordsCloud from "../../Home/HomeWordsCloud";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useRouteMatch } from "react-router-dom";
import { isObjectEmpty } from "../../../../utils";
import TagCloud from "react-tag-cloud";
import { dashboardSettermWordCount } from "../../../../actions";
import { useActions } from "../../../../utils/action-helper";

export default function VocabularyComponent({ combine }) {
    const [dictionary, setDictionary] = useState(false)
    const [funcWord, setFuncWord] = useState(false)
    const [terms, setTerms] = useState(20)
    const { theme: { bgColors, colors } } = useTheme();
    const [sort, setSort] = useState("");
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const resultData = useSelector(state => state.analyseData.result.resultData);
    const termWordCount = useSelector(state => state.dashboardData.termWordCount);

    const { actionDashboardSettermWordCount } = useActions({
        actionDashboardSettermWordCount: dashboardSettermWordCount
    })

    function calculateWordsCloud(resultData, sort, terms, funcWord) {

        let wordsWithFilters = [];
        if ((sort === "Adjectives" || !sort) && resultData?.countAdjectives?.length) {

            wordsWithFilters = [...wordsWithFilters, ...resultData['countAdjectives'].map(position => {
                return {
                    position,
                    color: "#3fbdff",
                    type: 'Adjectives',
                    length: resultData['dataTokenizer'][position].lemma.length,
                    num: resultData['dataTokenizer'].filter(val => val.lemma === resultData['dataTokenizer'][position].lemma).length,
                    text: resultData['dataTokenizer'][position].lemma,
                    rotate: Math.random() > 0.8 ? 90 : 0
                };
            })]
        }
        if ((sort === "Nouns" || !sort) && resultData?.countNouns?.length){

            wordsWithFilters = [...wordsWithFilters, ...resultData['countNouns'].map(position => {
                return {
                    color: "#415c98",
                    type: 'Nouns',
                    length: resultData['dataTokenizer'][position].lemma.length,
                    num: resultData['dataTokenizer'].filter(val => val.lemma === resultData['dataTokenizer'][position].lemma).length,
                    text: resultData['dataTokenizer'][position].lemma,
                    rotate: Math.random() > 0.8 ? 90 : 0
                };
            })]
        }
        if ((sort === "Verbs" || !sort) && resultData?.countVerbs?.length) {

            wordsWithFilters = [...wordsWithFilters, ...resultData['countVerbs'].map(position => {
                return {
                    color: "#5e47d9",
                    type: 'Verbs',
                    length: resultData['dataTokenizer'][position].lemma.length,
                    num: resultData['dataTokenizer'].filter(val => val.lemma === resultData['dataTokenizer'][position].lemma).length,
                    text: resultData['dataTokenizer'][position].lemma,
                    rotate: Math.random() > 0.8 ? 90 : 0
                };
            })]
        }

        if (sort === "") {
            // If alle anzeigen or all view, display higest number of words:
            wordsWithFilters.sort((a, b) => b.num - a.num);
        }

        // filter for function words
        if (!funcWord && !sort) {
            const allFunctionWords = [];

            // Add respective item of below array when if it is available:
            if (resultData['countConjunctions']?.length) {
                allFunctionWords.push(
                    ...resultData['countConjunctions']
                )
            }
            if (resultData['countArticles']?.length) {
                allFunctionWords.push(
                    ...resultData['countArticles']
                )
            }
            if (resultData['countPronouns']?.length) {
                allFunctionWords.push(
                    ...resultData['countPronouns']
                )
            }
            if (resultData['countPrepositions']?.length) {
                allFunctionWords.push(
                    ...resultData['countPrepositions']
                )
            }
            if (resultData['countParticles']?.length) {
                allFunctionWords.push(
                    ...resultData['countParticles']
                )
            }

            wordsWithFilters = [...wordsWithFilters, ...allFunctionWords.map(position => {
                return {
                    color: "#192439",
                    type: 'Function',
                    length: resultData['dataTokenizer'][position].lemma.length,
                    num: resultData['dataTokenizer'].filter(val => val.lemma === resultData['dataTokenizer'][position].lemma).length,
                    text: resultData['dataTokenizer'][position].lemma,
                    rotate: Math.random() > 0.8 ? 90 : 0
                };
            })]
        }

        // Remove all unknown words and uncosistent
        wordsWithFilters = wordsWithFilters.filter(el => el.text !== '<unknown>' && !el.text.match(/[@]/));

        //  uniqueficalion
        wordsWithFilters = wordsWithFilters.filter((value, index, self) => {
            return self.findIndex(val => val.text === value.text) === index
        })

        // sort
        wordsWithFilters.sort((a, b) => a.num > b.num ? -1 : 1)

        // and set limit
        wordsWithFilters = wordsWithFilters.slice(0, terms);

        // calculate word sizes
        const maxMinSize = wordsWithFilters.reduce((acc, val) => [acc[0] < val.num ? val.num : acc[0], acc[1] > val.num ? val.num : acc[1]], [0, 10000]);
        wordsWithFilters = wordsWithFilters.map(v => {
            return { ...v, fontSize: Math.ceil(v.num / (maxMinSize[0] / 50)) }
        })

        return wordsWithFilters;
    }

    // memorisation result
    const wordsWithFilters = useMemo(() => {
        if (!isObjectEmpty(resultData)) {
            return calculateWordsCloud(resultData, sort, terms, funcWord)
        }
        return []
    }, [resultData, sort, terms, funcWord])

    function handleOnSetTerms(event, newValue) {
        setTerms(newValue);
        actionDashboardSettermWordCount(newValue)
    }

    return (
        <div className={`${style.vocabulary}`} style={{ backgroundColor: bgColors.bgPrimary0 }}>
            {!combine ? <>  <div className={`${style.head}`}>
                <h2 style={{ color: colors.colorPrimary400 }}>{t("Vocabulary")}</h2>
                <div style={{ gridGap: "10px" }} className={`d-flex align-items-center`}>
                    <Button color={!dictionary ? "primary" : "default"} onClick={() => setDictionary(s => !s)}
                        variant="contained"
                        className={`${style.wordsCloud}`}>{t("Word cloud")}</Button>
                    <Button color={dictionary ? "primary" : "default"} onClick={() => setDictionary(s => !s)}
                        variant="contained"
                        className={`${style.wordsCloud}`}>{t("List view")}</Button>
                </div>
            </div>
                <div className={style.vocMain}>
                    {dictionary ?
                        <div className={`d-flex`} style={{ gridGap: 80 }}>
                            <WordsTable wordsWithFilters={wordsWithFilters} />
                        </div> :
                        <HomeWordsCloud wordsWithFilters={wordsWithFilters} />
                    }
                    <div className={style.legends}>
                        <div className={`mb-4 d-flex flex-column`}>
                            <h4 className={`mb-2`}>{t("Parts of speech")}</h4>
                            <Button variant={sort !== "Adjectives" ? "text" : "contained"}
                                color={sort !== "Adjectives" ? "default" : "primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                style={{ color: sort !== "Adjectives" ? "#3fbdff" : colors.colorPrimary0 }}
                                onClick={() => setSort("Adjectives")}>{t("Adjectives")}</Button>
                            <Button variant={sort !== "Nouns" ? "text" : "contained"}
                                color={sort !== "Nouns" ? "default" : "primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                style={{ color: sort !== "Nouns" ? "#415c98" : colors.colorPrimary0 }}
                                onClick={() => setSort("Nouns")}>{t("Nouns")}</Button>
                            <Button variant={sort !== "Verbs" ? "text" : "contained"}
                                color={sort !== "Verbs" ? "default" : "primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                style={{ color: sort !== "Verbs" ? "#5e47d9" : colors.colorPrimary0 }}
                                onClick={() => setSort("Verbs")}>{t("Verbs")}</Button>
                            <Button variant={sort !== "" ? "text" : "contained"}
                                color={"primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                onClick={() => setSort("")}>{t("Show all")}</Button>
                        </div>
                        <div>
                            <h4 className={`mb-2`}>{t('filter')}</h4>
                            <Button style={{ fontSize: 10 }} className={`mb-3 d-flex p-1`}
                                color={funcWord ? "primary" : "default"} variant={"contained"}
                                onClick={() => setFuncWord(s => !s)}
                            >{t("without function words")}</Button>
                            <div>
                                <p className={`d-flex align-items-center`}
                                    style={{ color: colors.colorPrimary400 }}>{t("Terms")}
                                    <input className={`ml-2`} max={100}
                                        style={{ width: "35px", outline: "none" }}
                                        type="number" value={terms}
                                        onChange={e => setTerms(e.target.value)} /></p>
                                <Slider min={1} value={terms} onChange={handleOnSetTerms} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
                :
                wordsWithFilters?.length && <div className={style.vocMain} style={{ height: "400px" }}>
                    <div className={"mainTagCloud"}>
                        <div className={"app-outer"}>
                            <div className={"app-inner"}>
                                <TagCloud className='tag-cloud' style={{ padding: 6, fontFamily: 'Roboto', width: "800px", marginLeft: "-120px" }}>
                                    {wordsWithFilters.map(w => (
                                        <div key={w.text} rotate={w.rotate} style={{
                                            fontSize: w.fontSize,
                                            fontWeight: "bolder",
                                            color: w.color,
                                        }}>{w.text}</div>
                                    ))}
                                </TagCloud>
                            </div>
                        </div>
                    </div>
                    <div className={style.legends}>
                        <div className={`mb-4 d-flex flex-column`} style={{ paddingBottom: "170px" }}>
                            <h4 className={`mb-2`}>{t("Parts of speech")}</h4>
                            <Button variant={sort !== "Adjectives" ? "text" : "contained"}
                                color={sort !== "Adjectives" ? "default" : "primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                style={{ color: sort !== "Adjectives" ? "#3fbdff" : colors.colorPrimary0 }}
                                onClick={() => setSort("Adjectives")}>{t("Adjectives")}</Button>
                            <Button variant={sort !== "Nouns" ? "text" : "contained"}
                                color={sort !== "Nouns" ? "default" : "primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                style={{ color: sort !== "Nouns" ? "#415c98" : colors.colorPrimary0 }}
                                onClick={() => setSort("Nouns")}>{t("Nouns")}</Button>
                            <Button variant={sort !== "Verbs" ? "text" : "contained"}
                                color={sort !== "Verbs" ? "default" : "primary"}
                                className={`d-flex justify-content-start p-0 pl-1`}
                                style={{ color: sort !== "Verbs" ? "#5e47d9" : colors.colorPrimary0 }}
                                onClick={() => setSort("Verbs")}>{t("Verbs")}</Button>
                        </div>
                        <h4>
                            {t("numberOfWords_dashboard_pdf")}  : </h4> <b> {termWordCount}
                        </b>
                    </div>
                </div>
            }
        </div>
    )
}
